

import { useTranslation } from 'react-i18next';
import { PublicFooter, PublicHeader } from '../../../../components/common';
import './TermnsAndConditionsPage.scss'; 

const TermnsAndConditionsPage = () : JSX.Element => {
    const { t } = useTranslation();

    return(
        <div className="termsn-and-conditions">
                {t('pages.termns-and-conditions.title')}
        </div>
    )
}

export default TermnsAndConditionsPage;