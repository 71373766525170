import { Button, Checkbox, Col, Dropdown, Form, Input, InputNumber, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { Modal } from 'antd';
import { RegisterFormFieldsData } from '../../../../../models/RegisterFormData';
import { EditRegisterFormFieldsData } from '../../../../../models/RegisterFormData';
import { AddRegisterFormFieldsData } from '../../../../../models/RegisterFormData';
import { editFieldRegisterForm, deleteFieldRegisterForm } from '../../../../../api/repositoryEP';

import { postFieldRegisterForm } from '../../../../../api/repositoryEP';
import { notification } from 'antd';
import './ModalDeleteField.scss'

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';


interface ModalDeleteFieldProps {
    modalVisible: boolean;
    setModalVisible: (visible: boolean) => void;
    id: string
}

const ModalDeleteField = (props: ModalDeleteFieldProps): JSX.Element => {
    const { t } = useTranslation();
    const [fieldType, setFieldType] = useState<string>();
    const [selectOptions, setSelectOptions] = useState<string[]>([]);
    const [form] = Form.useForm();
    const modalVisible = props.modalVisible;
    const [visible, setVisible] = useState(props.modalVisible);




    const showErrorMessage = () => {
        notification.error({
            message: t('actions.error-message'),
            description: t('actions.error-description')
        });
    }
    const showSuccessNotification = () => {
        notification.success({
            message: t('actions.success-message'),
            description: t('actions.success-description')
        });
    }

    const handleModalDelete = () => {
        if (props.id != undefined) {
            deleteFieldRegisterForm(props.id).then((r) => {
                if (r?.code == 200) {
                    showSuccessNotification();
                } else {
                    showErrorMessage();
                }
                setVisible(false);
                props.setModalVisible(false);
            })
        } else {
            showErrorMessage();
            setVisible(false);
            props.setModalVisible(false);
        }
    }


    return (
        <div className="modal-delete-field">
            <Modal
                title={t("components.tab-configuration.register-form.modal-delete.title")}
                open={visible}
                footer={null}
                onCancel={() => { setVisible(false); props.setModalVisible(false) }}
                className='box-modal'
            >
                <div className='body-modal'>
                    {t("components.tab-configuration.register-form.modal-delete.description")}
                </div>
                <div >
                    <Button
                        id="btn-delete"
                        className="delete-button"
                        type="primary"
                        htmlType="button"
                        onClick={handleModalDelete}
                        style={{ width: '100%', backgroundColor: "#F78C75", marginTop: "30px" }}
                    >
                        {t("components.tab-configuration.register-form.modal-delete.btn-delete")}
                    </Button>
                </div>
            </Modal>
        </div>
    );
}

export default ModalDeleteField;