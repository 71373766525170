import { Button } from 'antd';
import { useEffect } from 'react'
import { useErrorInfo } from '../../../Contexts/Error500Context';
import { pushErrorNotificationData } from '../../../api/repositoryEP';
import { GetAlertIcon, GetHomeIcon, GetRainCloudIcon, GetReloadIcon } from '../../../utils/icons';
import { PublicFooter, PublicHeader } from '../../../components/common';

import './500.scss';
import { useTranslation } from 'react-i18next';
import { GetDefaultLanguage, GetFormattedUrl, GetLanguageInUrl, IsLaguagePresentInUrl } from '../../../utils/urls';

interface Page500Props{}

const Page500 = (props : Page500Props) : JSX.Element => {
    const { t } = useTranslation();
    
    const errorInfoContext = useErrorInfo();
    const sendErrorNotification = pushErrorNotificationData;

    const removeErrorFromSessionStorage = () : void => {
        sessionStorage.removeItem("last-error");
    }

    const goHome = () : void => {
        removeErrorFromSessionStorage();
        const url : string = GetFormattedUrl('')
        window.location.href = url;
    }

    const goBack = () : void => {
        removeErrorFromSessionStorage();
        window.history.back();
    }

    useEffect(()=>{
        const parameters = {
            message: decodeURIComponent(errorInfoContext.message ?? ''),
            parameters: decodeURIComponent(errorInfoContext.parameters ?? ''),
            method: errorInfoContext.method
        };

        sendErrorNotification(parameters)
    },[errorInfoContext])

    return (
        <div className="error-page generic-message">
            <div className="icon">
                {
                    GetRainCloudIcon()
                }
            </div>
            <div className="number">
                500
            </div>
            <div className="message">
                <p className="title">
                    {t('pages.500.title')}
                </p>
                <p className="text">
                    {t('pages.500.first-message')}
                </p>
                <p className="text">
                    {t('pages.500.second-message')}
                </p>
            </div>
            <div className="buttons">
                <Button className="app-button" onClick={goHome}>
                    <div className="content">
                        {GetHomeIcon()}
                        <span>
                            {t('pages.500.buttons.go-home')}
                        </span>
                    </div>
                </Button>
                <Button className="app-button" onClick={goBack}>
                    <div className="content">
                        {GetReloadIcon()}
                        <span>
                            {t('pages.500.buttons.go-back')}
                        </span>
                    </div>
                </Button>
                <Button className="app-button">
                    <div className="content">
                        {GetAlertIcon()}
                        <span>
                            {t('pages.500.buttons.notify-error')}
                        </span>
                    </div>
                </Button>
            </div>
        </div>
    )
}

export default Page500;