import { Card, Switch, Drawer } from 'antd';
import './UsersListComponent.scss';
import moment from 'moment';
import { useAppConfig } from '../../../Contexts/AppConfigContext';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { changeAccountUserStatus } from '../../../api/repositoryEP';
import UserData from '../../../models/UserData';
import ReactCountryFlag from "react-country-flag"
import { EditOutlined } from '@ant-design/icons';
import EditUserForm from '../../EditUserForm/EditUserForm';
import {notification} from 'antd';

interface UsersListComponentProps {
    list: UserData[];
    loading: boolean;
}

const UserListComponent = (props: UsersListComponentProps): JSX.Element => {
    const appConfig = useAppConfig();
    const { t } = useTranslation();
    const { list, loading } = props;
    const screenWidth = window.innerWidth;
    const drawerWidth = screenWidth <= 768 ? '100%' : '40%';
    const [drawerVisible, setDrawerVisible] = useState<boolean>(false);  

    const getCountryCode = (language: string): string => {
        switch (language) {
            case "es":
                return "es";
            case "fr":
                return "fr";
            default:
                return "gb"; // Establece en inglés por defecto
        }
    };

    const getLanguage = (language: string): string => {
        return t(`languages.${language}`, { language: language });
    };

    const handleEdition = (id: string) => {
        setDrawerVisible(true);
        console.log("Editando usuario", id)
    };

    const getRole = (role: string): string => {
        return t(`roles.${role}`, { role: role });
    };

    const changeUserStatus = (id: string, isActive: boolean) => {
        changeAccountUserStatus(id, isActive).then((r) => {  if(r?.code==200){
            notification.success({
                message: t('actions.success-message'),
                description: t('actions.success-description')
            });
        }else {
            notification.error({
                message: t('actions.error-message'),
                description: t('actions.error-description')
            });
        }});

    }

    return (
        <div className="accounts-list">
            {loading && (
                <div className="loading-container">
                    <LoadingOutlined className="loading-icon" />
                    <span>{t("components.accounts.list.loading")}</span>
                </div>
            )}

            {!loading &&
                list.map((item: UserData, index: number) => {
                    var cardHeader: JSX.Element = (


                        <div className="header-container" key={index}>
                            <Drawer open={drawerVisible} title={<span>{t("components.edit-users.title")}</span>} onClose={() => { setDrawerVisible(false) }} width={drawerWidth} closable>
                                <EditUserForm setDrawerVisible={setDrawerVisible} userId={(item.id)} />
                            </Drawer>
                            <div className="account-type">
                                <span>
                                    {/*aqui voy a poner la foto */}
                                    {/*  <Tooltip title={t(`components.accounts.list.account-type-${item.type}`)}>
                                        {getAccountTypeIcon(item.type)}
                                    </Tooltip>*/}
                                </span>
                                <span>
                                    {item.username} <EditOutlined style={{ fontSize: '20px' }} onClick={() => handleEdition(item.id)} />
                                    <small>{item.name} {item.surname} {item.secondSurname}</small>
                                </span>
                            </div>
                            <div className="actions-container">
                                <Switch className="app-switch" defaultChecked={item.isActive} onChange={(checked) => changeUserStatus(item.id, checked)} />
                            </div>
                        </div>
                    );

                    return (
                        <Card className="app-card" title={cardHeader} bordered={false} key={index}>
                            <div className="body-container">
                                <div className="additional-info">
                                    <div className="names">
                                        <span>{getRole(item.role)}</span>
                                        <span>
                                            <ReactCountryFlag countryCode={getCountryCode(item.languaje)} svg />  {' '}
                                            {getLanguage(item.languaje)}
                                        </span>
                                    </div>
                                    <div className="contact">
                                        <span>
                                            <strong>{t('components.accounts.list.email')}</strong>: {item.contact.email}
                                        </span>
                                        <span>
                                            <strong>{t('components.accounts.list.phone')}</strong>: {item.contact.phone}
                                        </span>
                                    </div>
                                    <span><strong>{t('components.accounts.list.creation-date')} </strong>: {moment(item.creationDate).format(appConfig.config.dateFormat)}</span>
                                </div>
                            </div>
                        </Card>
                    );
                })}
        </div>
    );
};

export default UserListComponent;

