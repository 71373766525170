

import { Button, Col, Dropdown, Form, Input, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { FileExcelOutlined, LoadingOutlined } from '@ant-design/icons';

import './AccountsFilterComponent.scss'
import { AccountFilter } from '../../../models/AccountsFilter';

interface AccountsFilterComponentProps {
    onSearch: (filterData: AccountFilter) => void;
    searching: boolean;
}

const AccountsFilterComponent = (props: AccountsFilterComponentProps) : JSX.Element => {
    
    const { t } = useTranslation();

    const { searching } = props;

    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        props.onSearch({
            name: values.name,
            email: values.email,
            status: values.status,
            location: values.location,
            accountType: values.accountType,
            page: 0,
            pageSize: 5,
        });
    }

    const items = [
        {
          key: '1',
          label: t("components.accounts.filter.agency"),
        },
        {
          key: '2',
          label: t("components.accounts.filter.business"),
        },
        {
          key: '3',
          label: t("components.accounts.filter.independent"),
        },
        {
          key: '3',
          label: t("components.accounts.filter.events"),
        },
      ];

    const onMenuClick = ({ key }: any) => {
        console.log(`Click on item ${key}`);
    };

    return(
        <div className="accounts-filter">
            <div className="export-data-content">
                <Dropdown.Button menu={{ items, onClick: onMenuClick }} className="export-button-dropdown">
                    <FileExcelOutlined className="excel-button"/> {t("components.accounts.filter.export-data")}
                </Dropdown.Button>
            </div>
            <Form form={form} onFinish={onFinish} layout='vertical'>
                <Row gutter={16}>
                    <Col xs={24} md={8}>
                        <Form.Item name="name" label={t("components.accounts.filter.name")} initialValue={''}>
                            <Input className="app-input"/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item name="email" label={t("components.accounts.filter.email")} initialValue={''}>
                            <Input className="app-input"/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item name="status" label={t("components.accounts.filter.status")} initialValue={''}>
                            <Select className="app-input" defaultValue={""}>
                                <Select.Option value="">{t("forms.selects.select-else")}</Select.Option>
                                <Select.Option value="active">{t("components.accounts.filter.status-active")}</Select.Option>
                                <Select.Option value="inactive">{t("components.accounts.filter.status-inactive")}</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item name="location" label={t("components.accounts.filter.location")} initialValue={''}>
                            <Input className="app-input"/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item name="accountType" label={t("components.accounts.filter.account-type")} initialValue={''}>
                            <Select className="app-input" defaultValue={""}>
                                <Select.Option value="">{t("forms.selects.select-else")}</Select.Option>
                                <Select.Option value="agency">{t("components.accounts.filter.agency")}</Select.Option>
                                <Select.Option value="business">{t("components.accounts.filter.business")}</Select.Option>
                                <Select.Option value="independent">{t("components.accounts.filter.independent")}</Select.Option>
                                <Select.Option value="events">{t("components.accounts.filter.events")}</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Button disabled={searching} className="app-button" htmlType='submit'>
                            {
                              searching ? <LoadingOutlined /> : ''
                            }
                            {t("forms.buttons.search")}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default AccountsFilterComponent;