import BookingData, { ApplicationPriceTypeEnum, OfferSupplementTypeEnum, PaxTypeEnum, PaymentTypeEnum } from "../models/BookingData";
import BookingInfo from "../models/BookingInfo";

export const exampleBookings: BookingInfo[] = [
    {
      bookingId: "B001",
      locator: "LOC123",
      hotelId: "H001",
      hotelName: "Sunset Beach Resort",
      ownerPhone: "+34000000000", ownerEmail: "owner.email@beds2bpro.com", ownerName: "John Smith",
      isCancelled: false, 
      statusId: "1",
      statusName: "Activa",
      checkIn: "2023-10-15",
      checkOut: "2023-10-20",
      creationDate: "2023-10-10",
      creationUser: "Alice Johnson",
      creationUserEmail: "alice@example.com",
      pvp: 150,
      commission: 10,
      payed: 140,
      currencySymbol: "$",
      currency: "USD",
      roomsNumber: 1,
      adultsNumber: 2,
      childrenNumber: 1,
      babiesNumber: 0,
      teenagersNumber: 0, rewards: 10,
      accountInfo: {
        accountType: "agency",accountAddress: "Calle de la Princesa, 5, 28008 Madrid, Spain",
        accountId: "123456789",
        name: "Agency Example Name S.L.",
        userName: "David Brown",
        userEmail: "david@example.com", userPhone: "+34000000000"
      }
    },
    {
      bookingId: "B002",
      locator: "LOC456",
      hotelId: "H002",
      hotelName: "Mountain View Lodge",
      ownerPhone: "+34000000000", ownerEmail: "owner.email@beds2bpro.com", ownerName: "Jane Doe",
      isCancelled: true, 
      statusId: "2",
      statusName: "Cancelada",
      checkIn: "2023-11-01",
      checkOut: "2023-11-07",
      creationDate: "2023-10-25",
      creationUser: "Bob Wilson",
      creationUserEmail: "bob@example.com",
      pvp: 200,
      commission: 15,
      payed: 185,
      currencySymbol: "€",
      currency: "EUR",
      roomsNumber: 2,
      adultsNumber: 4,
      childrenNumber: 1,
      babiesNumber: 1,
      teenagersNumber: 0, rewards: 5,
      accountInfo: {
        accountType: "agency",accountAddress: "Calle de la Princesa, 5, 28008 Madrid, Spain",
        accountId: "123456789",
        name: "Agency Example Name S.L.",
        userName: "Emily Davis",
      userEmail: "emily@example.com", userPhone: "+34000000000"
      }
    },
    {
      bookingId: "B003",
      locator: "LOC789",
      hotelId: "H003",
      hotelName: "Seaside Paradise Hotel",
      ownerPhone: "+34000000000", ownerEmail: "owner.email@beds2bpro.com", ownerName: "Eva Williams",
      isCancelled: true, 
      statusId: "2",
      statusName: "Activa", 
      cancelledDate: new Date(),
      checkIn: "2023-12-05",
      checkOut: "2023-12-10",
      creationDate: "2023-11-20",
      creationUser: "David Johnson",
      creationUserEmail: "david@example.com",
      pvp: 120,
      commission: 5,
      payed: 115,
      currencySymbol: "£",
      currency: "GBP",
      roomsNumber: 1,
      adultsNumber: 2,
      childrenNumber: 1,
      babiesNumber: 0,
      teenagersNumber: 0, rewards: 15.36,
      accountInfo: {
        accountType: "agency",accountAddress: "Calle de la Princesa, 5, 28008 Madrid, Spain",
        accountId: "123456789",
        name: "Agency Example Name S.L.",
        userName: "Olivia Martinez",
      userEmail: "olivia@example.com", userPhone: "+34000000000"
      }
    },
    {
      bookingId: "B004",
      locator: "LOC012",
      hotelId: "H004",
      hotelName: "City Lights Inn",
      ownerPhone: "+34000000000", ownerEmail: "owner.email@beds2bpro.com", ownerName: "Michael Taylor",
      isCancelled: false, 
      statusId: "1",
      statusName: "Activa",
      checkIn: "2023-09-20",
      checkOut: "2023-09-25",
      creationDate: "2023-09-15",
      creationUser: "Linda Clark",
      creationUserEmail: "linda@example.com",
      pvp: 180,
      commission: 12,
      payed: 168,
      currencySymbol: "$",
      currency: "USD",
      roomsNumber: 3,
      adultsNumber: 6,
      childrenNumber: 1,
      babiesNumber: 0,
      teenagersNumber: 0, rewards: 12,
      accountInfo: {
        accountType: "agency",accountAddress: "Calle de la Princesa, 5, 28008 Madrid, Spain",
        accountId: "123456789",
        name: "Agency Example Name S.L.",
        userName: "Chris Johnson",
      userEmail: "chris@example.com", userPhone: "+34000000000"
      }
    },
    {
      bookingId: "B005",
      locator: "LOC345",
      hotelId: "H005",
      hotelName: "Riverside Retreat",
      ownerPhone: "+34000000000", ownerEmail: "owner.email@beds2bpro.com", ownerName: "Robert Martin",
      isCancelled: false, 
      statusId: "1",
      statusName: "Activa",
      checkIn: "2023-11-10",
      checkOut: "2023-11-15",
      creationDate: "2023-11-05",
      creationUser: "Sarah Smith",
      creationUserEmail: "sarah@example.com",
      pvp: 160,
      commission: 10,
      payed: 150,
      currencySymbol: "€",
      currency: "EUR",
      roomsNumber: 1,
      adultsNumber: 2,
      childrenNumber: 1,
      babiesNumber: 0,
      teenagersNumber: 0, rewards: 1,
      accountInfo: {
        accountType: "agency",accountAddress: "Calle de la Princesa, 5, 28008 Madrid, Spain",
        accountId: "123456789",
        name: "Agency Example Name S.L.",
        userName: "Mark Wilson",
      userEmail: "mark@example.com", userPhone: "+34000000000"
      }
    },
    {
      bookingId: "B006",
      locator: "LOC678",
      hotelId: "H006",
      hotelName: "Golden Sands Resort",
      ownerPhone: "+34000000000", ownerEmail: "owner.email@beds2bpro.com", ownerName: "Karen Davis",
      isCancelled: true, 
      statusId: "2",
      statusName: "Cancelada",
      checkIn: "2023-12-12",
      checkOut: "2023-12-17",
      creationDate: "2023-12-05",
      creationUser: "James Brown",
      creationUserEmail: "james@example.com",
      pvp: 220,
      commission: 18,
      payed: 202,
      currencySymbol: "$",
      currency: "USD",
      roomsNumber: 2,
      adultsNumber: 4,
      childrenNumber: 2,
      babiesNumber: 1,
      teenagersNumber: 0, rewards: 8,
      accountInfo: {
        accountType: "agency",accountAddress: "Calle de la Princesa, 5, 28008 Madrid, Spain",
        accountId: "123456789",
        name: "Agency Example Name S.L.",
        userName: "Laura Johnson",
        userEmail: "laura@example.com", userPhone: "+34000000000"
      }
    },
    {
      bookingId: "B007",
      locator: "LOC901",
      hotelId: "H007",
      hotelName: "Valley Retreat Lodge",
      ownerPhone: "+34000000000", ownerEmail: "owner.email@beds2bpro.com", ownerName: "Daniel Garcia",
      isCancelled: false, statusId: "1",
      statusName: "Activa",
      checkIn: "2023-11-05",
      checkOut: "2023-11-10",
      creationDate: "2023-10-30",
      creationUser: "Maria Hernandez",
      creationUserEmail: "maria@example.com",
      pvp: 175,
      commission: 12,
      payed: 163,
      currencySymbol: "£",
      currency: "GBP",
      roomsNumber: 1,
      adultsNumber: 2,
      childrenNumber: 1,
      babiesNumber: 0,
      teenagersNumber: 0, rewards: 7,
      accountInfo: {
        accountType: "agency",accountAddress: "Calle de la Princesa, 5, 28008 Madrid, Spain",
        accountId: "123456789",
        name: "Agency Example Name S.L.",
        userName: "William Smith",
      userEmail: "william@example.com", userPhone: "+34000000000"
      }
    },
    {
      bookingId: "B008",
      locator: "LOC234",
      hotelId: "H008",
      hotelName: "Hillside Inn",
      ownerPhone: "+34000000000", ownerEmail: "owner.email@beds2bpro.com", ownerName: "Thomas Johnson",
      isCancelled: true, statusId: "1",
      statusName: "Activa", cancelledDate: new Date(),
      checkIn: "2023-10-25",
      checkOut: "2023-10-30",
      creationDate: "2023-10-20",
      creationUser: "Patricia Wilson",
      creationUserEmail: "patricia@example.com",
      pvp: 210,
      commission: 16,
      payed: 194,
      currencySymbol: "$",
      currency: "USD",
      roomsNumber: 1,
      adultsNumber: 2,
      childrenNumber: 1,
      babiesNumber: 0,
      teenagersNumber: 0, rewards: 36,
      accountInfo: {
        accountType: "agency",accountAddress: "Calle de la Princesa, 5, 28008 Madrid, Spain",
        accountId: "123456789",
        name: "Agency Example Name S.L.",
        userName: "Thomas Brown",
        userEmail: "thomas@example.com", userPhone: "+34000000000"
      }
    },
    {
      bookingId: "B009",
      locator: "LOC567",
      hotelId: "H009",
      hotelName: "Beachside Resort",
      ownerPhone: "+34000000000", ownerEmail: "owner.email@beds2bpro.com", ownerName: "William Smith",
      isCancelled: false, statusId: "1",
      statusName: "Activa",
      checkIn: "2023-11-15",
      checkOut: "2023-11-20",
      creationDate: "2023-11-10",
      creationUser: "Nancy Davis",
      creationUserEmail: "nancy@example.com",
      pvp: 155,
      commission: 10,
      payed: 145,
      currencySymbol: "€",
      currency: "EUR",
      roomsNumber: 1,
      adultsNumber: 2,
      childrenNumber: 1,
      babiesNumber: 0,
      teenagersNumber: 0, rewards: 3.98,
      accountInfo: {
        accountType: "agency",accountAddress: "Calle de la Princesa, 5, 28008 Madrid, Spain",
        accountId: "123456789",
        name: "Agency Example Name S.L.",
        userName: "Michael Brown",
        userEmail: "michael@example.com",userPhone: "+34000000000"
      }
    },
    {
      bookingId: "B010",
      locator: "LOC123",
      hotelId: "H010",
      hotelName: "Forest Haven Inn",
      ownerPhone: "+34000000000", ownerEmail: "owner.email@beds2bpro.com", ownerName: "Thomas Johnson",
      isCancelled: false, statusId: "1",
      statusName: "Activa",
      checkIn: "2023-12-22",
      checkOut: "2023-12-27",
      creationDate: "2023-12-15",
      creationUser: "Laura Wilson",
      creationUserEmail: "laura@example.com",
      pvp: 185,
      commission: 13,
      payed: 172,
      currencySymbol: "$",
      currency: "USD",
      roomsNumber: 1,
      adultsNumber: 2,
      childrenNumber: 1,
      babiesNumber: 0,
      teenagersNumber: 0, rewards: 14,
      accountInfo: {
        accountType: "agency",accountAddress: "Calle de la Princesa, 5, 28008 Madrid, Spain",
        accountId: "123456789",
        name: "Agency Example Name S.L.",
        userName: "Nancy Davis",
        userEmail: "nancy@example.com",userPhone: "+34000000000"
      }
    },
    {
      bookingId: "B011",
      locator: "LOC456",
      hotelId: "H011",
      hotelName: "Lakeview Resort",
      ownerPhone: "+34000000000", ownerEmail: "owner.email@beds2bpro.com", ownerName: "Robert Taylor",
      isCancelled: false, statusId: "1",
      statusName: "Activa",
      checkIn: "2023-10-10",
      checkOut: "2023-10-15",
      creationDate: "2023-10-05",
      creationUser: "Chris Johnson",
      creationUserEmail: "chris@example.com",
      pvp: 195,
      commission: 14,
      payed: 181,
      currencySymbol: "€",
      currency: "EUR",
      roomsNumber: 1,
      adultsNumber: 2,
      childrenNumber: 1,
      babiesNumber: 0,
      teenagersNumber: 0, rewards: 6,
      accountInfo: {
        accountType: "agency",accountAddress: "Calle de la Princesa, 5, 28008 Madrid, Spain",
        accountId: "123456789",
        name: "Agency Example Name S.L.",
        userName: "Olivia Smith",
        userEmail: "olivia@example.com",userPhone: "+34000000000"
      }
    },
    {
      bookingId: "B012",
      locator: "LOC789",
      hotelId: "H012",
      hotelName: "Downtown Suites",
      ownerPhone: "+34000000000", ownerEmail: "owner.email@beds2bpro.com", ownerName: "Jennifer Davis",
      isCancelled: false, statusId: "1",
      statusName: "Activa",
      checkIn: "2023-11-30",
      checkOut: "2023-12-05",
      creationDate: "2023-11-25",
      creationUser: "Kevin Brown",
      creationUserEmail: "kevin@example.com",
      pvp: 170,
      commission: 12,
      payed: 158,
      currencySymbol: "$",
      currency: "USD",
      roomsNumber: 1,
      adultsNumber: 2,
      childrenNumber: 1,
      babiesNumber: 0,
      teenagersNumber: 0, rewards: 78,
      accountInfo: {
        accountType: "agency",accountAddress: "Calle de la Princesa, 5, 28008 Madrid, Spain",
        accountId: "123456789",
        name: "Agency Example Name S.L.",
        userName: "Daniel Smith",
        userEmail: "daniel@example.com",userPhone: "+34000000000"
      }
    },
    {
      bookingId: "B013",
      locator: "LOC012",
      hotelId: "H013",
      hotelName: "Harbor View Hotel",
      ownerPhone: "+34000000000", ownerEmail: "owner.email@beds2bpro.com", ownerName: "Emily Martinez",
      isCancelled: false, statusId: "1",
      statusName: "Activa",
      checkIn: "2023-12-08",
      checkOut: "2023-12-13",
      creationDate: "2023-12-01",
      creationUser: "Joseph Wilson",
      creationUserEmail: "joseph@example.com",
      pvp: 155,
      commission: 10,
      payed: 145,
      currencySymbol: "€",
      currency: "EUR",
      roomsNumber: 1,
      adultsNumber: 2,
      childrenNumber: 1,
      babiesNumber: 0,
      teenagersNumber: 0, rewards: 96.36,
      accountInfo: {
        accountType: "agency"
        ,accountAddress: "Calle de la Princesa, 5, 28008 Madrid, Spain",
        accountId: "123456789",
        name: "Agency Example Name S.L.",
        userName: "Emily Smith",
        userEmail: "emily@example.com", userPhone: "+34000000000"
      }
    },
    {
      bookingId: "B014",
      locator: "LOC345",
      hotelId: "H014",
      hotelName: "Palm Oasis Resort",
      ownerPhone: "+34000000000", ownerEmail: "owner.email@beds2bpro.com", ownerName: "Olivia Martin",
      isCancelled: true, statusId: "1",
      statusName: "Activa", cancelledDate: new Date(),
      checkIn: "2023-11-25",
      checkOut: "2023-11-30",
      creationDate: "2023-11-20",
      creationUser: "Joseph Brown",
      creationUserEmail: "joseph@example.com",
      pvp: 200,
      commission: 16,
      payed: 184,
      currencySymbol: "$",
      currency: "USD",
      roomsNumber: 1,
      adultsNumber: 2,
      childrenNumber: 1,
      babiesNumber: 0,
      teenagersNumber: 0, rewards: 25.36,
      accountInfo: {
        accountType: "agency",accountAddress: "Calle de la Princesa, 5, 28008 Madrid, Spain",
        accountId: "123456789",
        name: "Agency Example Name S.L.",
        userName: "William Davis",
        userEmail: "william@example.com",userPhone: "+34000000000"
      }
    },
    {
      bookingId: "B015",
      locator: "LOC678",
      hotelId: "H015",
      hotelName: "Mountain Chalet Inn",
      ownerPhone: "+34000000000", ownerEmail: "owner.email@beds2bpro.com", ownerName: "Daniel Smith",
      isCancelled: false, statusId: "1",
      statusName: "Activa",
      checkIn: "2023-10-15",
      checkOut: "2023-10-20",
      creationDate: "2023-10-10",
      creationUser: "Chris Wilson",
      creationUserEmail: "chris@example.com",
      pvp: 175,
      commission: 11,
      payed: 164,
      currencySymbol: "€",
      currency: "EUR",
      roomsNumber: 1,
      adultsNumber: 2,
      childrenNumber: 1,
      babiesNumber: 0,
      teenagersNumber: 0, rewards: 65.36,
      accountInfo: {
        accountType: "agency",accountAddress: "Calle de la Princesa, 5, 28008 Madrid, Spain",
        accountId: "123456789",
        name: "Agency Example Name S.L.",
        userName: "Olivia Davis",
        userEmail: "olivia@example.com",userPhone: "+34000000000"
      }
    },
  ];


export const bookingDataExample: BookingData = {
    id: 1,
    clientId: 1,
    isCancelled: false,
    cancelledDate: new Date(),
    clientCode: "C123",
    bookingStatusCode: "Confirmed",
    systemLocator: "S123",
    clientLocator: "CL123",
    customerLocator: "CU123",
    recordDate: new Date(),
    lastUpdateDate: new Date(),
    observations: "Special requests",
    rateKey: "R123",
    currencyCode: "USD",
    taxesIncluded: true,
    cancellationPolicies: [
        {
            //Esto quiere decir que si cancela antes del 2023-10-20 23:59:59 no hay penalización
            from: null,
            to: new Date("2023-10-18 23:59:59"),
            penalty: 0,
            penaltyType: PaymentTypeEnum.fixed
        },
        {
            //Esto quiere decir que si cancela entre el 20/10/2023 y el 21/10/2023 hay una penalización del 50%
            from: new Date("2023-10-19 00:00:00"),
            to: new Date("2023-10-21 23:59:59"),
            penalty: 50,
            penaltyType: PaymentTypeEnum.percent
        },
        {
            //Esto quiere decir que si cancela entre el 22/10/2023 y el 23/10/2023 hay una penalización del 75%
            from: new Date("2023-10-22 00:00:00"),
            to: new Date("2023-10-23 23:59:59"),
            penalty: 75,
            penaltyType: PaymentTypeEnum.percent
        },
        {
            //Esto quiere decir que si cancela a partir del 24/10/2023 hay una penalización del 100%
            from: new Date("2023-10-24 00:00:00"),
            to: null,
            penalty: 100,
            penaltyType: PaymentTypeEnum.percent
        }
    ],
    ownerData: {
        id: 1,
        name: "John",
        surname: "Doe",
        email: "johndoe@example.com",
      },
    hotelInfo: {
        id: 1,
        code: "H123",
        name: "Grand Hotel",
        address: "123 Main St, City",
        phone: "+1234567890",
        latitude: 40.416775,
        longitude: -3.703790,
        mainImage: "https://images.pexels.com/photos/356830/pexels-photo-356830.jpeg",
        website: "https://www.beds2brewards.com/hoteles/grand-hotel-example/",
        director: "Journey Doe"
      },
    bookingPricing: {
        id: 1,
        currencyCode: "USD",
        pvp: 1500,
        net: 1350,
        commission: 150,
        exchangeValue: 1.0,
        exchangeValueDate: new Date(),
        pvpExchange: 1500,
        netExchange: 1350,
        commissionExchange: 150,
        amountType: ApplicationPriceTypeEnum.d,
      },
    bookingLines: [
      {
        id: 1,
        roomImage: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/zimbali-fotos/ZIMBALI_GALERIA025.jpg",
        roomName: "Habitación doble",
        roomTypeId: 101,
        roomTypeCode: "R101",
        regimeName: "Alojamiento y desayuno",
        regimeTypeId: 201,
        regimeTypeCode: "RG201",
        checkIn: new Date("2023-10-20"),
        checkOut: new Date("2023-10-25"),
        totalAmount: 500,
        totalCommissionAmount: 50,
        totalAmountBeforeTaxes: 450,
        totalCommissionBeforeTaxes: 45,
        roomKey: "Room key for Room 101",
        paxes: [
          {
            id: 1,
            paxType: PaxTypeEnum.adult,
            age: 30,
            name: "Guest 1",
            surname: "Doe",
          },
          {
            id: 2,
            paxType: PaxTypeEnum.adult,
            age: 28,
            name: "Guest 2",
            surname: "Doe",
          },
          {
            id: 3,
            paxType: PaxTypeEnum.child,
            age: 8,
            name: "Child 1",
            surname: "Doe",
          },
        ],
        bookingLineSummary: [
            {
              id: 1,
              from: new Date("2023-10-20"),
              to: new Date("2023-10-21"),
              applicationPriceType: ApplicationPriceTypeEnum.d,
              applicationRegimePriceType: ApplicationPriceTypeEnum.d,
              price: 500,
              priceBeforeTaxes: 450,
              agencyCommission: 50,
              agencyCommissionBeforeTaxes: 45,
              regimePrice: 500,
              regimePriceBeforeTaxes: 450
            },
            {
              id: 2,
              from: new Date("2023-10-21"),
              to: new Date("2023-10-22"),
              applicationPriceType: ApplicationPriceTypeEnum.d,
              applicationRegimePriceType: ApplicationPriceTypeEnum.d,
              price: 500,
              priceBeforeTaxes: 450,
              agencyCommission: 50,
              agencyCommissionBeforeTaxes: 45,
              regimePrice: 500,
              regimePriceBeforeTaxes: 450
            },
            {
              id: 3,
              from: new Date("2023-10-22"),
              to: new Date("2023-10-23"),
              applicationPriceType: ApplicationPriceTypeEnum.d,
              applicationRegimePriceType: ApplicationPriceTypeEnum.d,
              price: 500,
              priceBeforeTaxes: 450,
              agencyCommission: 50,
              agencyCommissionBeforeTaxes: 45,
              regimePrice: 500,
              regimePriceBeforeTaxes: 450
            },
            {
              id: 4,
              from: new Date("2023-10-23"),
              to: new Date("2023-10-24"),
              applicationPriceType: ApplicationPriceTypeEnum.d,
              applicationRegimePriceType: ApplicationPriceTypeEnum.d,
              price: 500,
              priceBeforeTaxes: 450,
              agencyCommission: 50,
              agencyCommissionBeforeTaxes: 45,
              regimePrice: 500,
              regimePriceBeforeTaxes: 450
            },
            {
              id: 5,
              from: new Date("2023-10-24"),
              to: new Date("2023-10-25"),
              applicationPriceType: ApplicationPriceTypeEnum.d,
              applicationRegimePriceType: ApplicationPriceTypeEnum.d,
              price: 500,
              priceBeforeTaxes: 450,
              agencyCommission: 50,
              agencyCommissionBeforeTaxes: 45,
              regimePrice: 500,
              regimePriceBeforeTaxes: 450
            }
          ]
          ,
        taxes: [
          {
            id: 1,
            percent: 10,
          },
          {
            id: 2,
            percent: 5,
          },
        ],
      },
      {
        id: 2,
        roomName: "Habitación tripe",
        roomImage: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/zimbali-fotos/ZIMBALI_GALERIA034.jpg",// "https://via.placeholder.com/260x135",
        roomTypeId: 102,
        roomTypeCode: "R102",
        regimeName: "Alojamiento y desayuno",
        regimeTypeId: 202,
        regimeTypeCode: "RG202",
        checkIn: new Date("2023-10-20"),
        checkOut: new Date("2023-10-25"),
        totalAmount: 600,
        totalCommissionAmount: 60,
        totalAmountBeforeTaxes: 540,
        totalCommissionBeforeTaxes: 54,
        roomKey: "Room key for Room 102",
        paxes: [
          {
            id: 4,
            paxType: PaxTypeEnum.adult,
            age: 35,
            name: "Guest 3",
            surname: "Smith",
          },
          {
            id: 5,
            paxType: PaxTypeEnum.child,
            age: 5,
            name: "Guest 4",
            surname: "Smith",
          },
          {
            id: 6,
            paxType: PaxTypeEnum.child,
            age: 7,
            name: "Child 2",
            surname: "Smith",
          },
        ],
        bookingLineSummary: [
            {
              id: 1,
              from: new Date("2023-10-20"),
              to: new Date("2023-10-21"),
              applicationPriceType: ApplicationPriceTypeEnum.d,
              applicationRegimePriceType: ApplicationPriceTypeEnum.d,
              price: 500,
              priceBeforeTaxes: 450,
              agencyCommission: 50,
              agencyCommissionBeforeTaxes: 45,
              regimePrice: 500,
              regimePriceBeforeTaxes: 450
            },
            {
              id: 2,
              from: new Date("2023-10-21"),
              to: new Date("2023-10-22"),
              applicationPriceType: ApplicationPriceTypeEnum.d,
              applicationRegimePriceType: ApplicationPriceTypeEnum.d,
              price: 500,
              priceBeforeTaxes: 450,
              agencyCommission: 50,
              agencyCommissionBeforeTaxes: 45,
              regimePrice: 500,
              regimePriceBeforeTaxes: 450
            },
            {
              id: 3,
              from: new Date("2023-10-22"),
              to: new Date("2023-10-23"),
              applicationPriceType: ApplicationPriceTypeEnum.d,
              applicationRegimePriceType: ApplicationPriceTypeEnum.d,
              price: 500,
              priceBeforeTaxes: 450,
              agencyCommission: 50,
              agencyCommissionBeforeTaxes: 45,
              regimePrice: 500,
              regimePriceBeforeTaxes: 450
            },
            {
              id: 4,
              from: new Date("2023-10-23"),
              to: new Date("2023-10-24"),
              applicationPriceType: ApplicationPriceTypeEnum.d,
              applicationRegimePriceType: ApplicationPriceTypeEnum.d,
              price: 500,
              priceBeforeTaxes: 450,
              agencyCommission: 50,
              agencyCommissionBeforeTaxes: 45,
              regimePrice: 500,
              regimePriceBeforeTaxes: 450
            },
            {
              id: 5,
              from: new Date("2023-10-24"),
              to: new Date("2023-10-25"),
              applicationPriceType: ApplicationPriceTypeEnum.d,
              applicationRegimePriceType: ApplicationPriceTypeEnum.d,
              price: 500,
              priceBeforeTaxes: 450,
              agencyCommission: 50,
              agencyCommissionBeforeTaxes: 45,
              regimePrice: 500,
              regimePriceBeforeTaxes: 450
            }
          ]
          ,
        taxes: [
          {
            id: 3,
            percent: 10,
          },
          {
            id: 4,
            percent: 5,
          },
        ],
      },
    ],
    extras: [
      {
        id: 1,
        extraId: 301,
        extraCode: "E301",
        description: "Extra 1 - Breakfast",
        applicationDateFrom: new Date("2023-10-20"),
        applicationDateTo: new Date("2023-10-25"),
        quantity: 1,
        price: 0,
        priceBeforeTax: 0,
        applicationPriceType: ApplicationPriceTypeEnum.u,
        image: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/hotel-zimbali//bar_piscina.jpg", //"https://via.placeholder.com/260x135"
      },
      {
        id: 2,
        extraId: 302,
        extraCode: "E302",
        description: "Extra 2 - Parking",
        applicationDateFrom: new Date("2023-10-20"),
        applicationDateTo: new Date("2023-10-25"),
        quantity: 1,
        price: 20,
        priceBeforeTax: 18,
        applicationPriceType: ApplicationPriceTypeEnum.u,
        image: "https://www.playasenator.com/wp-content/blogs.dir/1031/files/hotel-zimbali//restaurante_buffet.jpg"//"https://via.placeholder.com/260x135"
      },
    ],
    offersAndSupplements: [
      {
        id: 1,
        offerAndSupplementId: 401,
        offerAndSupplemetCode: "O401",
        type: OfferSupplementTypeEnum.offer,
        description: "Offer 1 - Early Booking Discount",
        applyFrom: new Date("2023-09-01"),
        applyTo: new Date("2023-09-30"),
        applyOrder: 1,
        amount: -100,
        amountBeforeTax: -90,
      },
      {
        id: 2,
        offerAndSupplementId: 402,
        offerAndSupplemetCode: "O402",
        type: OfferSupplementTypeEnum.supplement,
        description: "Supplement 1 - Sea View",
        applyFrom: new Date("2023-10-01"),
        applyTo: new Date("2023-12-31"),
        applyOrder: 2,
        amount: 50,
        amountBeforeTax: 45,
      },
    ],
    taxes: [
      {
        id: 5,
        percent: 10, // IVA
      },
      {
        id: 6,
        percent: 5, // Service Charge
      },
    ],
  };