
import { Button, Checkbox, Col, Dropdown, Form, Input, InputNumber, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { Drawer } from 'antd';
import { RegisterFormFieldsData } from '../../../../../models/RegisterFormData';
import { EditRegisterFormFieldsData } from '../../../../../models/RegisterFormData';
import { AddRegisterFormFieldsData } from '../../../../../models/RegisterFormData';
import { editFieldRegisterForm } from '../../../../../api/repositoryEP';

import { postFieldRegisterForm } from '../../../../../api/repositoryEP';
import { notification } from 'antd';
import './FieldForm.scss'

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';


interface FieldFormProps {
    drawerVisible: boolean;
    setDrawerVisible: (visible: boolean) => void;
    fieldData: RegisterFormFieldsData | null | undefined;
    languages: string[];
}

const FieldForm = (props: FieldFormProps): JSX.Element => {
    const { t } = useTranslation();
    const [fieldType, setFieldType] = useState<string>();
    const [selectOptions, setSelectOptions] = useState<string[]>([]);
    const [form] = Form.useForm();
    const drawerVisible = props.drawerVisible;

    useEffect(() => {
        if (!drawerVisible) {
            form.resetFields();
        }
    }, [drawerVisible]);

    useEffect(() => {
        if(props.fieldData?.options != null && props.fieldData?.options != undefined && props.fieldData?.options != "") {
            const options = JSON.parse(props.fieldData.options ?? '[]');
            setSelectOptions(options);
        };
     
    }, [props.fieldData?.options]);


    const addOption = () => {
        setSelectOptions([...selectOptions, '']);
    };

    const removeOption = (index: number) => {
        setSelectOptions(selectOptions.filter((_, i) => i !== index));
    };

    const handleOptionChange = (index: number, value: string) => {
        const newOptions = [...selectOptions];
        newOptions[index] = value;
        setSelectOptions(newOptions);
    };

    useEffect(() => {
        if (props.fieldData != null) {
            setFieldType(props.fieldData.fieldType);
        }
    }, [props.fieldData]);



    const showErrorMessage = () => {
        notification.error({
            message: t('actions.error-message'),
            description: t('actions.error-description')
        });
    }
    const showSuccessNotification = () => {
        notification.success({
            message: t('actions.success-message'),
            description: t('actions.success-description')
        });
    }

    const onAddField = async (values: any) => {
        let ops = "";
        if (values.fieldType == 'select' || values.fieldType == 'radio') { // para los options
            ops = JSON.stringify(selectOptions);
        }

        const field: AddRegisterFormFieldsData = {
            fieldType: values.fieldType,
            language: values.language,
            label: values.label,
            isActive: values.isActive,
            order: values.order,
            searchable: values.searchable,
            required: values.required,
            alone: values.alone,
            nameField: values.nameField,
            defaultValue: values.defaultValue,
            options: ops,
            placeholder: values.placeholder,
        }
        postFieldRegisterForm(field).then((response) => {
            if (response?.code == 200) {
                showSuccessNotification();
                form.resetFields();
                setSelectOptions([]);
                props.setDrawerVisible(false)

            } else {
                showErrorMessage();
                form.resetFields();
                setSelectOptions([]);
            }
        })
            ;
    }
    const onEditField = async (values: any) => {
        if (props.fieldData?.id == null || props.fieldData?.id == "") {
            showErrorMessage()
        } else {
            let ops = "";
            if (values.fieldType == 'select' || values.fieldType == 'radio') { // para los options
                ops = JSON.stringify(selectOptions);
            }
            const field: EditRegisterFormFieldsData = {
                id: props.fieldData.id,
                fieldType: values.fieldType,
                language: values.language,
                label: values.label,
                isActive: values.isActive,
                order: values.order,
                searchable: values.searchable,
                required: values.required,
                alone: values.alone,
                nameField: values.nameField,
                defaultValue: values.defaultValue,
                options: ops, // es un json realmente
                placeholder: values.placeholder,
            }
            editFieldRegisterForm(field).then((response) => {

                if (response?.code == 200) {
                    showSuccessNotification();
                    form.resetFields();
                    setSelectOptions([]);
                    props.setDrawerVisible(false)

                } else {
                    showErrorMessage();
                    form.resetFields();
                    setSelectOptions([]);
                }
            })
                ;
        }
    }

    const onFinish = (values: any) => {

        if (props.fieldData == null) {
            onAddField(values)
        } else {
            onEditField(values)
        }
    }


    return (
        <div className="field-form">
            <Form onFinish={onFinish} layout='vertical' form={form}>
                <Row>
                    <Col xs={24} md={24}>
                        <Form.Item
                            name="language"
                            label={t("components.tab-configuration.register-form.filter.language")}
                            initialValue={props.fieldData?.language ?? undefined}
                            rules={[{ required: true, message: t("components.tab-configuration.register-form.filter.language-required") ?? "Language is required" }]}
                        >
                            <Select className="app-input"
                                placeholder={t("components.tab-configuration.register-form.filter.select")}
                            >
                                {props.languages.map(language => (
                                    <Select.Option key={language} value={language}>
                                        {t(`languages.${language}`)}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={24}>
                        <Form.Item
                            name="fieldType"
                            label={t("components.tab-configuration.register-form.filter.field-type")}
                            initialValue={props.fieldData?.fieldType ?? undefined}  // Se usa undefined si no hay valor
                            rules={[{ required: true, message: t("components.tab-configuration.register-form.filter.field-type-required") ?? "Field type is required" }]}
                        >
                            <Select className="app-input"
                                value={fieldType}
                                onChange={(value) => setFieldType(value)}
                                disabled={props.fieldData?.fieldType != null && props.fieldData?.fieldType != undefined}
                                placeholder={t("components.tab-configuration.register-form.filter.select")}  // si estamos editando, no se puede modificar esto
                            >
                                <Select.Option value="input">{t("components.tab-configuration.register-form.filter.field-input")}</Select.Option>
                                <Select.Option value="text-area">{t("components.tab-configuration.register-form.filter.field-textarea")}</Select.Option>
                                <Select.Option value="select">{t("components.tab-configuration.register-form.filter.field-select")}</Select.Option>
                                <Select.Option value="checkbox">{t("components.tab-configuration.register-form.filter.field-checkbox")}</Select.Option>
                                <Select.Option value="radio">{t("components.tab-configuration.register-form.filter.field-radio")}</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    {(fieldType == 'input' || fieldType == 'text-area' || fieldType == 'select') && (

                        <Col xs={24} md={24}>
                            <Form.Item
                                name="placeholder"
                                label={t("components.tab-configuration.register-form.filter.placeholder")}
                                initialValue={props.fieldData?.placeholder ?? undefined}
                            >
                                <Input className="app-input"

                                />
                            </Form.Item>
                        </Col>

                    )}


                    <Col xs={24} md={24}>
                        <Form.Item
                            name="label"
                            label={t("components.tab-configuration.register-form.filter.label")}
                            initialValue={props.fieldData?.label ?? undefined}
                            rules={[{ required: true, message: t("components.tab-configuration.register-form.filter.label-required") ?? "Tag field is required" }]}
                        >
                            <Input className="app-input" />
                        </Form.Item>
                    </Col>



                    <Col xs={24} md={24}>
                        <Form.Item
                            name="name"
                            label={t("components.tab-configuration.register-form.filter.name-field")}
                            initialValue={props.fieldData?.placeholder ?? undefined}
                            rules={[{ required: true, message: t("components.tab-configuration.register-form.filter.name-field-required") ?? "Name field is required" }]}
                        >
                            <Input className="app-input"
                            />
                        </Form.Item>
                    </Col>

                    {(fieldType == 'input' || fieldType == 'text-area' || fieldType == 'select') && (
                        <Col xs={24} md={24}>
                            <Form.Item
                                name="defaultValue"
                                label={t("components.tab-configuration.register-form.filter.default-value")}
                                initialValue={props.fieldData?.defaultValue ?? undefined}
                            >
                                <Input className="app-input"
                                />
                            </Form.Item>
                        </Col>
                    )}


                    {(fieldType === 'select' || fieldType === 'radio') && (

                        <Form.Item
                            name="options"
                            label={t("components.tab-configuration.register-form.filter.options") + fieldType}
                            rules={[
                                {
                                    validator: (_, value) =>
                                        selectOptions.length === 0 || selectOptions.every(option => option === '')
                                            ? Promise.reject((t("components.tab-configuration.register-form.filter.options-required") ?? "Options are required"))
                                            : Promise.resolve(),
                                },
                            ]}
                        >

                            <Col xs={24} md={24}>
                                <Button
                                    onClick={addOption}
                                    icon={<PlusOutlined />}
                                    style={{ marginBottom: 8 }}
                                >
                                    {t("components.tab-configuration.register-form.filter.button-add-option")}
                                </Button>
                            </Col>


                            {selectOptions.map((option, optIndex) => (
                                <div key={optIndex} style={{ display: 'flex', marginBottom: 8 }}>
                                    <Input
                                        value={option}
                                        onChange={(e) => handleOptionChange(optIndex, e.target.value)}
                                    />
                                    <Button
                                        onClick={() => removeOption(optIndex)}
                                        icon={<MinusCircleOutlined />}
                                        style={{ marginLeft: 8 }}
                                    />
                                </div>
                            ))}


                        </Form.Item>

                    )}



                    <Col xs={24} md={24}>
                        <Form.Item
                            name="isActive"
                            label={t("components.tab-configuration.register-form.filter.state")}
                            rules={[{ required: true, message: t("components.tab-configuration.register-form.filter.state-required") ?? "Select the state" }]}
                        >
                            <Select
                                className="app-input"
                                placeholder={t("components.tab-configuration.register-form.filter.select")}
                                defaultValue={props.fieldData ? (props.fieldData?.isActive ? "active" : "inactive") : undefined}
                            >
                                <Select.Option value="active">{t("components.tab-configuration.register-form.filter.state-active")}</Select.Option>
                                <Select.Option value="inactive">{t("components.tab-configuration.register-form.filter.state-inactive")}</Select.Option>
                            </Select>

                        </Form.Item>
                    </Col>

                    <Col xs={12} md={12}>
                        <Form.Item
                            name="order"
                            label={t("components.tab-configuration.register-form.filter.order")}
                            initialValue={props.fieldData?.order ?? undefined}
                            rules={[{ required: true, message: t("components.tab-configuration.register-form.filter.order-required") ?? "Select the order" }]}
                        >
                            <InputNumber
                                className="app-input"
                                min={1}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={24}>
                        <Form.Item
                            name="required"
                        >
                            <Checkbox defaultChecked={props.fieldData?.required}>
                                {t("components.tab-configuration.register-form.filter.required")}
                            </Checkbox>
                        </Form.Item>
                    </Col>
                    {fieldType == 'select' && (
                        <Col xs={24} md={24}>
                            <Form.Item
                                name="searchable"
                            >
                                <Checkbox defaultChecked={props.fieldData?.searchable}>
                                    {t("components.tab-configuration.register-form.filter.searchable")}
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    )}


                    <Col xs={24} md={24}>
                        <Form.Item
                            name="alone"
                        >
                            <Checkbox defaultChecked={props.fieldData?.alone}>
                                {t("components.tab-configuration.register-form.filter.alone")}
                            </Checkbox>
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={24}>
                        <Button className="app-button full-width margin-button" htmlType='submit' >
                            {props.fieldData == null ? t("components.tab-configuration.register-form.filter.button-add") : t("components.tab-configuration.register-form.filter.button-edit")}
                        </Button>
                    </Col>
                </Row>
            </Form>



        </div>
    );
}

export default FieldForm;