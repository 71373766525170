import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { availableAppLanguages } from "../../../../Contexts/AppConfigContext";
import './RegisterForm.scss';
import FilterRegisterForm from "./filter/FilterRegisterForm";
import ListRegisterForm from "./list/ListRegisterForm";
import { RegisterFormFieldsData } from "../../../../models/RegisterFormData";
import { RegisterFormFilterData } from '../../../../models/RegisterFormData';
import { fetchFieldsRegisterForm, fetchFilterFieldsRegisterForm } from "../../../../api/repositoryEP";
import { CustomPagination } from "../../..";


const RegisterForm = (): JSX.Element => {
    const { t, i18n } = useTranslation();
    const languages = availableAppLanguages;
    const currentLanguage = i18n.language;
    const [fieldsList, setFieldList] = useState<RegisterFormFieldsData[]>([])
    const [searching, setSearching] = useState<boolean>(false);
    const [totalElements, setTotalElements] = useState<number>(0);


    const filterRegiterFormFields = async (filterData: RegisterFormFilterData) => {
        setSearching(true)
        const response = fetchFilterFieldsRegisterForm(currentLanguage, filterData).then((r) => {
            if (r?.code == 200) {
                setFieldList(r?.data as RegisterFormFieldsData[]);
                setTotalElements(fieldsList.length);
            }
        }).finally(() => {
            setSearching(false)
        })
    }

    useEffect(() => {
        setSearching(true)
        const fetchFields = async () => {
            const response = fetchFieldsRegisterForm(currentLanguage).then((r) => {
                if (r?.code == 200) {
                    setFieldList(r?.data as RegisterFormFieldsData[]);
                    setTotalElements(fieldsList.length);
                }
            }).finally(() => {
                setSearching(false)
            })
        }
        fetchFields();
    }, [])


    return (
        <div className="register-form-component ">
            <h2>
                {t("components.form-register.title")}
            </h2>
            <div className="filter-container">
                <FilterRegisterForm
                    onSearch={(filterData) => {
                        filterRegiterFormFields(filterData);
                    }}
                    searching={searching}
                    languages={languages}
                />
            </div>
            <div className="list-container" >
                <ListRegisterForm
                    list={fieldsList}
                    loading={searching}
                    languages={languages}
                />
            </div>
            <div className="pagination-container">
                <CustomPagination totalElements={totalElements} />
            </div>
        </div>
    );
};


export default RegisterForm;