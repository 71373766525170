

import { Card, Col, Drawer, Row, Switch, Tooltip, Typography } from 'antd';
import { Get3DotsIcon, GetAccountsIcon, GetAgencyIcon, GetAgentEventsIcon, GetAgentIndependentIcon, GetBookingsIcon, GetBusinessIcon, GetGroupIcon, GetUserIcon } from '../../../utils/icons';
import { useAppConfig } from '../../../Contexts/AppConfigContext';
import { useTranslation } from 'react-i18next';

import moment from 'moment';

import './BookingListComponent.scss'
import BookingInfo from '../../../models/BookingInfo';
import { EyeOutlined, LoadingOutlined } from '@ant-design/icons';
import BookingDetailPage from '../../../pages/Private/BookingDetailPage/BookingDetailPage';
import { useState } from 'react';
import BookingData from '../../../models/BookingData';

interface BookingsListComponentProps {
    list: BookingInfo[];
    loading: boolean;
    onGetBookingData: (bookingId: string) => void;
    bookingData?: BookingData
}

const BookingsListComponent = (props: BookingsListComponentProps) : JSX.Element => {
    const appConfig = useAppConfig();

    const { t } = useTranslation();

    const { list, loading, bookingData, onGetBookingData } = props;

    const getAccountTypeTag = (type: string) => {
            
            switch(type){
                case 'agency':
                    return t("components.accounts.filter.agency");
                case 'business':
                    return t("components.accounts.filter.business");
                case 'independent':
                    return t("components.accounts.filter.independent");
                case 'events':
                    return t("components.accounts.filter.events");
                default:
                    return t("components.accounts.filter.agency");
            }
    }

    const GetStatusName = (statusId: string) : string => {
        let response : string = '';
        switch(statusId){
            case '1':
                response = t("components.bookings.list.booking-status.active");
                break;
            case '2':
                response =  t("components.bookings.list.booking-status.cancelled");
                break;
        }

        return response;
    }

    const [selected, setSelected] = useState<BookingInfo>();
    const showBookingDetail = (item: BookingInfo) => {
        setSelected(item)
        onGetBookingData(item.bookingId)
    }

    return(
        <div className="bookings-list">
            <Drawer width={"60%"} open={selected !== undefined && bookingData !== undefined} onClose={()=>{ setSelected(undefined)}} title={t('pages.booking-detail.main-title')}>
                <BookingDetailPage bookingData={bookingData}/>
            </Drawer>

            {
                loading && <div className="loading-container">
                                <LoadingOutlined className="loading-icon"/>
                                <span>{t("components.bookings.list.loading")}</span>
                            </div>
            }

            {
                !loading && list.map((item: BookingInfo, index: number) => {
                    var cardHeader : JSX.Element =  <div key={index} className="header-container">
                                                        <div className="booking-resume-header">
                                                            <span>
                                                                {item.hotelName}
                                                            </span>
                                                            <Typography.Text copyable>
                                                                {item.locator}
                                                            </Typography.Text>
                                                        </div>
                                                        <div className="actions-container">
                                                            <div className="status-container">
                                                                <Tooltip title={item.statusName}>
                                                                    <div className={`status status-${item.statusId}`}>
                                                                        {GetStatusName(item.statusId)}
                                                                    </div>
                                                                </Tooltip>
                                                            </div>
                                                            <div className="show-booking-detail" onClick={()=>{showBookingDetail(item)}}>
                                                                <EyeOutlined className="width-24px"/>
                                                            </div>
                                                        </div>
                                                    </div>

                    return(
                        <Card className={`app-card ${(item.statusId === '1' ? 'booking-active' : 'booking-canceled')}`} title={cardHeader} bordered={false}>
                            <Row>
                                <Col className="booking-info" xs={24} md={8}>
                                    <div>
                                        <span>
                                            {GetBookingsIcon('app-icon width-14px')} <strong>{t("components.bookings.list.dates")}:</strong> {moment(item.checkIn).format(appConfig.config.dateFormat)} / {moment(item.checkOut).format(appConfig.config.dateFormat)}
                                            <small>({t("components.bookings.list.created-at", [moment(item.creationDate).format(appConfig.config.dateFormat)])})</small>
                                        </span>
                                    </div>
                                    <div>
                                        <span>
                                            {GetUserIcon('app-icon width-14px')} <strong>{t("components.bookings.list.owner")}:</strong> {item.ownerName}
                                            <small>
                                                <a href={`mailto:${item.ownerEmail}`}>{item.ownerEmail}</a> / {item.ownerPhone}
                                            </small>
                                        </span>
                                        <span>{GetBusinessIcon('app-icon width-14px')} <strong>{t("components.bookings.list.rooms")}:</strong> {item.roomsNumber}</span>
                                        <span>{GetGroupIcon('app-icon width-14px')} <strong>{t("components.bookings.list.pax")}:</strong> {item.adultsNumber} {t("components.bookings.list.adults")}, {item.childrenNumber} {t("components.bookings.list.children")}, {item.babiesNumber} {t("components.bookings.list.babies")}. </span>
                                    </div>
                                </Col>
                                <Col className="other-info" xs={24} md={8}>
                                    <div className="agency-info">
                                        <span>
                                            <strong>{GetAccountsIcon('app-icon width-14px')} {t("components.bookings.list.account")}:</strong> {item.accountInfo.name}
                                            <small>
                                                {item.accountInfo.accountAddress}
                                            </small>
                                            <small>
                                                <strong>{t("components.bookings.list.account-type")}:</strong>  {getAccountTypeTag(item.accountInfo.accountType)}
                                            </small>
                                        </span>
                                    </div>
                                    
                                </Col>
                                <Col className="other-info" xs={24} md={8}>
                                    <div className="agency-info">
                                        <span>
                                            {GetAccountsIcon('app-icon width-14px')} <strong>{t("components.bookings.list.account-admin")}</strong> {item.accountInfo.userName}
                                            <small>
                                                <a href={`mailto:${item.accountInfo.userEmail}`}>
                                                    {item.accountInfo.userEmail}
                                                </a> / {item.accountInfo.userPhone} 123
                                            </small>
                                        </span>
                                        <span>
                                            <strong>{GetUserIcon('app-icon width-14px')} {t("components.bookings.list.user")}:</strong> {item.creationUser}
                                            <small>
                                                <a href={`mailto:${item.creationUserEmail}`}>
                                                    {item.creationUserEmail}
                                                </a>
                                            </small>
                                        </span>
                                    </div>
                                    
                                </Col>
                                <Col className="booking-status-and-pricing" xs={24} md={24}>
                                    <div className="pricing-info">
                                        <span className="price">
                                            <label>{item.pvp} {item.currencySymbol}</label>
                                            <small>{t("components.bookings.list.price")}</small>
                                        </span>
                                        <span className="price"> 
                                            <label>{item.commission} {item.currencySymbol}</label>
                                            <small>{t("components.bookings.list.commission")}</small>
                                        </span>
                                        <span className="price">
                                            <label>{item.rewards} {item.currencySymbol}</label>
                                            <small>{t("components.bookings.list.rewards")}</small>
                                        </span>
                                    </div>
                                </Col>
                            </Row> 
                        </Card>
                    )
                })
            }
        </div>
    )
}

export default BookingsListComponent;