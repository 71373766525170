import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en-GB.json';
import us from './en-US.json';
import es from './es-ES.json';
import mx from './es-MX.json';

export const DEFAULT_LANGUAGE = 'es';
export const AVAILABLE_LANGUAGES: string[] = ['es','en', 'mx', 'us'];
i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: en },
      us: { translation: us },
      es: { translation: es },
      mx: { translation: mx },
    },
    lng: DEFAULT_LANGUAGE,
    fallbackLng: DEFAULT_LANGUAGE,
    debug: false,
    interpolation: {
      escapeValue: false,
    }
  });

export default i18n;