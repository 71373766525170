
import moment from 'moment';
import  './CancellationPoliciesComponent.scss'
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import HTMLReactParser from 'html-react-parser';
import { useAppConfig } from '../../Contexts/AppConfigContext';
import { GetShowMoreIcon } from '../../utils/icons';
import { CancellationPolicy, PaymentTypeEnum } from '../../models/BookingData';

interface CancellationPoliciesComponentProps {
    cancellationPolicies: CancellationPolicy[],
    currencyCode: string
}
 
const CancellationPoliciesComponent = (props: CancellationPoliciesComponentProps) : JSX.Element => {
    const appConfig = useAppConfig();
    const { t } = useTranslation();

    
    const { cancellationPolicies, currencyCode } = props;

    const [visible, setVisible] = useState<boolean>(false);

    const toogleVisibility = () => {
        setVisible(!visible);
    } 

    const getCancellationPolicyInfo = (cancellationPolicy: CancellationPolicy) : JSX.Element => {

        /**
         * Si cancellationPolicy.from es null es que la política de cancelación aplica desde cancellationPolicy.to
         * Si cancellationPolicy.from y cancellationPolicy.to tienen valor es que la política de cancelación aplica entre esas fechas
         * Si cancellationPolicy.to es null es que la política de cancelación aplica desde cancellationPolicy.from hasta el día de check-in de la reserva
         */

        let cancellationPolicies: JSX.Element[] = [];

        if (cancellationPolicy.from === null){
            cancellationPolicies.push(<span className="cancellation-policy-line">{HTMLReactParser(t('pages.booking-detail.cancellation-policy.until', [moment(cancellationPolicy.to).format(`${appConfig.config.dateFormat} HH:mm:ss`)]))}</span>)            
        }else if (cancellationPolicy.to === null){
            cancellationPolicies.push(<span className="cancellation-policy-line">{HTMLReactParser(t('pages.booking-detail.cancellation-policy.from', [moment(cancellationPolicy.from).format(`${appConfig.config.dateFormat} HH:mm:ss`)]))}</span>)
            cancellationPolicies.push(<span className="cancellation-policy-line">{HTMLReactParser(t('pages.booking-detail.cancellation-policy.until-check-in'))}</span>)
        }else{
            cancellationPolicies.push(<span className="cancellation-policy-line">{HTMLReactParser(t('pages.booking-detail.cancellation-policy.from', [moment(cancellationPolicy.from).format(`${appConfig.config.dateFormat} HH:mm:ss`)]))}</span>)
            cancellationPolicies.push(<span className="cancellation-policy-line">{HTMLReactParser(t('pages.booking-detail.cancellation-policy.to', [moment(cancellationPolicy.to).format(`${appConfig.config.dateFormat} HH:mm:ss`)]))}</span>)
        }

        return <div className="cancelation-policy">
            {cancellationPolicies}
            <span>
                {
                    cancellationPolicy.penalty === 0 ? 
                    t('pages.booking-detail.cancellation-policy.free') :
                    `${cancellationPolicy.penalty} ${(cancellationPolicy.penaltyType === PaymentTypeEnum.percent ? '%' : currencyCode)}`
                }
            </span>
        </div>
    }

    return(<div className="cancellation-policies-component">

                <div className="cancellation-policies" onClick={toogleVisibility}>
                    {GetShowMoreIcon("plus-icon")} {t('pages.booking-detail.cancellation-policies')}
                </div>
                <ul className={`cancellation-policies-list ${visible ? 'visible' : 'hidden'}`}>
                    {
                        cancellationPolicies.map((cancellationPolicy) => {
                            return (
                                <li>
                                    { 
                                        getCancellationPolicyInfo(cancellationPolicy)
                                    }
                                </li>
                            )
                        })
                    }
                </ul>
        </div>
    )
}

export default CancellationPoliciesComponent;