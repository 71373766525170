import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Switch, Table } from 'antd';
import type { TableProps } from 'antd';
import { HotelsConfigData, HotelsConfigEditData, HotelsConfigEditStatusData } from '../../../../../../models/MappingData';
import { DeleteOutlined, EditOutlined, KeyOutlined, StarFilled, StarTwoTone } from '@ant-design/icons';
import { Row, Col, Button, Drawer } from 'antd';
import { editHotelsStatusConfig, fetchHotelsConfigEditionMapping, fetchHotelsConfigMapping } from '../../../../../../api/repositoryEP';
import { notification } from 'antd';
import './HotelsConfig.scss';
import ModalGeneric from '../Modals/ModalGeneric';
import HotelForm from '../Forms/HotelsForm/HotelsForm';
import Search from 'antd/es/input/Search';
import { GetKeyIcon } from '../../../../../../utils/icons';
import { GetStarIcon } from '../../../../../../utils/icons';


interface HotelsConfigProps {
    language: string;
}

const HotelsConfig = (props: HotelsConfigProps): JSX.Element => {

    const { t } = useTranslation();
    const [data, setData] = useState<HotelsConfigData[]>([]);
    const drawerWidth = window.innerWidth <= 768 ? '100%' : '40%';
    const [newHotelDrawer, setNewHotelDrawer] = useState<boolean>(false);
    const [editHotelDrawer, setEditHotelDrawer] = useState(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [hotelData, setHotelData] = useState<HotelsConfigEditData | null>(null);


    const columns: TableProps<HotelsConfigData>['columns'] = [
        {
            title: t("components.tab-mapping.hotels-config.table.c1"),
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: t("components.tab-mapping.hotels-config.table.c2"),
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: t("components.tab-mapping.hotels-config.table.c3"),
            key: 'category',
            render: (obj) => (
                <div className='icon-hotelTable-container'>
                    {obj.category.number} {obj.category.type === 'star' ? GetStarIcon("app-icon width-18px") : GetKeyIcon("app-icon width-18px")  }
                </div>
            ),
        },
        {
            title: t("components.tab-mapping.hotels-config.table.c4"),
            dataIndex: 'destination',
            key: 'destination',
        },
        {
            title: t("components.tab-mapping.hotels-config.table.c5"),
            dataIndex: 'numberRooms',
            key: 'numberRooms',
        },
        {
            title: t("components.tab-mapping.hotels-config.table.c6"),
            key: 'action',
            render: (obj) => (
                <div className='icon-container'>
                    <Switch defaultChecked={obj.active} style={{ marginRight: 5 }} onClick={() => changeStatus(obj)} />
                    <EditOutlined className='icon' onClick={() => editHotel(obj)} />
                    <DeleteOutlined className='icon' onClick={() => deleteHotel(obj)} />
                </div>
            ),
        },
    ];


    useEffect(() => { // para cuando se cierra el drawer de editar o crear o el modal, recargamos los datos
        if (!newHotelDrawer && !editHotelDrawer && !deleteModalVisible) {
            fetchHotelsConfigMapping(props.language, '').then((response) => {
                if (response?.code === 200) {
                    setData(response.data);
                } else {
                    showErrorMessage(); // algo más ? 
                }
            });
        }

    }, [newHotelDrawer, editHotelDrawer, deleteModalVisible]);


    const editHotel = (hotel: HotelsConfigData) => {
        const h: HotelsConfigEditData = { id: hotel.id, name: hotel.name, category: hotel.category, code: hotel.code, destination: hotel.destination, numberRooms: hotel.numberRooms, active: hotel.active, regimen: [], rooms: [], syncReward: false }
        setHotelData(h);
        setEditHotelDrawer(true)
    }

    const deleteHotel = (hotel: HotelsConfigData) => {
        const r: HotelsConfigEditData = {
            id: hotel.id,
            name: hotel.name,
            category: hotel.category,
            code: hotel.code,
            destination: hotel.destination,
            numberRooms: hotel.numberRooms,
            active: hotel.active,
            regimen: [],
            rooms: [],
            syncReward: false
        }
        setHotelData(r);
        setDeleteModalVisible(true);

    }
    const changeStatus = (hotels: HotelsConfigData) => {
        const h: HotelsConfigEditStatusData = { id: hotels.id, active: hotels.active }
        editHotelsStatusConfig(h).then((response) => {
            if (response?.code === 200) {
                showSuccessNotification();
            } else {
                showErrorMessage();
            }
        })
    }

    const onSearch = (value: string) => {
        fetchHotelsConfigMapping(props.language, value).then((response) => {
            if (response?.code === 200) {
                setData(response.data);
            } else {
                showErrorMessage();
            }
        });
    }

    const handleAddButton = () => {
        setHotelData(null)
        setNewHotelDrawer(true)
    }



    const showErrorMessage = () => {
        notification.error({
            message: t('actions.error-message'),
            description: t('actions.error-description')
        });
    }

    const showSuccessNotification = () => {
        notification.success({
            message: t('actions.success-message'),
            description: t('actions.success-description')
        });
    }



    return (
        <div className="hotels-configuration-component">
            {deleteModalVisible && hotelData?.id &&
                <ModalGeneric
                    modalVisible={deleteModalVisible}
                    setModalVisible={setDeleteModalVisible}
                    id={hotelData.id}
                    action='deleteHotel'
                    data = {hotelData}
                    setData={setHotelData}
                    />
            }

            <Drawer
                open={editHotelDrawer}
                title={t("components.tab-mapping.hotels-config.drawer.title-edit")}
                onClose={() => {
                    setEditHotelDrawer(false);
                    setHotelData(null)
                }}
                width={drawerWidth}
                closable>
                <HotelForm
                    setDrawerVisible={setEditHotelDrawer}
                    drawerVisible={editHotelDrawer}
                    hotelData={hotelData}
                    setHotelData={setHotelData}
                    language={props.language}
                />
            </Drawer>

            <Drawer
                open={newHotelDrawer}
                title={t("components.tab-mapping.hotels-config.drawer.title-new")}
                onClose={() => {
                    setNewHotelDrawer(false);
                    setHotelData(null)
                }}
                width={drawerWidth}
                closable>
                <HotelForm
                    setDrawerVisible={setNewHotelDrawer}
                    drawerVisible={newHotelDrawer}
                    hotelData={null}
                    setHotelData={setHotelData}
                    language={props.language}
                />
            </Drawer>

            <Row gutter={[10, 10]} style={{ flexDirection: 'column' }}>
                <Col xs={24} md={24}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={24} style={{ fontFamily: 'Montserrat' }}>
                            {t("components.tab-mapping.hotels-config.msg-info")}
                        </Col>
                        <Col xs={24} md={24} lg={12}>
                            <Search
                                placeholder={t("components.tab-mapping.hotels-config.search") || ''}
                                allowClear
                                onSearch={onSearch}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={6} className='button-container'>
                            <Button
                                className='button-class'
                                onClick={() => handleAddButton()}>
                                {t("components.tab-mapping.hotels-config.button-add")}
                            </Button>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={6} className='button-container'>
                            <Button className='button-import'>{t("components.tab-mapping.hotels-config.button-import")}</Button>
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} md={24}>
                    <Table
                        className='table'
                        columns={columns}
                        dataSource={data}
                        scroll={{ x: 'max-content' }} />
                </Col>

            </Row>


        </div>
    );
};



export default HotelsConfig;