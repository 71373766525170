import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Table } from 'antd';
import type { TableProps } from 'antd';
import { RegimesConfigData, TranslationData } from '../../../../../../models/MappingData';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Row, Col, Button, Drawer } from 'antd';
import { fetchLanguagesAvailables, fetchRegimesConfigMapping } from '../../../../../../api/repositoryEP';
import { notification } from 'antd';
import './RegimesConfig.scss';
import Search from 'antd/es/input/Search';
import RegimesForm from '../Forms/RegimesForm/RegimesForm';
import ModalGeneric from '../Modals/ModalGeneric';

interface RegimesConfigProps {
    language: string;
}

const RegimesConfig = (props: RegimesConfigProps): JSX.Element => {

    const { t } = useTranslation();
    const [data, setData] = useState<RegimesConfigData[]>([]);
    const drawerWidth = window.innerWidth <= 768 ? '100%' : '40%';
    const [newRegimeDrawer, setNewRegimeDrawer] = useState<boolean>(false);
    const [editRegimeDrawer, setEditRegimeDrawer] = useState(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);

    const [regimenData, setRegimenData] = useState<RegimesConfigData | null>(null);


    useEffect(() => {
        if (!newRegimeDrawer && !editRegimeDrawer && !deleteModalVisible) {
            fetchRegimesConfigMapping('').then((response) => {
                if (response?.code === 200) {
                    setData(response.data);
                } else {
                    showErrorMessage(); // algo más ? 
                }
            });
        }

    }, [newRegimeDrawer, editRegimeDrawer, deleteModalVisible]);


    const columns: TableProps<RegimesConfigData>['columns'] = [
        {
            title: t("components.tab-mapping.regimes-config.table.c1"),
            dataIndex: 'name',
            key: 'name',
            render: (names) => {
                const nameInLangugage = names.find((name: TranslationData) => name.language === props.language);
                return nameInLangugage?.value;
            }
        },
        {
            title: t("components.tab-mapping.regimes-config.table.c2"),
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: t("components.tab-mapping.regimes-config.table.c3"),
            dataIndex: 'internNote',
            key: 'interNote',
        },
        {
            title: t("components.tab-mapping.regimes-config.table.c4"),
            key: 'action',
            render: (obj) => (
                <div className='icon-container' >
                    <EditOutlined className='icon' onClick={() => editRegimen(obj)} />
                    <DeleteOutlined className='icon' onClick={() => deleteRegimen(obj)} />
                </div>
            ),
        }
    ]

    

    const editRegimen = (regimen: RegimesConfigData) => {
        setRegimenData(regimen);
        setEditRegimeDrawer(true);
    }

    const deleteRegimen = (regimen: RegimesConfigData) => {
        setRegimenData(regimen);
        setDeleteModalVisible(true);
    }
    

    const onSearch = (value: string) => {
        fetchRegimesConfigMapping(value).then((response) => {
            if (response?.code === 200) {
                setData(response.data);
            } else {
                showErrorMessage();
            }
        });
    }


    const showErrorMessage = () => {
        notification.error({
            message: t('actions.error-message'),
            description: t('actions.error-description')
        });
    }


    const handleAddButton = () => {
       setRegimenData(null)
       setNewRegimeDrawer(true)
    }


    return (
        <div className="regimen-config-component">
            {deleteModalVisible && regimenData?.id &&

                <ModalGeneric
                    modalVisible={deleteModalVisible}
                    setModalVisible={setDeleteModalVisible}
                    id={regimenData.id}
                    action="deleteRegimen" 
                    data = {regimenData}
                    setData={setRegimenData}
                    />}

            <Drawer
                open={editRegimeDrawer}
                title={t("components.tab-mapping.regimes-config.drawer.title-edit")}
                onClose={() => {
                    setEditRegimeDrawer(false);
                    setRegimenData(null)
                }}
                width={drawerWidth}
                closable>
                <RegimesForm
                    setDrawerVisible={setEditRegimeDrawer}
                    drawerVisible={editRegimeDrawer}
                    regimesData={regimenData}
                    setRegimesData={setRegimenData}
                />
            </Drawer>

            <Drawer
                open={newRegimeDrawer}
                title={t("components.tab-mapping.regimes-config.drawer.title-new")}
                onClose={() => {
                    setNewRegimeDrawer(false);
                    setRegimenData(null)
                }}
                width={drawerWidth}
                closable>
                <RegimesForm
                    setDrawerVisible={setNewRegimeDrawer}
                    drawerVisible={newRegimeDrawer}
                    regimesData={null}
                    setRegimesData={setRegimenData}
                />
            </Drawer>


            <Row gutter={[10, 10]} style={{ flexDirection: 'column' }}>
                <Col xs={24} md={24}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={24} style={{ fontFamily: 'Montserrat' }}>
                            {t("components.tab-mapping.regimes-config.msg-info")}
                        </Col>
                        <Col xs={24} md={24} lg={12}>
                            <Search
                                placeholder={t("components.tab-mapping.regimes-config.search") || ''}
                                allowClear
                                onSearch={onSearch}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={6} className='button-container'>
                            <Button
                                className='button-class'
                                onClick={() => handleAddButton()}>
                                {t("components.tab-mapping.regimes-config.button-add")}
                            </Button>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={6} className='button-container'>
                            <Button className='button-import'>{t("components.tab-mapping.regimes-config.button-import")}</Button>
                        </Col>
                    </Row>
                </Col>

                <Col xs={24} md={24}>
                    <Table
                        className='table'
                        columns={columns}
                        dataSource={data}
                        scroll={{ x: 'max-content' }} />
                </Col>
            </Row>

        </div>
    );
};



export default RegimesConfig;