import { useTranslation } from 'react-i18next';
import './CookiesPage.scss'
import { PublicFooter, PublicHeader } from '../../../../components/common';

const CookiesPage = () : JSX.Element => {

    const { t } = useTranslation();
    
    return(
        <div className="cookies">
                {t('pages.cookies.title')}
        </div>
    )
}

export default CookiesPage;