import { UserEdit } from "../models/UsersInfo";


export const userEditMock: UserEdit = {
    id: "1",
    name: "John",
    email: "email@email.com",
    username: "john_williams",
    role: "admin",
    language: "en",
    surname: "Williams",
    secondSurname: "Williams",
    phone: "677867867",
}
