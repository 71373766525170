import './ConfigurationPage.scss'
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import TabConfiguration from '../../../components/TabConfiguration/TabConfiguration';

const ConfigurationPage = (): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className="configuration-page">
            <h3>
                {t("pages.configuration.title")}
            </h3>
            <TabConfiguration />
        </div>
    );

}

export default ConfigurationPage;