import { useTranslation } from "react-i18next";
import { Select, Space } from "antd";
import { availableAppLanguages } from "../../../../Contexts/AppConfigContext";
import { GetDefaultLanguage } from "../../../../utils/urls";
import { Row, Col, Form, Input, Switch, InputNumber } from "antd";
import { useEffect, useState } from "react";
import { fetchGroupForm, fetchServicesChosen, fetchOptionsServices, postGroupForm } from "../../../../api/repositoryEP";
import { Button } from "antd";
import type { SelectProps } from 'antd';
import { FormGroupData, FormGroupDataRequest, ServiceData } from "../../../../models/ConfigurationData";
import { LoadingOutlined } from "@ant-design/icons";
import { notification } from "antd";

interface GroupFormProps {
    defaultLanguage: string
}

const GroupForm = (props: GroupFormProps): JSX.Element => {

    const { t } = useTranslation();
    const languages = availableAppLanguages;
    const [formGroupData, setFormGroupData] = useState<FormGroupData>({} as FormGroupData);
    const [language, setLanguage] = useState(props.defaultLanguage);
    const [loading, setLoading] = useState<boolean>(true);
    const [services, setServices] = useState<SelectProps['options']>([]);
    const [options, setOptions] = useState<SelectProps['options']>([]);
    const [form] = Form.useForm();


    const showErrorMessage = () => {
        notification.error({
            message: t('actions.error-message'),
            description: t('actions.error-description')
        });
    }
    const showSuccessNotification = () => {
        notification.success({
            message: t('actions.success-message'),
            description: t('actions.success-description')
        });
    }

    const handleServicesChosenChange = (values: any) => {
        setServices(values);
    }

    const handleLanguage = (language: any) => {
        {
            setLanguage(language);
            form.setFieldValue('language', language);
        }
    }



    useEffect(() => {
        setLoading(true)
        form.setFieldValue('language', language);
        fetchOptionsServices(language).then((r) => {
            if (r?.code == 200) {
                const newOptions = (r.data as Array<ServiceData>).map(service => ({
                    label: service.name,
                    value: service.id,
                }));
                setOptions(newOptions);
            } else {
                showErrorMessage(); // aquí sería un " error no han podido cargar los servicios"
            }
        });
        fetchServicesChosen(language).then((r) => {
            if (r?.code == 200) {
                const servicesChoosen = (r.data as Array<ServiceData>).map(service => ({
                    label: service.name,
                    value: service.id,
                }));
                setServices(servicesChoosen);
                form.setFieldValue('services', servicesChoosen);

            } else {
                showErrorMessage(); // aquí sería un " error no han podido cargar los servicios"
            }
        });

        setLoading(false)
    }, [language]);


    useEffect(() => {
        setLoading(true)
        fetchGroupForm(language).then((r) => {
            if (r?.code == 200) {
                setFormGroupData(r.data as FormGroupData);
            } else {
                showErrorMessage(); // se muestra un error y se redirige a otra página si no ha podido cargar ????  o mostrar error 404
            }
            setLoading(false)
        });
    }, []);


    const onFinish = (values: any) => {
        postGroupForm(values as FormGroupDataRequest).then((r) => {
            if (r?.code == 200) {
                showSuccessNotification();
            } else {
                showErrorMessage();
            }
        });
    }

    return (
        <div className="pro-configuration-component">
            {loading ? (<LoadingOutlined className="loading" />) : (
                <div>
                    <h2>
                        {t("components.tab-configuration.group-form.title")}
                    </h2>
                    <Form layout="vertical" onFinish={onFinish} form={form}>
                        <Row gutter={4} style={{ flexDirection: 'column' }}>

                            <Col xs={24} md={12}>
                                <Form.Item
                                    name="email"
                                    label={t("components.tab-configuration.group-form.fields.email")}
                                    initialValue={formGroupData.email}
                                >
                                    <Input className="app-input" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} md={24}>
                                <Form.Item
                                    name="agent-copy"
                                    label={t("components.tab-configuration.group-form.fields.agent-copy")}
                                    initialValue={formGroupData.agentCopy}
                                >
                                    <Switch className="app-switch" />
                                </Form.Item>
                            </Col>

                            <Row gutter={4} style={{ flexDirection: 'row' }}>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="min-occupancy"
                                        label={t("components.tab-configuration.group-form.fields.min-occupancy")}
                                        initialValue={formGroupData.minOccupancy}
                                    >
                                        <InputNumber className="app-input" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Form.Item
                                        name="max-occupancy"
                                        label={t("components.tab-configuration.group-form.fields.max-occupancy")}
                                        initialValue={formGroupData.maxOccupancy}
                                    >
                                        <InputNumber className="app-input" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={4} style={{ flexDirection: 'row' }}>
                                <Col xs={24} sm={12} >
                                    <Form.Item
                                        name="min-rooms"
                                        label={t("components.tab-configuration.group-form.fields.min-rooms")}
                                        initialValue={formGroupData.minRooms}
                                    >
                                        <InputNumber className="app-input" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} >
                                    <Form.Item
                                        name="max-rooms"
                                        label={t("components.tab-configuration.group-form.fields.max-rooms")}
                                        initialValue={formGroupData.maxRooms}
                                    >
                                        <InputNumber className="app-input" />
                                    </Form.Item>
                                </Col>
                            </Row>


                            <Col xs={24} md={24}>
                                <Form.Item
                                    name="release"
                                    label={t("components.tab-configuration.group-form.fields.release")}
                                    initialValue={formGroupData.release}
                                >
                                    <InputNumber className="app-input" />
                                </Form.Item>
                            </Col>

                            <Row gutter={4} style={{ flexDirection: 'row' }}>
                                <Col xs={24} md={6}  >
                                    <Form.Item
                                        name="language"
                                        label={t("components.tab-configuration.group-form.fields.services")}

                                    >
                                        <Select defaultValue={language} onChange={handleLanguage}>
                                            {languages.map(language => (
                                                <Select.Option key={language} value={language}>
                                                    {t(`languages.${language}`)}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>


                                <Col xs={24} md={18}>
                                    <Form.Item
                                        name="services"
                                        label=" "

                                    >
                                        <Select
                                            mode="multiple"
                                            allowClear
                                            placeholder={t("components.tab-configuration.group-form.fields.services-placeholder")}
                                            options={options}
                                            value={services}
                                            onChange={handleServicesChosenChange}
                                        >
                                        </Select>

                                    </Form.Item>
                                </Col>
                            </Row>

                            <Col xs={24} md={24}>
                                <Button className="app-button" htmlType='submit'>
                                    {t("components.button.save")}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            )
            }
        </div>
    );
};



export default GroupForm;