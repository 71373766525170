import { Button, Checkbox, Col, Dropdown, Form, Input, InputNumber, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { Modal } from 'antd';
import { deleteHotelsConfigMapping ,deleteRegimesConfigMapping, deleteRoomConfigMapping, deleteServiceConfigMapping } from '../../../../../../api/repositoryEP';
import { notification } from 'antd';

import './ModalGeneric.scss'


interface ModalGenericProps {
    modalVisible: boolean;
    setModalVisible: (visible: boolean) => void;
    id: string ,
    action : string,
    data : any,
    setData : (d : any) => void
}

const ModalGeneric = (props: ModalGenericProps): JSX.Element => {
    const { t } = useTranslation();
    const [msg,setMsg] = useState<string | null>("");

    useEffect(() => {
        switch (props.action) {
            case "deleteHotel":
                setMsg(t("components.tab-mapping.hotels-config.modal-msg"));
                break;
            case "deleteRegimen":
                setMsg(t("components.tab-mapping.regimes-config.modal-msg"));
                break;
            case "deleteRoom":
                setMsg(t("components.tab-mapping.room-config.modal-msg"));
                break;
            case "deleteService":
                setMsg(t("components.tab-mapping.services-config.modal-msg"));
        }
    },[props.action]);



    const showErrorMessage = () => {
        notification.error({
            message: t('actions.error-message'),
            description: t('actions.error-description')
        });
    }
    const showSuccessNotification = () => {
        notification.success({
            message: t('actions.success-message'),
            description: t('actions.success-description')
        });
    }


    const deleteHotel = () => {
        deleteHotelsConfigMapping(props.id).then((r: any) => {
            if (r?.code == 200) {
                showSuccessNotification();
            } else {
                showErrorMessage();
            }
            cleanAndClose();
        })
        
    }

    const deleteRegime = () => {
        deleteRegimesConfigMapping(props.id).then((r: any) => {
            if (r?.code == 200) {
                showSuccessNotification();
            } else {
                showErrorMessage();
            }
            cleanAndClose();
        })
        
    }

    const deleteRoom = () => {
        deleteRoomConfigMapping(props.id).then((r: any) => {
            if (r?.code == 200) {
                showSuccessNotification();
            } else {
                showErrorMessage();
            }
            cleanAndClose();
        })
        
    }

    const deleteService = () => {
        deleteServiceConfigMapping(props.id).then((r: any) => {
            if (r?.code == 200) {
                showSuccessNotification();
            } else {
                showErrorMessage();
            }
            cleanAndClose();
        });
    }



    const handleModalDelete = () => {
        if(props.id != undefined){
            switch (props.action ) {
                case "deleteHotel" : 
                    deleteHotel();
                    break;
                case "deleteRegimen" :
                    deleteRegime();
                    break;
                case "deleteRoom" :
                    deleteRoom();
                    break;    
                case "deleteService" :
                    deleteService();
                    break;       
            }
        }else {
            showErrorMessage();
            cleanAndClose();
        }
    }

    const cleanAndClose = () => 
    {
    
        props.setData(null)
        props.setModalVisible(false);

    }


    return (
        <div className="modal-generic">
            <Modal
                title={t("components.tab-configuration.register-form.modal-delete.title")}
                open={props.modalVisible}
                footer={null}
                onCancel={() => {  props.setModalVisible(false) }}
                className='box-modal'
            >
                <div className='body-modal'>
                    {msg}
                </div>
                <div >
                    <Button
                        id="btn-delete"
                        className="delete-button"
                        type="primary"
                        htmlType="button"
                        onClick={handleModalDelete}
                        style={{ width: '100%', backgroundColor: "#F78C75", marginTop: "30px" }}
                    >
                        {t("components.tab-configuration.register-form.modal-delete.btn-delete")}
                    </Button>
                </div>
            </Modal>
        </div>
    );
}

export default ModalGeneric;