
import './LoadingPage.scss';

import { LoadingOutlined } from '@ant-design/icons';

const LoadingPage = () : JSX.Element => {

    return <div className="loading-page generic-message">
                    <div className="icon">
                        <LoadingOutlined />
                    </div>
            </div>
}

export default LoadingPage;