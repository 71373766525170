import RoomConfigData, { HotelRegimensData, HotelRoomsData, HotelsConfigData, HotelsConfigEditData, RegimesConfigData, ServicesConfigData } from "../models/MappingData";

export const languagesAvailableMock: Array<string> = ['es', 'en', 'fr', 'de']

export const roomConfigMock: Array<RoomConfigData> = [{
    id: '1',
    name: [{ language: 'es', value: 'Habitación simple' },
    { language: 'en', value: 'Single room' },
    { language: 'fr', value: 'Chambre simple' },
    { language: 'de', value: 'Einzelzimmer' }],
    code: '12345',
    internNote: 'Habitación genérica individual',
    syncReward: true
},
{
    id: '2',
    name: [{ language: 'es', value: 'Habitación doble' },
    { language: 'en', value: 'Double room' },
    { language: 'fr', value: 'Chambre double' },
    { language: 'de', value: 'Einzelzimmer' }],
    code: 'ABCDE',
    internNote: 'Habitación genérica doble',
    syncReward: true
}, {
    id: '3',
    name: [{ language: 'es', value: 'Habitación triple' },
    { language: 'en', value: 'Triple room' },
    { language: 'fr', value: 'Chambre triple' },
    { language: 'de', value: 'Einzelzimmer' }],
    code: 'ADGB',
    internNote: 'Habitación genérica triple',
    syncReward: true
}

]


export const roomConfigSearchMock: Array<RoomConfigData> = [{
    id: '1',
    name: [{ language: 'es', value: 'Habitación simple' },
    { language: 'en', value: 'Single room' },
    { language: 'fr', value: 'Chambre simple' },
    { language: 'de', value: 'Einzelzimmer' }],
    code: '12345',
    internNote: 'Habitación genérica individual',
    syncReward: true
},
{
    id: '2',
    name: [{ language: 'es', value: 'Habitación doble' },
    { language: 'en', value: 'Double room' },
    { language: 'fr', value: 'Chambre double' },
    { language: 'de', value: 'Einzelzimmer' }],
    code: 'ABCDE',
    internNote: 'Habitación genérica doble',
    syncReward: true
}
]

export const roomConfigEditMock: RoomConfigData = {
    id: '1',
    name: [{ language: 'es', value: 'Habitación doble' },
    { language: 'en', value: 'Double room' },
    { language: 'fr', value: 'Chambre double' },
    { language: 'de', value: 'Einzelzimmer' }],
    code: 'ABCDE',
    internNote: 'Habitación genérica doble',
    syncReward: true
}






//--------RegimensConfig-----------------


export const regimesConfigMock: Array<RegimesConfigData> = [{
    id: '1',
    name: [{ language: 'es', value: 'Todo incluido' },
    { language: 'en', value: ' All inclusive' },
    { language: 'fr', value: 'Tout compris' },
    { language: 'de', value: 'Alles inklusive' }],
    code: '12345',
    internNote: 'Hoteles selectos',
    syncReward: true
},
{
    id: '2',
    name: [{ language: 'es', value: 'Media pension' },
    { language: 'en', value: ' All inclusive' },
    { language: 'fr', value: 'Tout compris' },
    { language: 'de', value: 'Alles inklusive' }],
    code: 'aabbcc',
    internNote: '-',
    syncReward: true
},
{
    id: '3',
    name: [{ language: 'es', value: 'Sólo alojamiento' },
    { language: 'en', value: ' All inclusive' },
    { language: 'fr', value: 'Tout compris' },
    { language: 'de', value: 'Alles inklusive' }],
    code: 'abcde',
    internNote: '-',
    syncReward: true
}
]



export const regimesConfigSearchMock: Array<RegimesConfigData> = [{
    id: '3',
    name: [{ language: 'es', value: 'Sólo alojamiento' },
    { language: 'en', value: ' All inclusive' },
    { language: 'fr', value: 'Tout compris' },
    { language: 'de', value: 'Alles inklusive' }],
    code: 'abcde',
    internNote: '-',
    syncReward: true
}]


export const regimesConfigEditMock: RegimesConfigData = {
    id: '1',
    name: [{ language: 'es', value: 'Todo incluido' },
    { language: 'en', value: ' All inclusive' },
    { language: 'fr', value: 'Tout compris' },
    { language: 'de', value: 'Alles inklusive' }],
    code: '12345',
    internNote: 'Hoteles selectos',
    syncReward: true
}






// ---------HOTELS------------

export const hotelsConfigMock: Array<HotelsConfigData> = [{
    id: '1',
    name: "Hotel 1",
    category: { number: 5, type: "star" },
    code: '2542',
    destination: 'Madrid',
    numberRooms: 123,
    active: true,
},
{
    id: '2',
    name: "Hotel 2",
    category: { number: 3, type: "star" },
    code: '5465',
    destination: 'Barcelona',
    numberRooms: 500,
    active: true,
},
{
    id: '3',
    name: "Hotel 3",
    category: { number: 3, type: "key" },
    code: '6456',
    destination: 'Londres',
    numberRooms: 153,
    active: false,
}, {
    id: '4',
    name: "Hotel 4",
    category: { number: 2, type: "star" },
    code: '5675',
    destination: 'Cancún',
    numberRooms: 40,
    active: true,
}
]


export const hotelsConfigSearchMock: Array<HotelsConfigData> = [{
    id: '1',
    name: "Hotel 1",
    category: { number: 5, type: "star" },
    code: '2542',
    destination: 'Madrid',
    numberRooms: 123,
    active: true,
},
{
    id: '2',
    name: "Hotel 2",
    category: { number: 3, type: "star" },
    code: '5465',
    destination: 'Barcelona',
    numberRooms: 500,
    active: true,
}]


export const hotelsConfigEditMock: HotelsConfigEditData = {
    id: '4',
    name: "Hotel 4",
    category: { number: 2, type: "star" },
    code: '5675',
    destination: 'Cancún',
    numberRooms: 40,
    active: true,
    regimen: [{ id: '1', name: 'Todo incluido', active: true }, { id: '2', name: 'Media pensión', active: false }, { id: '3', name: 'Sólo alojamiento', active: true }],
    rooms: [{ id: '1', name: 'Habitación simple', active: true }, { id: '2', name: 'Habitación doble', active: false }, { id: '3', name: 'Habitación triple', active: true }],
    syncReward: true
}


export const hotelRoomMock: Array<HotelRoomsData> = [
    {
        id: '1',
        name: 'Habitación simple',
        active: true
    },
    {
        id: '2',
        name: 'Habitación doble',
        active: true
    },
    {
        id: '3',
        name: 'Habitación triple',
        active: false
    },
    {
        id: '4',
        name: 'Habitación doble Superior',
        active: false
    }

]


export const newHotelRoomMock: Array<HotelRoomsData> = [
    {
        id: '1',
        name: 'Habitación simple',
        active: false
    },
    {
        id: '2',
        name: 'Habitación doble',
        active: false
    },
    {
        id: '3',
        name: 'Habitación triple',
        active: false
    },
    {
        id: '4',
        name: 'Habitación doble Superior',
        active: false
    }

]


export const hotelRegimenMock: Array<HotelRegimensData> = [
    {
        id: '1',
        name: ' Todo incluido',
        active: true
    },
    {
        id: '2',
        name: 'Media pensión',
        active: true
    },
    {
        id: '3',
        name: ' Sólo alojamiento',
        active: false
    }
    ,
    {
        id: '4',
        name: ' Alojamiento y desayuno',
        active: false
    }
    ,
    {
        id: '5',
        name: ' Pensión completa',
        active: false
    }

]



export const newHotelRegimenMock: Array<HotelRegimensData> = [
    {
        id: '1',
        name: ' Todo incluido',
        active: false
    },
    {
        id: '2',
        name: 'Media pensión',
        active: false
    },
    {
        id: '3',
        name: ' Sólo alojamiento',
        active: false
    }
    ,
    {
        id: '4',
        name: ' Alojamiento y desayuno',
        active: false
    }
    ,
    {
        id: '5',
        name: ' Pensión completa',
        active: false
    }

]




export const serviceConfigMock: Array<ServicesConfigData> = [
    {
        id: '1',
        name: [
            {
                language: 'es',
                value: 'Spa'
            },
            {
                language: 'en',
                value: 'Spa'
            },
            {
                language: 'de',
                value: 'Wellness'
            },
            {
                language: 'fr',
                value: 'Spa'
            },
        ],
        code: '00098',
        internNote: 'Hoteles selectos',
        syncReward: true


    },

    {
        id: '2',
        name: [
            {
                language: 'es',
                value: 'Piscina'
            },
            {
                language: 'en',
                value: 'Pool'
            },
            {
                language: 'de',
                value: 'Schwimmbad'
            },
            {
                language: 'fr',
                value: 'Piscine'
            },
        ],
        code: '12345',
        internNote: '-',
        syncReward: true
    },
    {
        id: '3',
        name: [
            {
                language: 'es',
                value: 'Golf'
            },
            {
                language: 'en',
                value: 'Golf'
            },
            {
                language: 'de',
                value: 'Golf'
            },
            {
                language: 'fr',
                value: 'Golf'
            },
        ],
        code: 'ABCDE',
        internNote: '-',
        syncReward: false
    },
    {
        id: '4',
        name: [
            {
                language: 'es',
                value: 'RestaurantE'
            },
            {
                language: 'en',
                value: 'Restaurant'
            },
            {
                language: 'de',
                value: 'Restaurant'
            },
            {
                language: 'fr',
                value: 'Restaurant'
            },
        ],
        code: 'AAAAAA',
        internNote: '-',
        syncReward: true
    }



]




export const serviceConfigSearchMock: Array<ServicesConfigData> = [

    {
        id: '2',
        name: [
            {
                language: 'es',
                value: 'Piscina'
            },
            {
                language: 'en',
                value: 'Pool'
            },
            {
                language: 'de',
                value: 'Schwimmbad'
            },
            {
                language: 'fr',
                value: 'Piscine'
            },
        ],
        code: '12345',
        internNote: '-',
        syncReward: true
    },
    {
        id: '3',
        name: [
            {
                language: 'es',
                value: 'Golf'
            },
            {
                language: 'en',
                value: 'Golf'
            },
            {
                language: 'de',
                value: 'Golf'
            },
            {
                language: 'fr',
                value: 'Golf'
            },
        ],
        code: 'ABCDE',
        internNote: '-',
        syncReward: false
    }



]