
import { Card, Col, Row, Switch } from 'antd';
import AccountUserData from '../../../models/AccountUserData';
import { useTranslation } from 'react-i18next';

import './AccountUsersListComponent.scss'
import { GetUserIcon } from '../../../utils/icons';
import moment from 'moment';
import { useAppConfig } from '../../../Contexts/AppConfigContext';


interface AccountUsersListComponentProps {
    list: AccountUserData[],
    onChangeUserStatus: (userId: string, status: boolean) => void;
}

const AccountUsersListComponent = (props: AccountUsersListComponentProps): JSX.Element => {

    const { list, onChangeUserStatus} = props;

    const appConfig = useAppConfig();

    const { t } = useTranslation();

    const changeUserStatus = (userId: string, status: boolean) => {
        onChangeUserStatus(userId, status);

    }

    return (
        <div className="account-users-list-component">
            {
                list.map((item: AccountUserData, index: number) => {
                    var cardHeader : JSX.Element =  <div key={index} className="header-container">
                                                        <div className="resume-header">
                                                            &nbsp;
                                                        </div>
                                                        <div className="actions-container">
                                                            <Switch className="app-switch" defaultChecked={item.status=='active'} onChange={(isEnabled: any)=>{changeUserStatus(item.id, isEnabled)}}/>
                                                        </div>
                                                    </div>

                    return(
                        <Card className="app-card" title={cardHeader} bordered={true}>
                            <Row>
                                <Col className="info" xs={24}>
                                    <span>
                                        { GetUserIcon('app-icon width-18px')} <span>{item.name} {item.surname} {item.secondSurname}</span>
                                        <small>
                                            {t('components.account-users.list.creation-date', [moment(item.created_at).format(appConfig.config.dateFormat)])}
                                        </small>
                                    </span>
                                    <span className="with-padding"> {item.email}</span>
                                    <span className="with-padding">{item.phone}</span>
                                </Col>
                            </Row> 
                        </Card>
                    )
                })
            }
        </div>
    )
}

export default AccountUsersListComponent;