import { useTranslation } from "react-i18next";
import { Select, Space } from "antd";
import { availableAppLanguages } from "../../../../Contexts/AppConfigContext";
import { GetDefaultLanguage } from "../../../../utils/urls";
import { ColorPicker } from 'primereact/colorpicker';
import { Row, Col } from "antd";
import { useEffect, useState } from "react";
import { Radio } from "antd";
import { fetchClientPROPersonalitationData, editDesignPROPersonalization, editMainColorWebPROPersonalization, editSecondaryColorWebPROPersonalization, editMainColorWidgetPROPersonalization, editSecondaryColorWidgetPROPersonalization, editPROConfiguration } from "../../../../api/repositoryEP";
import { notification } from 'antd';
import { Button } from "antd";
import './ProConfiguration.scss';



const ProConfiguration = () => {
    const { t } = useTranslation();
    const languages = availableAppLanguages;
    const [language, setLanguage] = useState(GetDefaultLanguage());
    // por defecto que sea el lenguaje que tiene el usurario
    const [mainColorWeb, setMainColorWeb] = useState("#aabbcc");
    const [secondaryColorWeb, setSecondaryColorWeb] = useState("#aabbcc");
    const [mainColorWidget, setMainColorWidget] = useState("#aabbcc");
    const [secondaryColorWidget, setSecondaryColorWidget] = useState("#aabbcc");
    const [model, setModel] = useState(1);




    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetchClientPROPersonalitationData(language);
                if (response?.code === 200) {
                    setMainColorWeb(response.data.mainColorWeb);
                    setMainColorWidget(response.data.mainColorWidget);
                    setSecondaryColorWeb(response.data.secondaryColorWeb);
                    setSecondaryColorWidget(response.data.secondaryColorWidget);
                    setModel(response.data.design);
                } else {
                    showErrorMessage();
                }

            } catch (error) {
               showErrorMessage();
            }
        };

        fetchData();
    }, [])


    const handleMainColorWeb = (e: any) => {
        setMainColorWeb(e.target.value);
    }


    const handleSecondaryColorWeb = (e: any) => {
        setSecondaryColorWeb(e.target.value);
    }


    const handleMainColorWidget = (e: any) => {
        setMainColorWidget(e.target.value);
    }

    const handleSecondaryColorWidget = (e: any) => {
        setSecondaryColorWidget(e.target.value);
    }


    const handleLanguage = (language: any) => {
        {
            setLanguage(language);
            const fetchData = async () => {
                try {
                    const response = await fetchClientPROPersonalitationData(language);
                    if (response?.code === 200) {
                        setMainColorWeb(response.data.mainColorWeb);
                        setMainColorWidget(response.data.mainColorWidget);
                        setSecondaryColorWeb(response.data.secondaryColorWeb);
                        setSecondaryColorWidget(response.data.secondaryColorWidget);
                        setModel(response.data.design);
                    } else {
                        showErrorMessage();
                    }
                } catch (error) {
                    showErrorMessage();
                }
            };
            fetchData();
        }
    }


    const saveColors = () => {
        const fetchData = async () => {
            try {
                const response = await editPROConfiguration(language, model, mainColorWeb, secondaryColorWeb, mainColorWidget, secondaryColorWidget);
                if (response?.code == 200) {
                    showSuccessNotification();
                } else {
                    showErrorMessage();
                }
            } catch (error) {
                showErrorMessage();
            }
        };
        fetchData();
        const scrollTopElement = document.getElementById("content-page")!;
        scrollTopElement.scrollTop = 0
    }


    const showErrorMessage = () => {
        notification.error({
            message: t('actions.error-message'),
            description: t('actions.error-description')
        });
    }
    const showSuccessNotification = () => {
        notification.success({
            message: t('actions.success-message'),
            description: t('actions.success-description')
        });
    }


    const onChangeModel = (e: any) => {
        setModel(e.target.value);
    }

    return (
        <div className="pro-configuration-component">
           <h2>
                {t("components.pro-configuration.title-language")}
            </h2>
            <Row gutter={4} style={{ flexDirection: 'column' }}>
                <Col xs={24} md={24} className="select-left">
                    <Select className="select-large" defaultValue={language} onChange={handleLanguage}>
                        {languages.map(language => (
                            <Select.Option key={language} value={language}>
                                {t(`languages.${language}`)}
                            </Select.Option>
                        ))}
                    </Select>
                </Col>

                <h2 className="h2">
                    {t("components.pro-configuration.title-colors")}
                </h2>
                <Col xs={24} md={24}>
                    <Row gutter={4} style={{ flexDirection: 'row', marginBottom: "0px" }}>
                        <Col xs={12} md={6}>
                            <span>
                                <div className="text">{t("components.pro-configuration.main-color-web")}</div>
                                <ColorPicker
                                    value={mainColorWeb}
                                    onChange={handleMainColorWeb}
                                    format="hex"
                                /> </span>
                        </Col>
                        <Col xs={12} md={6}>

                            <span>
                                <div className="text">{t("components.pro-configuration.secondary-color-web")}</div>
                                <ColorPicker
                                    value={secondaryColorWeb}
                                    onChange={handleSecondaryColorWeb}
                                    format="hex"
                                />
                            </span>
                        </Col>

                        <Col xs={12} md={6}>
                            <span>
                                <div className="text">{t("components.pro-configuration.main-color-widget")}</div>
                                <ColorPicker
                                    value={mainColorWidget}
                                    onChange={handleMainColorWidget}
                                    format="hex"
                                />
                            </span>
                        </Col>
                        <Col xs={12} md={6}>
                            <span>
                                <div className="text">{t("components.pro-configuration.secondary-color-widget")}</div>
                                <ColorPicker
                                    value={secondaryColorWidget}
                                    onChange={handleSecondaryColorWidget}
                                    format="hex"
                                />
                            </span>
                        </Col>
                    </Row>
                </Col>
                <h2 className="h2">
                    {t("components.pro-configuration.title-designs")}
                </h2>
                <Col xs={24} md={24}>
                    <Radio.Group onChange={onChangeModel} value={model} >
                        <Radio value={1}>
                            <div className="radio-content">
                                <span className="text">{t("components.pro-configuration.landing-1")}</span>
                                <div className="image-container">
                                    <img className="image-config" src='/images/landing1.png' alt="Descripción de la imagen" />
                                </div>
                            </div>
                        </Radio>
                        <Radio value={2}>
                            <div className="radio-content">
                                <span className="text">{t("components.pro-configuration.landing-2")}</span>
                                <div className="image-container">
                                    <img className="image-config" src='/images/landing2.png' alt="Descripción de la imagen" />
                                </div>
                            </div></Radio>
                    </Radio.Group>
                </Col>
            </Row>
            <Col xs={24} md={24}>
                <Button className="app-button" style={{ marginTop: 50 }} onClick={saveColors}>
                    {t("components.button.save")}
                </Button>
            </Col>
        </div>
    );
};

export default ProConfiguration;
