import { useTranslation } from 'react-i18next';
import './LegalWarningPage.scss';

const LegalWarningPage = () : JSX.Element => {
    const { t } = useTranslation();
    
    return(
        <div className="legal-warning">
                {t('pages.legal-warning.title')}
        </div>
    )
}

export default LegalWarningPage;