import { useEffect, useState } from "react";
import { UserEdit } from "../../models/UsersInfo";
import { useTranslation } from "react-i18next";
import { Button, Col, Dropdown, Form, Input, Row, Select, Checkbox } from "antd";
import "./ProfileForm.scss";
import {  isValidUsername } from "../../api/repositoryEP";
import EditUserData from "../../models/EditUserData";
import { editUser } from "../../api/repositoryEP";
import { notification } from 'antd';

interface ProfileFormProps {
    userData: UserEdit | null;
    setUserData: (userData: UserEdit | null) => void;
}

const ProfileForm =  (props : ProfileFormProps): JSX.Element => {
    const [form] = Form.useForm();
    const [usernameValue, setUsernameValue] = useState<string>(
        props.userData?.username !== undefined ? props.userData.username : ""
    );
    
    const [isValidUsernameInput, setIsValidUsernameInput] = useState<boolean>(true);
    const [passwordValue, setPasswordValue] = useState<string>("");
    const [isEmptyUsername, setIsEmptyUsername] = useState<boolean>(false);
    const { t } = useTranslation();

    useEffect(() => {

      //  setUsernameValue(props.userData?.username)
    }, []);


    const handleInputBlur = async () => {
        setIsValidUsernameInput(true);
        if (usernameValue === "") {
             setIsEmptyUsername(true) 
        } else {
            setIsEmptyUsername(false)
            const response = await isValidUsername(usernameValue);
            if (response?.code === 200) {
                setIsValidUsernameInput(response.data);
            } else {
                setIsValidUsernameInput(false);
            }
        }
    }


    const handleChangeUsername = (e : any) => {
        setUsernameValue( e.target.value);
      };

    const onFinish = async (values: any) => {
        if (isValidUsernameInput) {
            // creamos el objeto con los datos del formulario y llamamos a la api
            const userData: EditUserData = {
                name: values.name,
                surname: values.surname,
                lastSurname: values["second-surname"],
                email: values.email,
                phone: values.phone,
                username: values.username,
                password: values.password,
                rol: "admin",
                language: values.language,
                notify: values.notifications
            }

            const response = await editUser(userData);
            if (response?.code == 200) {
                props.setUserData(response.data);
                //////////////// MENSAJE DE NOTIFICACION DE ÉXITO
                notification.success({
                    message: t('actions.success-message'),
                    description: t('actions.success-description')
                });

            } else {
                notification.error({
                    message: t('actions.error-message'),
                    description: t('actions.error-description')
                });
            }
        }
    };


   return (
        <div className="profile-info">
            <Form form={form} onFinish={onFinish} layout="vertical">
                <Row gutter={16}>
                    {props.userData && (<>
                        <Col xs={24} md={24}>
                            <h3>
                                {t("components.edit-users.general").toUpperCase()}
                            </h3>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item
                                name="name"
                                initialValue={props.userData.name}
                                label={t("components.edit-users.name")}
                                rules={[{ required: true, message: t("components.edit-users.validations.name-required") ?? "Name is required" }]}
                            >
                                <Input className="app-input"/>
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={24}>
                            <Form.Item
                                name="surname"
                                initialValue={props.userData.surname}
                                label={t("components.edit-users.surname")}
                                rules={[{ required: true, message: t("components.edit-users.validations.surname-required") ?? "Surname is required" }]}
                            >
                                <Input className="app-input"/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item
                                name="second-surname"
                                label={t("components.edit-users.second-surname") }
                                initialValue={props.userData.secondSurname}
                            >
                                <Input className="app-input" />
                            </Form.Item>
                        </Col>


                        <Col xs={24} md={24}>
                            <h3>
                                {t("components.edit-users.contact").toUpperCase()}
                            </h3>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item
                                name="email"
                                initialValue={props.userData.email}
                                label={t("components.edit-users.email")}
                                rules={[{ required: true, message: t("components.edit-users.validations.email-required") + "" }]}

                            >
                                <Input className="app-input" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item
                                name="phone"
                                initialValue={props.userData.phone}
                                label={t("components.edit-users.phone")}
                                rules={[
                                    {
                                        pattern: /^[0-9]*$/,
                                        message: t("components.edit-users.validations.phone-numeric") + "",
                                    },
                                ]}
                            >
                                <Input className="app-input"/>
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={24}>
                            <h3>
                                {t("components.edit-users.access").toUpperCase()}
                            </h3>
                        </Col>


                        <Col xs={24} md={24}>
                            <Form.Item
                                name="password"
                                label={t("components.edit-users.password")}
                            >
                                <Input.Password className="app-input"
                                    value={passwordValue} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={24}>
                            <Form.Item
                                name="repeat-password"
                                label={t("components.edit-users.repeat-password")}
                                dependencies={['password']}

                                rules={[
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            const passwordFieldValue = getFieldValue('password');
                                            if (!passwordFieldValue && !value) {
                                                // Ambos campos están vacíos, no hay error
                                                return Promise.resolve();
                                            }
                                            if (!value && passwordFieldValue) {
                                                return Promise.reject(t("components.edit-users.validations.password-required"))
                                            }
                                            if (!value || passwordFieldValue === value) {
                                                // El campo de repetir contraseña está vacío o coincide con el campo de contraseña
                                                return Promise.resolve();
                                            }
                                            // Los campos no coinciden
                                            return Promise.reject(t("components.edit-users.validations.password-not-match"));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password
                                    className="app-input"    />
                            </Form.Item>
                        </Col>


                        <Col xs={24} md={24}>

                            <Form.Item
                                name="username"
                                label={t("components.edit-users.username")}
                                initialValue={props.userData.username}
                                rules={[
                                    {
                                        required: true,
                                        message: t("components.edit-users.validations.username-required") + ""
                                    },
                                ]}
                                validateStatus={(!isValidUsernameInput || isEmptyUsername) ? 'error' : 'success'}
                                help={(!isValidUsernameInput
                                    && t("components.edit-users.validations.username-not-valid"))
                                    || (isEmptyUsername && t("components.edit-users.validations.username-required"))
                                }
                            >
                                <Input className="app-input"                                  
                                    value={usernameValue}
                                    onChange={handleChangeUsername}
                                    onBlur={handleInputBlur}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={24}>
                            <h3>
                                {t("components.edit-users.language").toUpperCase()}
                            </h3>
                        </Col>


                        <Col xs={24} md={24}>
                            <Form.Item name="language" initialValue={props.userData.language} rules={[{ required: true, message: t("components.edit-users.validations.language-required") + "" }]} >
                                <Select className="app-input" placeholder={t("components.edit-users.select")}>
                                    <Select.Option value="es">{t("languages.es")}</Select.Option>
                                    <Select.Option value="en">{t("languages.en")}</Select.Option>
                                    <Select.Option value="fr">{t("languages.fr")}</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>


                        <Col xs={24} md={20}></Col>
                        <Col xs={24} md={4}>
                            <Form.Item style={{ textAlign: 'center' }}>
                                <Button className="app-button" htmlType="submit" style={{ width: "100%", alignItems:"right"}} >
                                    {t("components.edit-users.btn-save")}
                                </Button>
                            </Form.Item>
                        </Col>
                    </>

                    )}
                </Row>

            </Form>
        </div>
    );
};


export default ProfileForm;