

import { useTranslation } from 'react-i18next';
import { AccountsFilter, AccountsList, CustomPagination } from '../../../components';

import {AccountFilter} from '../../../models/AccountsFilter';

import './AccountsPage.scss';
import { fetchAccounts, fetchAgencies, fetchCompanies } from '../../../api/repositoryEP';
import { useEffect, useState } from 'react';
import AccountData from '../../../models/AccountData';
import { SmallCompanyData } from '../../../models/SmallCompanyData';
import { SmallAgencyData } from '../../../models/SmallAgencyData';

const AccountsPage = () : JSX.Element => {
    const { t } = useTranslation();

    const [companies, setCompanies] = useState<SmallCompanyData[]>([]);
    const onSearchCompany = (companyName: string) => {

        fetchCompanies(companyName).then((r)=>{
            if (r?.typeText === "success" && r?.data){
                console.log("Companies", r?.data)
                setCompanies(r?.data as SmallCompanyData[]);
            }else{
                setCompanies([]);
            }
        }).finally(()=>{})
    }

    const [agencies, setAgencies] = useState<SmallAgencyData[]>([]);
    const onSearchAgency = (agencyName: string) => {
            fetchAgencies(agencyName).then((r)=>{
                if (r?.typeText === "success" && r?.data){
                    console.log("Agencies", r?.data)
                    setAgencies(r?.data as SmallAgencyData[]);
                }else{
                    setAgencies([]);
                }
            }).finally(()=>{})
    }

    const [totalElements, setTotalElements] = useState<number>(0);
    const [accountList, setAccountList] = useState<AccountData[]>([]);
    const [searching, setSearching] = useState<boolean>(false);
    const onSearch = (filterData: AccountFilter = {
        name: '',
        email: '',
        status: '',
        location: '',
        accountType: '',
        page: 0,
        pageSize: 5,
    
    }) => {
        setSearching(true);
        fetchAccounts(filterData).then((r)=>{
            if (r?.typeText === "success" && r?.data){
                setAccountList(r?.data.list as AccountData[]);
                setTotalElements(r?.data.totalElements as number);
            }else{
                setAccountList([]);
                setTotalElements(0);
            }
        }).finally(()=>{
            setSearching(false);
        })
    }

    useEffect(()=>{
        //Llamada inicial
        onSearch();
    },[]);

    return(
        <div className="accounts-page">
            <h3>
                {t('pages.accounts.title')}
                <small>
                    {t('pages.accounts.subtitle')}
                </small>
            </h3>
            <div className="filter-container">
                <AccountsFilter onSearch={onSearch} searching={searching} />
            </div>
            <div className="list-container">
                <AccountsList list={accountList} loading={searching} 
                              onSearchAgency={onSearchAgency} 
                              onSearchCompany={onSearchCompany}
                              agencies={agencies}
                              companies={companies}
                />
            </div>
            <div className="pagination-container">
                <CustomPagination totalElements={totalElements} />
            </div>
        </div>
    )
}

export default AccountsPage;