import { useTranslation } from "react-i18next";
import { Button, Col, Dropdown, Form, Input, Row } from "antd";

import "./AccountUsersFilterComponent.scss";
import { FileExcelOutlined, SearchOutlined } from "@ant-design/icons";

interface AccountUsersFilterProps {
  onSearch: (searchText: string) => void;
  searching: boolean;
}

const AccountUsersFilterComponent = (props: AccountUsersFilterProps): JSX.Element => {
  const { t } = useTranslation();

  const { searching } = props;

  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    props.onSearch(values.searchText);
  };

  const items = [
    {
      key: '1',
      label: t("components.account-users.filter.all"),
    },
    {
      key: '2',
      label: t("components.account-users.filter.active"),
    },
    {
      key: '3',
      label: t("components.account-users.filter.inactive"),
    }
  ];

const onMenuClick = ({ key }: any) => {
    console.log(`Click on item ${key}`);
};

  return (
    <div className="account-users-filter">
      
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Row gutter={16}>
          <Col xs={24}>
            <div className="export-data-content">
                  <Dropdown.Button menu={{ items, onClick: onMenuClick }} className="export-button-dropdown">
                      <FileExcelOutlined className="excel-button"/> {t("components.accounts.filter.export-data")}
                  </Dropdown.Button>
              </div>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="name"
              label={t("components.account-users.filter.name-and-surname")}
            >
              <Input className="app-input"  />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="email"
              label={t("components.account-users.filter.email")}
            >
              <Input className="app-input" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="status"
              label={t("components.account-users.filter.status")}
            >
              <Input className="app-input" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="agency"
              label={t("components.account-users.filter.agency")}
            >
              <Input className="app-input" />
            </Form.Item>
          </Col>
          
          <Col xs={24} md={{span: 8, offset: 8}}>
            <Form.Item>
              <Button className="app-button" style={{width: "100%"}} htmlType="submit" loading={searching}>
                {t("components.account-users.filter.search")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AccountUsersFilterComponent;
