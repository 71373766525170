export var companiesClientList = [
    {
        "id": "a1b2c3d4-e5f6-g7h8-i9j0-k1l2m3n4o5p6",
        "name": "Widget Solutions Inc.",
        "address": "123 Main Street, Anytown, USA",
        "telephone": "555-123-4567",
        "identityCode": "WSI123456",
        "idCurrency": "USD",
        "isAlreadyUsed": true
    },
    {
        "id": "b7c8d9e0-f1g2-h3i4-j5k6l7m8n9o0p1",
        "name": "Global Tech Enterprises",
        "address": "456 Tech Avenue, Techland, Canada",
        "telephone": "789-555-2345",
        "identityCode": "GTE987654",
        "idCurrency": "CAD",
        "isAlreadyUsed": false
    },
    {
        "id": "c2d3e4f5-g6h7-i8j9-k0l1m2n3o4p5",
        "name": "Innovative Innovations Ltd.",
        "address": "789 Innovation Boulevard, Innovatown, Australia",
        "telephone": "123-987-3456",
        "identityCode": "III567890",
        "idCurrency": "AUD",
        "isAlreadyUsed": true
    },
    {
        "id": "d4e5f6g7-h8i9-j0k1-l2m3n4o5-p6q7r8s9t0",
        "name": "NexGen Tech Solutions",
        "address": "567 Tech Street, Technoville, Germany",
        "telephone": "456-789-5678",
        "identityCode": "NTS234567",
        "idCurrency": "EUR",
        "isAlreadyUsed": true
    },
    {
        "id": "e5f6g7h8-i9j0-k1l2-m3n4o5p6q7-r8s9t0u1v2w3",
        "name": "Futuristic Systems Ltd.",
        "address": "890 Future Avenue, Futuricity, Japan",
        "telephone": "567-234-6789",
        "identityCode": "FSL345678",
        "idCurrency": "JPY",
        "isAlreadyUsed": false
    },    {
        "id": "1a2b3c4d-5e6f-7g8h-9i0j-1k2l3m4n5o6p",
        "name": "TechNova Solutions",
        "address": "123 Innovator Street, Innovatown, USA",
        "telephone": "555-123-4567",
        "identityCode": "TN123456",
        "idCurrency": "USD",
        "isAlreadyUsed": false
    },
    {
        "id": "2b3c4d5e-6f7g-8h9i0j1k-2l3m4n5o6p7q",
        "name": "GloboCom Systems",
        "address": "456 Tech Plaza, Techland, Canada",
        "telephone": "789-555-2345",
        "identityCode": "GC987654",
        "idCurrency": "CAD",
        "isAlreadyUsed": false
    },
    {
        "id": "3c4d5e6f-7g8h9i-0j1k2l3m4n5o-6p7q8r9s0t",
        "name": "AlphaGen Innovations",
        "address": "789 Innovation Avenue, Innovacity, Australia",
        "telephone": "123-987-3456",
        "identityCode": "AGI567890",
        "idCurrency": "AUD",
        "isAlreadyUsed": false
    },
    {
        "id": "4d5e6f7g-8h9i0j1k-2l3m4n5o6p7q8-9r0s1t2u3v",
        "name": "PowerTech Dynamics",
        "address": "567 Energy Road, Powerburg, Germany",
        "telephone": "456-789-5678",
        "identityCode": "PTD234567",
        "idCurrency": "EUR",
        "isAlreadyUsed": false
    },
    {
        "id": "5e6f7g8h-9i0j1k-2l3m4n5o6p7q-8r9s0t1u2v3w",
        "name": "WaveLink Communications",
        "address": "890 Wave Street, Waveville, Japan",
        "telephone": "567-234-6789",
        "identityCode": "WLC345678",
        "idCurrency": "JPY",
        "isAlreadyUsed": false
    },
    {
        "id": "6f7g8h9i-0j1k2l3m4n5o-6p7q8r9s0t1u2v3w4x",
        "name": "EcoTech Solutions",
        "address": "123 Green Avenue, EcoCity, USA",
        "telephone": "555-789-0123",
        "identityCode": "ETS678901",
        "idCurrency": "USD",
        "isAlreadyUsed": false
    },
    {
        "id": "7g8h9i0j-1k2l3m4n5o-6p7q8r9s0t1u2v3w4x5y",
        "name": "InterGlobal Innovate",
        "address": "456 Innovator Lane, Globetown, Canada",
        "telephone": "789-234-5678",
        "identityCode": "IGI789012",
        "idCurrency": "CAD",
        "isAlreadyUsed": false
    },
    {
        "id": "8h9i0j1k-2l3m4n5o6p-7q8r9s0t1u2v3w4x5y6z",
        "name": "SynthoTech Industries",
        "address": "789 Synth Street, Synthoville, Australia",
        "telephone": "123-456-7890",
        "identityCode": "STI890123",
        "idCurrency": "AUD",
        "isAlreadyUsed": false
    },
    {
        "id": "9i0j1k2l-3m4n5o6p7q-8r9s0t1u2v3w4x5y6z0a",
        "name": "QuantoSoft Systems",
        "address": "567 Software Road, Digitown, Germany",
        "telephone": "456-678-9012",
        "identityCode": "QSS901234",
        "idCurrency": "EUR",
        "isAlreadyUsed": false
    },
    {
        "id": "1k2l3m4n-5o6p7q8r9s-0t1u2v3w4x5y6z0a1b2c",
        "name": "MegaFusion Tech",
        "address": "890 Fusion Avenue, Megatown, Japan",
        "telephone": "567-890-1234",
        "identityCode": "MFT012345",
        "idCurrency": "JPY",
        "isAlreadyUsed": false
    }
];