import { ErrorInfo } from '../models/ErrorInfo';
import UserSessionData from '../models/UserSessionData';
import ApiResponse from '../models/api/ApiResponse';
import ParamsApi from '../models/api/ParamsApi';
import RestApiClient from './restApiClient';

//Mocks
import { configClientApplication } from '../mocks/applicationConfig';
import { templateInfo } from '../mocks/templates';
import { accountDataList } from '../mocks/accounts';
import { AccountFilter } from '../models/AccountsFilter';
import { BookingFilter } from '../models/BookingsFilter';
import { bookingDataExample, exampleBookings } from '../mocks/bookings';
import { hotelsDemo } from '../mocks/hotels';
import { companiesClientList } from '../mocks/companies';
import { agenciesList } from '../mocks/agecies';
import { accountUsersExample } from '../mocks/accountUsers';
import { UsersFilterData } from '../models/UsersFilterData';
import {AddRegisterFormFieldsData} from '../models/RegisterFormData';
import { FormGroupData, FormGroupDataRequest, LandingPageCodeData } from '../models/ConfigurationData';
import { userDataList } from '../mocks/users';
import NewUserData from '../models/NewUserData';
import { userEditMock } from '../mocks/userEditInfo';
import EditUserData from '../models/EditUserData';
import {EditRegisterFormFieldsData} from '../models/RegisterFormData';
import { configurationDataPRO } from '../mocks/configuration';
import {fieldsRegisterFormDemo} from '../mocks/fieldsRegisterForm'
import {RegisterFormFilterData} from '../models/RegisterFormData';
import {landingPageCodeMock} from '../mocks/landingPageCode';
import {groupFormMock} from '../mocks/groupFormMock';
import {servicesMock, servicesMockEnglish} from '../mocks/servicesMock';
import { hotelRegimenMock, hotelRoomMock, hotelsConfigEditMock, hotelsConfigMock, hotelsConfigSearchMock, languagesAvailableMock, newHotelRegimenMock, newHotelRoomMock, regimesConfigEditMock, regimesConfigMock, regimesConfigSearchMock, roomConfigMock, roomConfigSearchMock, serviceConfigMock, serviceConfigSearchMock } from '../mocks/mappingMocks';
import RoomConfigData, { HotelsConfigCreateData, HotelsConfigEditData, HotelsConfigEditStatusData, RegimesConfigCreateData, RegimesConfigData, RoomConfigCreateData, ServicesConfigCreateData, ServicesConfigData } from '../models/MappingData';

const sleep = (ms: number): Promise<void> => new Promise(resolve => setTimeout(resolve, ms));

var restApiClient : RestApiClient = new RestApiClient();;

// Si esta variable está a TRUE cuando se produce un error en una llamada API se redirige al usuario a la página de error
const REDIRECT_TO_ERROR_PAGE : boolean = true;


export async function pushErrorNotificationData(errorInfo: ErrorInfo) : Promise<ApiResponse | null>{
    console.warn("Calling to: pushErrorNotificationData", errorInfo);

    try{
        return {
            additionalMessages: [],
            code: 200,
            data: true,
            message: "",
            type: 200,
            typeText: "success"
        };

        const response = await restApiClient.fetch("PushErrorNotification", {
            body:errorInfo
        },  REDIRECT_TO_ERROR_PAGE);
        return response!;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function fetchClientAppCustomData() : Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: fetchClientAppCustomData");

        return {
            additionalMessages: [],
            code: 200,
            data: configClientApplication,
            message: "",
            type: 200,
            typeText: "success"
        };

        const response = await restApiClient.fetch("GetCustomAppData", {},  REDIRECT_TO_ERROR_PAGE);
        return response!;
        
    } catch (error) {
        console.error('Error fetching colors:', error);
        return null;
    }
}


export async function fetchClientPROPersonalitationData(language: string) : Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: fetchClientPROPersonalitationData");

        return {
            additionalMessages: [],
            code: 200,
            data: configurationDataPRO,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                language: language
            }
        }

        const response = await restApiClient.fetch("GetPROPersonalitationData", params,  REDIRECT_TO_ERROR_PAGE);
        return response!;
        
    } catch (error) {
        console.error('Error fetching colors:', error);
        return null;
    }
}


export async function editDesignPROPersonalization( language: string, model: number) : Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: editDesignPROPersonalization");

        return {
            additionalMessages: [],
            code: 200,
            data: null,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                language: language,
                model: model
            }
        }

        const response = await restApiClient.fetch("EditDesignPROPersonalization", params,  REDIRECT_TO_ERROR_PAGE);
        return response!;
        
    } catch (error) {
        console.error('Error fetching colors:', error);
        return null;
    }
}


export async function editPROConfiguration( language: string, model: number, mainColorWeb: string, secondaryColorWeb : string, mainColorWidget : string, secondaryColorWidget : string) : Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: editPROConfiguration");

        return {
            additionalMessages: [],
            code: 200,
            data: null,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                language: language,
                model: model,
                mainColorWeb : mainColorWeb,
                secondaryColorWeb : secondaryColorWeb,
                mainColorWidget : mainColorWidget,
                secondaryColorWidget : secondaryColorWidget
            }
        }

        const response = await restApiClient.fetch("EditPROConfiguration", params,  REDIRECT_TO_ERROR_PAGE);
        return response!;
        
    } catch (error) {
        console.error('Error editing PRO Configuration:', error);
        return null;
    }
}



export async function editMainColorWebPROPersonalization( language: string, color: string) : Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: editMainColorWebPROPersonalization");

        return {
            additionalMessages: [],
            code: 200,
            data: null,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                language: language,
                color: color
            }
        }

        const response = await restApiClient.fetch("EditMainColorWebPROPersonalization", params,  REDIRECT_TO_ERROR_PAGE);
        return response!;
        
    } catch (error) {
        console.error('Error fetching colors:', error);
        return null;
    }
}

export async function editSecondaryColorWebPROPersonalization(language: string, color: string) : Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: editMainColorWebPROPersonalization");

        return {
            additionalMessages: [],
            code: 200,
            data: null,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                language: language,
                color: color
            }
        }

        const response = await restApiClient.fetch("EditSecondaryColorWebPROPersonalization", params,  REDIRECT_TO_ERROR_PAGE);
        return response!;
        
    } catch (error) {
        console.error('Error fetching colors:', error);
        return null;
    }
}


export async function editMainColorWidgetPROPersonalization(language: string, color: string) : Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: editMainColorWebPROPersonalization");

        return {
            additionalMessages: [],
            code: 200,
            data: null,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                language: language,
                color: color
            }
        }

        const response = await restApiClient.fetch("EditMainColorWidgetPROPersonalization", params,  REDIRECT_TO_ERROR_PAGE);
        return response!;
        
    } catch (error) {
        console.error('Error fetching colors:', error);
        return null;
    }
}

export async function editSecondaryColorWidgetPROPersonalization(language: string, color: string) : Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: editMainColorWebPROPersonalization");

        return {
            additionalMessages: [],
            code: 200,
            data: null,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                language: language,
                color: color
            }
        }

        const response = await restApiClient.fetch("EditSecondaryColorWidgetPROPersonalization", params,  REDIRECT_TO_ERROR_PAGE);
        return response!;
        
    } catch (error) {
        console.error('Error fetching colors:', error);
        return null;
    }
}



export async function postFieldRegisterForm(field: AddRegisterFormFieldsData) : Promise<ApiResponse | null>{
    console.warn("Calling to: postFieldRegisterForm", field);

    try{
        return {
            additionalMessages: [],
            code: 200,
            data: null,
            message: "",
            type: 200,
            typeText: "success"
        };

        const response = await restApiClient.fetch("PostFieldRegisterForm", {
            body:field
        },  REDIRECT_TO_ERROR_PAGE);
        return response!;

    }catch(error){
        console.error('Error post field register form :', error);
        return null;
    }
}


export async function editFieldRegisterForm(field: EditRegisterFormFieldsData) : Promise<ApiResponse | null>{
    console.warn("Calling to: editFieldRegisterForm", field);

    try{
        return {
            additionalMessages: [],
            code: 200,
            data: null,
            message: "",
            type: 200,
            typeText: "success"
        };

        const response = await restApiClient.fetch("PostFieldRegisterForm", {
            body:field
        },  REDIRECT_TO_ERROR_PAGE);
        return response!;

    }catch(error){
        console.error('Error edit field register form :', error);
        return null;
    }
}



export async function fetchFieldsRegisterForm( language: string) : Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: fetchFieldsRegisterForm");

        return {
            additionalMessages: [],
            code: 200,
            data: fieldsRegisterFormDemo,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                language: language
            }
        }

        const response = await restApiClient.fetch("GetFieldsRegisterForm", params,  REDIRECT_TO_ERROR_PAGE);
        return response!;
        
    } catch (error) {
        console.error('Error fetching colors:', error);
        return null;
    }
}



export async function fetchFilterFieldsRegisterForm(language: string,  filterData : RegisterFormFilterData ) : Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: fetchFieldsRegisterForm");

        return {
            additionalMessages: [],
            code: 200,
            data: fieldsRegisterFormDemo,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                language: language,
                filterData: filterData
            }
        }

        const response = await restApiClient.fetch("GetFilterFieldsRegisterForm", params,  REDIRECT_TO_ERROR_PAGE);
        return response!;
        
    } catch (error) {
        console.error('Error fetching colors:', error);
        return null;
    }
}



export async function editStateFieldForm(id: string, isActive: boolean  ) : Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: editStateFieldForm");

        return {
            additionalMessages: [],
            code: 200,
            data: null,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                id: id,
                isActive: isActive
            }
        }

        const response = await restApiClient.fetch("EditStateFieldForm", params,  REDIRECT_TO_ERROR_PAGE);
        return response!;
        
    } catch (error) {
        console.error('Error editing field form:', error);
        return null;
    }
}



export async function deleteFieldRegisterForm(id: string) : Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: deleteFieldRegisterForm");

        return {
            additionalMessages: [],
            code: 200,
            data: null,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                id: id
            }
        }

        const response = await restApiClient.fetch("DeleteFieldRegisterForm", params,  REDIRECT_TO_ERROR_PAGE);
        return response!;
        
    } catch (error) {
        console.error('Error editing field form:', error);
        return null;
    }
}




export async function postLandingPageCode(code: LandingPageCodeData) : Promise<ApiResponse | null>{
    console.warn("Calling to: postLandingPageCode", code);

    try{
        return {
            additionalMessages: [],
            code: 200,
            data: null,
            message: "",
            type: 200,
            typeText: "success"
        };

        const response = await restApiClient.fetch("PostLandingPageCode", {
            body:code
        },  REDIRECT_TO_ERROR_PAGE);
        return response!;

    }catch(error){
        console.error('Error post langing page code :', error);
        return null;
    }
}



export async function fetchLandingPageCode( language: string) : Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: fetchLandingPageCode");

        return {
            additionalMessages: [],
            code: 200,
            data: landingPageCodeMock,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                language: language
            }
        }

        const response = await restApiClient.fetch("GetLandingPageCode", params,  REDIRECT_TO_ERROR_PAGE);
        return response!;
        
    } catch (error) {
        console.error('Error fetching landing page code:', error);
        return null;
    }
}


export async function fetchGroupForm( language: string) : Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: fetchGroupForm");

        return {
            additionalMessages: [],
            code: 200,
            data: groupFormMock,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                language: language
            }
        }

        const response = await restApiClient.fetch("GetGroupForm", params,  REDIRECT_TO_ERROR_PAGE);
        return response!;
        
    } catch (error) {
        console.error('Error fetching landing page code:', error);
        return null;
    }
}




export async function postGroupForm(data: FormGroupDataRequest) : Promise<ApiResponse | null>{
    console.warn("Calling to: postGroupForm", data);

    try{
        return {
            additionalMessages: [],
            code: 200,
            data: null,
            message: "",
            type: 200,
            typeText: "success"
        };

        const response = await restApiClient.fetch("PostGroupForm", {
            body:data
        },  REDIRECT_TO_ERROR_PAGE);
        return response!;

    }catch(error){
        console.error('Error post group form :', error);
        return null;
    }
}



export async function fetchServicesChosen( language: string) : Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: fetchServicesChosen");

        return {
            additionalMessages: [],
            code: 200,
            data: language== "es" ? servicesMock : servicesMockEnglish,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                language: language
            }
        }

        const response = await restApiClient.fetch("GetServicesChosen", params,  REDIRECT_TO_ERROR_PAGE);
        return response!;
        
    } catch (error) {
        console.error('Error fetching services chosen:', error);
        return null;
    }
}


export async function fetchOptionsServices( language: string) : Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: fetchOptionsServices");

        return {
            additionalMessages: [],
            code: 200,
            data: language== "es" ? servicesMock : servicesMockEnglish,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                language: language
            }
        }

        const response = await restApiClient.fetch("GetOptionsServices", params,  REDIRECT_TO_ERROR_PAGE);
        return response!;
        
    } catch (error) {
        console.error('Error fetching options services:', error);
        return null;
    }
}




export async function fetchLanguagesAvailables() : Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: fetchLanguagesAvailables");

        return {
            additionalMessages: [],
            code: 200,
            data: languagesAvailableMock,
            message: "",
            type: 200,
            typeText: "success"
        };

  
        const response = await restApiClient.fetch("GetRoomConfigEditMapping", {},  REDIRECT_TO_ERROR_PAGE);
        return response!;
        
    } catch (error) {
        console.error('Error fetching room config mapping:', error);
        return null;
    }
}


// ------------ REGIMEN--------------------



export async function editRegimesConfig(data: RegimesConfigData) : Promise<ApiResponse | null>{
    console.warn("Calling to: editRoomConfig", data);

    try{
        return {
            additionalMessages: [],
            code: 200,
            data: null,
            message: "",
            type: 200,
            typeText: "success"
        };

        const response = await restApiClient.fetch("EditRegimesConfig", {
            body:data
        },  REDIRECT_TO_ERROR_PAGE);
        return response!;

    }catch(error){
        console.error('Error editing regimes config :', error);
        return null;
    }
}

export async function createRegimesConfig(data: RegimesConfigCreateData) : Promise<ApiResponse | null>{
    console.warn("Calling to: createRegimesConfig", data);

    try{
        return {
            additionalMessages: [],
            code: 200,
            data: null,
            message: "",
            type: 200,
            typeText: "success"
        };

        const response = await restApiClient.fetch("CreateRegimesConfig", {
            body:data
        },  REDIRECT_TO_ERROR_PAGE);
        return response!;

    }catch(error){
        console.error('Error creating regimes config :', error);
        return null;
    }
}


export async function deleteRegimesConfigMapping(id: string) : Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: deleteRegimesConfigMapping");

        return {
            additionalMessages: [],
            code: 200,
            data: null,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                id: id
            }
        }

        const response = await restApiClient.fetch("DeleteRegimesConfig", params,  REDIRECT_TO_ERROR_PAGE);
        return response!;
        
    } catch (error) {
        console.error('Error editing field form:', error);
        return null;
    }
}



export async function fetchRegimesConfigMapping( name : string) : Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: fetchRegimesConfigMapping");

        return {
            additionalMessages: [],
            code: 200,
            data: name == '' ? regimesConfigMock : regimesConfigSearchMock,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                name : name
            }
        }

        const response = await restApiClient.fetch("GetRegimesConfigMapping", params,  REDIRECT_TO_ERROR_PAGE);
        return response!;
        
    } catch (error) {
        console.error('Error fetching regimes config mapping:', error);
        return null;
    }
}

// ----------------ROOM----------------------

export async function fetchRoomConfigMapping(  name : string) : Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: fetchRoomConfigMapping");

        return {
            additionalMessages: [],
            code: 200,
            data: name == '' ? roomConfigMock : roomConfigSearchMock,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                name : name
            }
        }

        const response = await restApiClient.fetch("GetRoomConfigMapping", params,  REDIRECT_TO_ERROR_PAGE);
        return response!;
        
    } catch (error) {
        console.error('Error fetching room config mapping:', error);
        return null;
    }
}






export async function editRoomConfig(data: RoomConfigData) : Promise<ApiResponse | null>{
    console.warn("Calling to: editRoomConfig", data);

    try{
        return {
            additionalMessages: [],
            code: 200,
            data: null,
            message: "",
            type: 200,
            typeText: "success"
        };

        const response = await restApiClient.fetch("EditRoomConfig", {
            body:data
        },  REDIRECT_TO_ERROR_PAGE);
        return response!;

    }catch(error){
        console.error('Error editing room config :', error);
        return null;
    }
}

export async function createRoomConfig(data: RoomConfigCreateData) : Promise<ApiResponse | null>{
    console.warn("Calling to: createRoomConfig", data);

    try{
        return {
            additionalMessages: [],
            code: 200,
            data: null,
            message: "",
            type: 200,
            typeText: "success"
        };

        const response = await restApiClient.fetch("CreateRoomConfig", {
            body:data
        },  REDIRECT_TO_ERROR_PAGE);
        return response!;

    }catch(error){
        console.error('Error creating room config :', error);
        return null;
    }
}


export async function deleteRoomConfigMapping(id: string) : Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: deleteRoomConfigMapping");

        return {
            additionalMessages: [],
            code: 200,
            data: null,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                id: id
            }
        }

        const response = await restApiClient.fetch("DeleteRoomConfig", params,  REDIRECT_TO_ERROR_PAGE);
        return response!;
        
    } catch (error) {
        console.error('Error editing field form:', error);
        return null;
    }
}





// ------ HOTELS--------

export async function fetchHotelsConfigEditionMapping( id: string) : Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: fetchHotelsConfigEditionMapping");

        return {
            additionalMessages: [],
            code: 200,
            data: hotelsConfigEditMock,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                id : id
            }
        }

        const response = await restApiClient.fetch("GetHotelsConfigEditMapping", params,  REDIRECT_TO_ERROR_PAGE);
        return response!;
        
    } catch (error) {
        console.error('Error fetching hotels config mapping:', error);
        return null;
    }
}



export async function editHotelsConfig(data: HotelsConfigEditData) : Promise<ApiResponse | null>{
    console.warn("Calling to: editHotelsConfig", data);

    try{
        return {
            additionalMessages: [],
            code: 200,
            data: null,
            message: "",
            type: 200,
            typeText: "success"
        };

        const response = await restApiClient.fetch("EditHotelsConfig", {
            body:data
        },  REDIRECT_TO_ERROR_PAGE);
        return response!;

    }catch(error){
        console.error('Error editing hotels config :', error);
        return null;
    }
}

export async function createHotelsConfig(data: HotelsConfigCreateData) : Promise<ApiResponse | null>{
    console.warn("Calling to: createHotelsConfig", data);

    try{
        return {
            additionalMessages: [],
            code: 200,
            data: null,
            message: "",
            type: 200,
            typeText: "success"
        };

        const response = await restApiClient.fetch("CreateHotelsConfig", {
            body:data
        },  REDIRECT_TO_ERROR_PAGE);
        return response!;

    }catch(error){
        console.error('Error creating regimes config :', error);
        return null;
    }
}


export async function deleteHotelsConfigMapping(id: string) : Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: deleteHotelsConfigMapping");

        return {
            additionalMessages: [],
            code: 200,
            data: null,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                id: id
            }
        }

        const response = await restApiClient.fetch("DeleteHotelsConfig", params,  REDIRECT_TO_ERROR_PAGE);
        return response!;
        
    } catch (error) {
        console.error('Error editing hotel config form:', error);
        return null;
    }
}



export async function fetchHotelsConfigMapping( language: string, name : string) : Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: fetchHotelsConfigMapping");

        return {
            additionalMessages: [],
            code: 200,
            data: name == '' ? hotelsConfigMock : hotelsConfigSearchMock,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                language: language,
                name : name
            }
        }

        const response = await restApiClient.fetch("GetHotelsConfigMapping", params,  REDIRECT_TO_ERROR_PAGE);
        return response!;
        
    } catch (error) {
        console.error('Error fetching hotels config mapping:', error);
        return null;
    }
}


export async function editHotelsStatusConfig(data: HotelsConfigEditStatusData) : Promise<ApiResponse | null>{
    console.warn("Calling to: editHotelsStatusConfig", data);

    try{
        return {
            additionalMessages: [],
            code: 200,
            data: null,
            message: "",
            type: 200,
            typeText: "success"
        };

        const response = await restApiClient.fetch("EditHotelsStatusConfig", {
            body:data
        },  REDIRECT_TO_ERROR_PAGE);
        return response!;

    }catch(error){
        console.error('Error editing hotels status config :', error);
        return null;
    }
}



// si le paso que idHotel es nulo, es porque es nuevo
export async function fetchHotelsRooms( language: string, idHotel : string) : Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: fetchHotelsRooms");

        return {
            additionalMessages: [],
            code: 200,
            data: idHotel == '' ? newHotelRoomMock :  hotelRoomMock,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                idHotel: idHotel,
                language: language,
            }
        }

        const response = await restApiClient.fetch("GetHotelsRooms", params,  REDIRECT_TO_ERROR_PAGE);
        return response!;
        
    } catch (error) {
        console.error('Error fetching hotels config mapping:', error);
        return null;
    }
}



// si le paso que idHotel es nulo, es porque es nuevo
export async function fetchHotelsRegimens( language: string, idHotel : string) : Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: fetchHotelsRegimens");

        return {
            additionalMessages: [],
            code: 200,
            data: idHotel == '' ? newHotelRegimenMock :  hotelRegimenMock,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                idHotel: idHotel,
                language: language,
            }
        }

        const response = await restApiClient.fetch("GetHotelsRegimens", params,  REDIRECT_TO_ERROR_PAGE);
        return response!;
        
    } catch (error) {
        console.error('Error fetching hotels config mapping:', error);
        return null;
    }
}


// si name tiene un valor, entonces es para filtar
export async function fetchServicesConfigMapping(  name : string) : Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: fetchServicesConfigMapping");

        return {
            additionalMessages: [],
            code: 200,
            data: name == '' ? serviceConfigMock : serviceConfigSearchMock,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                name : name
            }
        }

        const response = await restApiClient.fetch("GetServicesConfigMapping", params,  REDIRECT_TO_ERROR_PAGE);
        return response!;
        
    } catch (error) {
        console.error('Error fetching services config mapping:', error);
        return null;
    }
}



export async function deleteServiceConfigMapping(id: string) : Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: deleteServiceConfigMapping");

        return {
            additionalMessages: [],
            code: 200,
            data: null,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                id: id
            }
        }

        const response = await restApiClient.fetch("DeleteServiceConfig", params,  REDIRECT_TO_ERROR_PAGE);
        return response!;
        
    } catch (error) {
        console.error('Error editing service config :', error);
        return null;
    }
}



export async function createServicesConfig(data: ServicesConfigCreateData) : Promise<ApiResponse | null>{
    console.warn("Calling to: createServicesConfig", data);

    try{
        return {
            additionalMessages: [],
            code: 200,
            data: null,
            message: "",
            type: 200,
            typeText: "success"
        };

        const response = await restApiClient.fetch("CreateServicesConfig", {
            body:data
        },  REDIRECT_TO_ERROR_PAGE);
        return response!;

    }catch(error){
        console.error('Error creating services config :', error);
        return null;
    }
}



export async function editServicesConfig(data: ServicesConfigData) : Promise<ApiResponse | null>{
    console.warn("Calling to: editServicesConfig", data);

    try{
        return {
            additionalMessages: [],
            code: 200,
            data: null,
            message: "",
            type: 200,
            typeText: "success"
        };

        const response = await restApiClient.fetch("EditServicesConfig", {
            body:data
        },  REDIRECT_TO_ERROR_PAGE);
        return response!;

    }catch(error){
        console.error('Error editing services config :', error);
        return null;
    }
}



export async function useDoLogin(username: string, password: string) : Promise<ApiResponse | null>{
    try {
        console.warn("Calling to: useDoLogin", username, password);

        let result: ApiResponse = {
            additionalMessages: [],
            code: 200,
            data: {
                sessionId: "dfecb97e-e240-4747-b327-d30ff4da0f63",
                userData:{
                    name: "John",
                    surname: "Doe",
                    secondSurname: "Doe",
                    UAID:"123-ABC-XXX",
                    email: "test@beds2b.com",
                    avatar: "", //"https://www.shareicon.net/data/256x256/2016/09/15/829453_user_512x512.png",
                    phone: "123456789",
                    username: "TestUsername"
                },
                isLogged: true
            } as UserSessionData,
            message: "",
            type: 200,
            typeText: "success"
        }

        if ((username !== 'Sergio') || (password !== 'Sergio123')){
            result = {
                additionalMessages: [],
                code: 200,
                data: {
                    sessionId: "dfecb97e-e240-4747-b327-d30ff4da0f63",
                    userData:{
                        name: "John",
                        surname: "Doe",
                        secondSurname: "Doe",
                        UAID:"123-ABC-XXX",
                        email: "test@beds2b.com",
                        avatar: "", //"https://www.shareicon.net/data/256x256/2016/09/15/829453_user_512x512.png",
                        phone: "123456789",
                        username: "TestUsername"
                    },
                    isLogged: true
                } as UserSessionData,
                message: "",
                type: 404,
                typeText: "error"
            };
        }

        return result;

        var params : ParamsApi = {
            body:{
                userName: username,
                password: password
            }
        }

        const response = await restApiClient.fetch("DoLogin", params,  REDIRECT_TO_ERROR_PAGE);
        return response!;
        
    } catch (error) {
        console.error('Error fetching colors:', error);
        return null;
    }
}

export async function useCloseSession(sessionToken: string) : Promise<ApiResponse | null>{
    try {
        console.warn("Calling to: useCloseSession", sessionToken);

        return {
            additionalMessages: [],
            code: 200,
            data: true,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                token: sessionToken
            }
        }

        const response = await restApiClient.fetch("DoLogout", params,  REDIRECT_TO_ERROR_PAGE);
        return response!;
        
    } catch (error) {
        console.error('Error fetching colors:', error);
        return null;
    }
}

export async function useRecoverPassword(email: string) : Promise<ApiResponse | null>{
    try {
        console.warn("Calling to: useRecoverPassword", email);

        return {
            additionalMessages: [],
            code: 200,
            data: true,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                email: email
            }
        }

        const response = await restApiClient.fetch("DoRecoverPassword", params,  REDIRECT_TO_ERROR_PAGE);
        return response!;
        
    } catch (error) {
        console.error('Error fetching colors:', error);
        return null;
    }
}

export async function fetchTemplateInfo() : Promise<ApiResponse | null>{
    console.warn("Calling to: getTemplateInfo");
    try{
        return {
            additionalMessages: [],
            code: 200,
            data: templateInfo,
            message: "",
            type: 200,
            typeText: "success"
        };

        const response = await restApiClient.fetch("getClientTemplate", {},  REDIRECT_TO_ERROR_PAGE);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}


export async function isValidUsername(username : string) : Promise<ApiResponse | null>{
    console.warn("Calling to: checkUsername");
    try{
        return {
            additionalMessages: [],
            code: 200,
            data: false,
            message: "",
            type: 200,
            typeText: "success"
        };

        const response = await restApiClient.fetch("isValidUsername", {},  REDIRECT_TO_ERROR_PAGE);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}


export async function fetchAccounts(filterData: AccountFilter) : Promise<ApiResponse | null>{
    console.warn("Calling to: fetchAccounts", filterData);

    await sleep(1000);

    try{
        return {
            additionalMessages: [],
            code: 200,
            data: {
                list: accountDataList.slice(filterData.page, filterData.pageSize),
                totalElements: accountDataList.length
            },
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                filter: filterData
            }
        }

        const response = await restApiClient.fetch("getAccounts", params,  REDIRECT_TO_ERROR_PAGE);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function fetchBookings(filterData: BookingFilter) : Promise<ApiResponse | null>{
    console.warn("Calling to: fetchBookings", filterData);
    await sleep(1000);

    try{
        return {
            additionalMessages: [],
            code: 200,
            data: {
                list: exampleBookings.slice(filterData.page, filterData.pageSize),
                totalElements: exampleBookings.length
            },
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                filter: filterData
            }
        }

        const response = await restApiClient.fetch("getBookings", params,  REDIRECT_TO_ERROR_PAGE);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function fetchHotels() : Promise<ApiResponse | null>{
    console.warn("Calling to: fetchHotels");
    await sleep(1000);
    try{
        return {
            additionalMessages: [],
            code: 200,
            data: hotelsDemo,
            message: "",
            type: 200,
            typeText: "success"
        };


        const response = await restApiClient.fetch("getHotels", {},  REDIRECT_TO_ERROR_PAGE);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function fetchBookingData(bookingId: string) : Promise<ApiResponse | null>{
    console.warn("Calling to: fetchBookingData");
    await sleep(1000);
    try{
        return {
            additionalMessages: [],
            code: 200,
            data: bookingDataExample,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                id: bookingId
            }
        }

        const response = await restApiClient.fetch("fetchBookingData", params,  REDIRECT_TO_ERROR_PAGE);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function fetchAgencies(agencyName: string) : Promise<ApiResponse | null>{
    console.warn("Calling to: fetchAgencies");
    await sleep(1000);
    try{
        return {
            additionalMessages: [],
            code: 200,
            data: agenciesList,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                name: agencyName
            }
        }

        const response = await restApiClient.fetch("fetchAgencies", params,  REDIRECT_TO_ERROR_PAGE);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function fetchCompanies(companyName: string) : Promise<ApiResponse | null>{
    console.warn("Calling to: fetchCompanies");
    await sleep(1000);
    try{
        return {
            additionalMessages: [],
            code: 200,
            data: companiesClientList,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                name: companyName
            }
        }

        const response = await restApiClient.fetch("fetchCompanies", params,  REDIRECT_TO_ERROR_PAGE);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function fetchAccountUsers(accountId: string) : Promise<ApiResponse | null>{
    console.warn("Calling to: fetchAccountUsers");
    await sleep(1000);
    try{
        return {
            additionalMessages: [],
            code: 200,
            data: accountUsersExample,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            query:{
                id: accountId
            }
        }

        const response = await restApiClient.fetch("fetchAccountUsers", params,  REDIRECT_TO_ERROR_PAGE);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}

export async function changeAccountUserStatus(userId: string, isActive: boolean) : Promise<ApiResponse | null>{
    console.warn("Calling to: changeAccountUserStatus");
    await sleep(1000);
    try{
        return {
            additionalMessages: [],
            code: 200,
            data: true,
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                id: userId,
                isActive: isActive
            }
        }

        const response = await restApiClient.fetch("changeAccountUserStatus", params,  REDIRECT_TO_ERROR_PAGE);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}




export async function createUser(data: NewUserData) : Promise<ApiResponse | null>{
    try {
        console.warn("Calling to: createUser", data);

        return {
            additionalMessages: [],
            code: 200,
            data: {"id": "123456"},
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                data: data
            }
        }

        const response = await restApiClient.fetch("createUser", params,  REDIRECT_TO_ERROR_PAGE);
        return response!;
        
    } catch (error) {
        console.error('Error fetching colors:', error);
        return null;
    }
}





export async function editUser(data: EditUserData) : Promise<ApiResponse | null>{
    try {
        console.warn("Calling to: editUser", data);

        return {
            additionalMessages: [],
            code: 200,
            data: {"id": "123456"},
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                data: data
            }
        }

        const response = await restApiClient.fetch("createUser", params,  REDIRECT_TO_ERROR_PAGE);
        return response!;
        
    } catch (error) {
        console.error('Error fetching colors:', error);
        return null;
    }
}



export async function fetchUsers(filterData: UsersFilterData) : Promise<ApiResponse | null>{
    console.warn("Calling to: fetchUsers", filterData);

    await sleep(1000);

    try{
        return {
            additionalMessages: [],
            code: 200,
            data: {
                list: userDataList.slice(filterData.page, filterData.pageSize),
                totalElements: userDataList.length
            },
            message: "",
            type: 200,
            typeText: "success"
        };

        var params : ParamsApi = {
            body:{
                filter: filterData
            }
        }

        const response = await restApiClient.fetch("getUsers", params,  REDIRECT_TO_ERROR_PAGE);
        
        return response;

    }catch(error){
        console.error('Error push notification data:', error);
        return null;
    }
}


export async function fetchUserDataEdition(id: string) : Promise<ApiResponse | null> {
    try {
        console.warn("Calling to: fetchUserDataEdition");

        return {
            additionalMessages: [],
            code: 200,
            data: userEditMock,
            message: "",
            type: 200,
            typeText: "success"
        };

        const response = await restApiClient.fetch("GetUserDataEdition", {},  REDIRECT_TO_ERROR_PAGE);
        return response!;
        
    } catch (error) {
        console.error('Error fetching colors:', error);
        return null;
    }


}


