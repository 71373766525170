
import { useTranslation } from 'react-i18next';
import { BookingsFilter, BookingsList, CustomPagination } from '../../../components';
import './BookingsPage.scss';
import { fetchBookingData, fetchBookings, fetchHotels } from '../../../api/repositoryEP';
import { BookingFilter } from '../../../models/BookingsFilter';
import { useEffect, useState } from 'react';
import BookingInfo from '../../../models/BookingInfo';
import HotelInfo from '../../../models/HotelInfo';
import LoadingPage from '../../Commons/LoadingPage/LoadingPage';
import { LoadingOutlined } from '@ant-design/icons';
import BookingData from '../../../models/BookingData';

const BookingsPage = () : JSX.Element => {
    const { t } = useTranslation();

    const [totalElements, setTotalElements] = useState<number>(0);
    const [bookingList, setBookingList] = useState<BookingInfo[]>([]);
    const [searching, setSearching] = useState<boolean>(false);
    const getBookings = (filterData: BookingFilter = {
        locator: '',
        status: '',
        agency: '',
        checkIn: '',
        checkOut: '',
        location: '',
        name: '',
        hotel: '',
        ownLocator: '',
        userEmail: '',
        page: 0,
        pageSize: 5
    }) => {
        setSearching(true);
        fetchBookings(filterData).then((r)=>{
            if (r?.typeText === "success" && r?.data){
                setBookingList(r?.data?.list as BookingInfo[]);
                setTotalElements(r?.data?.totalElements as number);
            }else{
                setBookingList([]);
                setTotalElements(0);
            }
        }).finally(()=>{
            setSearching(false);
        })
    }

    const [hotels, setHotels] = useState<HotelInfo[]>([]);
    const getHotels = () => {
        fetchHotels().then((r)=>{
            if (r?.typeText === "success" && r?.data){
                setHotels(r?.data as HotelInfo[]);
            }else{
                setHotels([]);
            }
        })
    }

    const [bookingData, setBookingData] = useState<BookingData>();
    const getBookingData = (bookingId: string) => {
        console.log("Get booking data", bookingId);
        fetchBookingData(bookingId).then((r)=>{
            if (r?.typeText === "success" && r?.data){
                console.log(r?.data);
                setBookingData(r?.data as BookingData);
            }else{
                setBookingData(undefined);
            }
        })
    }

    useEffect(()=>{
        getBookings();
        getHotels();
    },[]);

    return(
        <div className="bookings-page">
            <h3>
                {t('pages.bookings.title')}
                <small>
                    {t('pages.bookings.subtitle')}
                </small>
            </h3>
            <div className="filter-container">
                <BookingsFilter searching={searching} onSearch={getBookings} hotelList={hotels}/>
            </div>
            <div className="list-container">
                <BookingsList loading={searching} list={bookingList} onGetBookingData={getBookingData} bookingData={bookingData}/>
            </div>
            <div className="pagination-container">
                <CustomPagination totalElements={totalElements}/>
            </div>
        </div>
    )
}

export default BookingsPage;