import { useTranslation } from "react-i18next";

import './DashboardPage.scss'
import { Button } from "antd";
import HTMLReactParser from "html-react-parser";
import { useNavigate } from "react-router-dom";
import { GetFormattedUrl } from "../../../utils/urls";
import { GetBookingsIcon, GetGroupIcon, GetHandIcon, GetRewardsIcon,GetConfigIcon } from "../../../utils/icons";

const DashboardgPage = () : JSX.Element => {
    const { t } = useTranslation();
    const navigate = useNavigate();


    const goAccounts = () => {
        navigate(GetFormattedUrl('private/accounts-admin'));
    }

    const goBookings = () => {
        navigate(GetFormattedUrl('private/bookings-admin'));
    }

    const goPayments = () => {
        navigate(GetFormattedUrl('private/payments-config'));
    }

    const goRewardsForm = () => {
        const url : string = 'https://www.beds2brewards.com/solicite-una-demo/';
        window.open(url, '_blank');
    }

    const goConfiguration = () => {
        navigate(GetFormattedUrl('private/config'));
    }




    return(
        <div className="dashboard-page">
            <h2 className="title">
                {HTMLReactParser(t('pages.dashboard.title'))}
            </h2>
            <p className="sub-title">
                {HTMLReactParser(t('pages.dashboard.welcome-message'))}
            </p>
            <ul className="benefits">
                <li className="benefit-item">
                    {GetBookingsIcon("app-icon width-24px")}
                    <div>
                        <h3>{t('pages.dashboard.list-item-1')}</h3>
                        <p>{t('pages.dashboard.list-item-1-description')}</p>
                        <Button className="app-button" onClick={goAccounts}>
                            {t('pages.dashboard.list-item-1-button')}
                        </Button>
                    </div>
                </li>
                <li className="benefit-item">
                    {GetGroupIcon("app-icon width-24px")}
                    <div>
                        <h3>{t('pages.dashboard.list-item-2')}</h3>
                        <p>{t('pages.dashboard.list-item-2-description')}</p>
                        <Button className="app-button" onClick={goBookings}>
                            {t('pages.dashboard.list-item-2-button')}
                        </Button>
                    </div>
                </li>
                <li className="benefit-item">
                    {GetHandIcon("app-icon width-24px")}
                    <div>
                        <h3>{t('pages.dashboard.list-item-3')}</h3>
                        <p>{t('pages.dashboard.list-item-3-description')}</p>
                        <Button className="app-button" onClick={goPayments}>
                            {t('pages.dashboard.list-item-3-button')}
                        </Button>
                    </div>
                </li>
                <li className="benefit-item">
                    {GetRewardsIcon("app-icon width-24px")}
                    <div>
                        <h3>{HTMLReactParser(t('pages.dashboard.list-item-4'))}</h3>
                        <ul className="contract-benefit">
                            <li>{HTMLReactParser(t('pages.dashboard.list-item-4-description-1'))}</li>
                            <li>{HTMLReactParser(t('pages.dashboard.list-item-4-description-2'))}</li>
                            <li>{HTMLReactParser(t('pages.dashboard.list-item-4-description-3'))}</li>
                        </ul>
                        <Button className="app-button large" onClick={goRewardsForm}>
                            {HTMLReactParser(t('pages.dashboard.list-item-4-button'))}
                        </Button>
                        <Button className="app-button">
                            {t('pages.dashboard.list-item-4-button-2')}
                        </Button>
                    </div>
                </li>
                <li className="benefit-item">
                    {GetConfigIcon("app-icon width-24px")}
                    <div>
                        <h3>{t('pages.dashboard.list-item-5')}</h3>
                        <p>{t('pages.dashboard.list-item-5-description')}</p>
                        <Button className="app-button" onClick={goConfiguration}>
                            {t('pages.dashboard.list-item-5-button')}
                        </Button>
                    </div>
                </li>
                
            </ul>
        </div>
    )
}

export default DashboardgPage;