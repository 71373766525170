

import { useTranslation } from 'react-i18next';
import { Button, Col, Dropdown, Form, Input, Row, Select } from 'antd';
import { FileExcelOutlined, LoadingOutlined } from '@ant-design/icons';

import './BookingsFilterComponent.scss'
import { BookingFilter } from '../../../models/BookingsFilter';
import { useState } from 'react';
import { GetArrowSelectIcon } from '../../../utils/icons';
import HotelInfo from '../../../models/HotelInfo';

interface BookingsFilterComponentProps {
    onSearch: (filterData: BookingFilter) => void;
    hotelList: Array<HotelInfo>;
    searching: boolean;
}

const BookingsFilterComponent = (props: BookingsFilterComponentProps) : JSX.Element => {

    const { onSearch, hotelList, searching } = props;

    const { t } = useTranslation();
    const [form] = Form.useForm();

    const [showAdvancedSearch, setShowAdvancedSearch] = useState<boolean>(false);

    const items = [
        {
          key: '1',
          label: t("components.bookings.filter.last-week"),
        },
        {
          key: '2',
          label: t("components.bookings.filter.last-month"),
        },
        {
          key: '3',
          label: t("components.bookings.filter.last-year"),
        },
        {
          key: '4',
          label: t("components.bookings.filter.status-confirmed"),
        },
        {
          key: '5',
          label: t("components.bookings.filter.status-cancelled"),
        },
      ];


    const onFinish = (values: any) => {
        onSearch({
            locator: values.locator,
            status: values.status,
            agency: values.agency,
            checkIn: values.checkin,
            checkOut: values.checkout,
            location: values.location,
            name: values.name,
            hotel: values.hotels,
            ownLocator: values.ownLocator,
            userEmail: values.userEmail,
            page: 0,
            pageSize: 5,
        });
    }
    


    const onMenuClick = ({ key }: any) => {
        console.log(`Click on item ${key}`);
    };

    return(
        <div className="bookings-filter">
            <div className="export-data-content">
                <Dropdown.Button menu={{ items, onClick: onMenuClick }} className="export-button-dropdown">
                    <FileExcelOutlined className="excel-button"/> {t("components.bookings.filter.export-data")}
                </Dropdown.Button> 
            </div>
            <Form form={form} layout='vertical' onFinish={onFinish}>
              <Row gutter={16}>
                    <Col xs={24} md={8}>
                        <Form.Item name="locator" label={t("components.bookings.filter.locator")} initialValue={''}>
                            <Input className="app-input"/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item name="status" label={t("components.bookings.filter.status")} initialValue={''}>
                            <Input className="app-input"/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item name="agency" label={t("components.bookings.filter.agency")} initialValue={''}>
                            <Input className="app-input"/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item name="checkin" label={t("components.bookings.filter.check-in")} initialValue={''}>
                            <Input className="app-input"/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item name="checkout" label={t("components.bookings.filter.check-out")} initialValue={''}>
                            <Input className="app-input"/>
                        </Form.Item>
                    </Col>
                    
                    <Col xs={24} md={8}>
                        <Form.Item name="location" label={t("components.bookings.filter.location")} initialValue={''}>
                            <Input className="app-input"/>
                        </Form.Item>
                    </Col>

                    <Col xs={24} className="advanced-search">
                        <a className='app-label' onClick={()=>{setShowAdvancedSearch(!showAdvancedSearch)}}>
                          {t("components.bookings.filter.advanced-search")} { GetArrowSelectIcon(`app-icon ${(showAdvancedSearch ? 'inverted' : '')}`) }
                        </a>
                    </Col>

                    <Col xs={24} md={8} style={{display: showAdvancedSearch ? 'inline' : 'none', visibility: showAdvancedSearch ? 'visible' : 'hidden'}}>
                        <Form.Item name="name" label={t("components.bookings.filter.name")} initialValue={''}>
                            <Input className="app-input"/>
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={8} style={{display: showAdvancedSearch ? 'inline' : 'none', visibility: showAdvancedSearch ? 'visible' : 'hidden'}}>
                        <Form.Item name="hotels" label={t("components.bookings.filter.hotels")} initialValue={''}>
                          <Select className="app-input" defaultValue={""}>
                              <Select.Option value="">{t("forms.selects.select-else")}</Select.Option>
                              {
                                hotelList.map((item: HotelInfo, index)=>{
                                  return <Select.Option key={index} value={item.id}>{item.name}</Select.Option>
                                })
                              }
                          </Select>
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={8} style={{display: showAdvancedSearch ? 'inline' : 'none', visibility: showAdvancedSearch ? 'visible' : 'hidden'}}>
                        <Form.Item name="ownLocator" label={t("components.bookings.filter.own-locator")} initialValue={''}>
                            <Input className="app-input"/>
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={8} style={{display: showAdvancedSearch ? 'inline' : 'none', visibility: showAdvancedSearch ? 'visible' : 'hidden'}}>
                        <Form.Item name="userEmail" label={t("components.bookings.filter.email")} initialValue={''}>
                            <Input className="app-input"/>
                        </Form.Item>
                    </Col>
                    
                    <Col xs={24}>
                      <Col xs={{span: 22, offset: 1}} md={{span: 8, offset: 8}}>
                        
                          <Button disabled={searching} className="app-button" htmlType='submit'>
                              {
                              searching ? <LoadingOutlined /> : ''
                            }
                            {t("forms.buttons.search")}
                          </Button>
                      </Col>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default BookingsFilterComponent;