
import './PrivateFooterComponent.scss'

interface PrivateFooterComponentProps {}

const PrivateFooterComponent = (props: PrivateFooterComponentProps) : JSX.Element => {
    return (
        <div className="private-footer-component">
            Private Footer Component
        </div>
    )
}

export default PrivateFooterComponent;