export function hexToRgb(hex: any) {
    // Elimina el signo '#' si está presente
    hex = hex.replace(/^#/, '');
  
    // Divide el valor hexadecimal en componentes RGB
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);
  
    return `${r}, ${g}, ${b}`;
  }


  interface PlaceProps {
    place: string
    hotelsNumber: number
  }
  export function Place({ hotelsNumber, place }: PlaceProps) {
    return (
      <p className="destinationPlace">
        <span>{place}</span>
        <span>{hotelsNumber} hoteles</span>
      </p>
    )
  }