import { useTranslation } from "react-i18next";
import { useState } from 'react';
import { Button, Col, Dropdown, Form, Input, Row, Select, Drawer } from "antd";
import "./UsersFilterComponent.scss";
import { FileExcelOutlined, SearchOutlined } from "@ant-design/icons";

import NewUserForm from "../../NewUserForm/NewUserForm";
import { PlusOutlined } from '@ant-design/icons';

interface UsersFilterComponentProps {
  onSearch: (searchText: string) => void;
  searching: boolean;
}

const UsersFilterComponent = (props: UsersFilterComponentProps): JSX.Element => {
  const { t } = useTranslation();

  const { searching } = props;

  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    props.onSearch(values.searchText);
  };

  const createUser = () => {
    console.log("Create user");
    setDrawerVisible(true);
  }
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);

  const items = [
    {
      key: '1',
      label: t("components.account-users.filter.active-users"), // usuarios activos
    },
    {
      key: '2',
      label: t("components.account-users.filter.block-users"), // usuarios bloqueados
    },
    {
      key: '3',
      label: t("components.account-users.filter.admin-users"), // administradores
    }
  ];

  const onMenuClick = ({ key }: any) => {
    console.log(`Click on item ${key}`);
  };
  const screenWidth = window.innerWidth;
  const drawerWidth = screenWidth <= 768 ? '100%' : '30%';

  return (
   
    <div className="account-users-filter">
      <Drawer open={drawerVisible} title={<span>{t("components.new-users.title")}</span>} onClose={() => { setDrawerVisible(false) }}  width={drawerWidth} closable>
        <NewUserForm setDrawerVisible={setDrawerVisible} />
      </Drawer>
      <div className="export-data-content">
        <Dropdown.Button menu={{ items, onClick: onMenuClick }} className="export-button-dropdown">
          <FileExcelOutlined className="excel-button" /> {t("components.accounts.filter.export-data")}
        </Dropdown.Button>
      </div>

      <Form form={form} onFinish={onFinish} layout="vertical">
        <Row gutter={16}>
          <Col xs={24} md={8}>
            <Form.Item
              name="name"
              label={t("components.account-users.filter.name-and-surname")}
            >
              <Input className="app-input" />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name="email"
              label={t("components.account-users.filter.email")}
            >
              <Input className="app-input" />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name="status"
              label={t("components.account-users.filter.username")}
            >
              <Input className="app-input" />
            </Form.Item>
          </Col>
          {/* <Col xs={24} md={8}>
            <Form.Item
              name="agency"
              label={t("components.account-users.filter.agency")} >
              <Input className="app-input" />
            </Form.Item>
        </Col> */}

          <Col xs={24} md={8}>
            <Form.Item name="status" label={t("components.accounts.filter.status")} initialValue={''}>
              <Select className="app-input" defaultValue={""}>
                <Select.Option value="">{t("forms.selects.select-else")}</Select.Option>
                <Select.Option value="active">{t("components.accounts.filter.status-active")}</Select.Option>
                <Select.Option value="inactive">{t("components.accounts.filter.status-inactive")}</Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} md={8}>
            <Form.Item name="role" label={t("components.account-users.filter.role")} initialValue={''}>
              <Select className="app-input" defaultValue={""}>
                <Select.Option value="">{t("forms.selects.select-else")}</Select.Option>
                <Select.Option value="admin">{t("roles.admin")}</Select.Option>
                <Select.Option value="user">{t("roles.user")}</Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col xs={24} md={8}>
            <Form.Item>
              <Button className="app-button" style={{ width: "100%" }} htmlType="submit" loading={searching}>
                {t("components.account-users.filter.search")}
              </Button>
            </Form.Item>
          </Col>

          <Col xs={24} md={16}>
          </Col>

          <Col xs={24} md={8}>
            <Form.Item style= {{textAlign:"center"}}>
              <Button className="app-button" style={{ width: "100%" }} icon={<PlusOutlined />} onClick={createUser}>
                {t("components.account-users.filter.new-user")}
              </Button>
            </Form.Item>
          </Col>
        </Row>

      </Form>
    </div>
  );
};

export default UsersFilterComponent;
