import { ServiceData } from "../models/ConfigurationData";

export const servicesMock: Array<ServiceData>= 
  [{
    id :"0",
    name : "Servicio 0"
  },
  {
    id: "1",
    name : "Servicio 1"
  },
  {
    id: "2",
    name : "Servicio 3"
  },
  {
    id: "4",
    name : "Servicio 4"
  }
  ]



  export const servicesMockEnglish: Array<ServiceData>= 
  [{
    id :"0",
    name : "Service 0"
  },
  {
    id: "1",
    name : "Service 1"
  },
  {
    id: "2",
    name : "Service 2"
  },
  {
    id: "4",
    name : "Service 3"
  }
  ]
