import { useTranslation } from "react-i18next";
import { Select } from "antd";
import { availableAppLanguages } from "../../../../Contexts/AppConfigContext";
import { GetDefaultLanguage } from "../../../../utils/urls";
import { Row, Col } from "antd";
import { useEffect, useState } from "react";
import { fetchLandingPageCode, postLandingPageCode } from "../../../../api/repositoryEP";
import { notification } from 'antd';
import { Button } from "antd";
import CodeEditor from "../../../codeEditor/CodeEditor";
import { LandingPageCodeData } from "../../../../models/ConfigurationData";
import './LandingPage.scss';

const LandingPage = (): JSX.Element => {
    const { t } = useTranslation();
    const languages = availableAppLanguages;
    const [language, setLanguage] = useState(GetDefaultLanguage());
    const [cssCode, setCssCode] = useState("");
    const [htmlCode, setHtmlCode] = useState("");
    const [jsCode, setJsCode] = useState("");


    const handleLanguage = (language: any) => {
        {
            setLanguage(language);
        }
    }

    useEffect(() => {
        if(language ){
            fetchLandingPageCode(language).then((r) => {
                const code : LandingPageCodeData = r?.data;
                if(r?.data){
                    setCssCode(code.cssCode);
                    setHtmlCode(code.htmlCode);
                    setJsCode(code.jsCode);
                }
            })

        }
    }, [language])

    const handleCode = () => {
        const data: LandingPageCodeData = {
            language: language,
            cssCode: cssCode,
            htmlCode: htmlCode,
            jsCode: jsCode
        };
        

        postLandingPageCode(data).then((r) => {
            if (r?.code == 200) {
                notification.success({
                    message: t('actions.success-message'),
                    description: t('actions.success-description')
                });
            } else {
                notification.error({
                    message: t('actions.error-message'),
                    description: t('actions.error-description')
                });
            }

            const scrollTopElement = document.getElementById("content-page")!;
            scrollTopElement.scrollTop = 0;
        })
    }

    return (
        <div className="landing-page-component">
            <h2>
                {t("components.tab-configuration.landing-page.title")}
            </h2>
            <Row gutter={4} style={{ flexDirection: 'column' }}>
                <Col xs={24} sm={24} md={8} lg={6} xl={4} >
                    <Select className="select-large" defaultValue={language} onChange={handleLanguage}>
                        {languages.map(language => (
                            <Select.Option key={language} value={language}>
                                {t(`languages.${language}`)}
                            </Select.Option>
                        ))}
                    </Select>
                </Col>

                <Col xs={24} md={24}>
                    <CodeEditor
                        htmlCode={htmlCode}
                        cssCode={cssCode}
                        jsCode={jsCode}
                        setHtmlCode={setHtmlCode}
                        setCssCode={setCssCode}
                        setJsCode={setJsCode}
                    />
                </Col>


                <Col xs={24} md={24}>
                    <Button className="app-button full-width margin-button" onClick={handleCode} >
                        {t("components.tab-configuration.landing-page.button-save")}
                    </Button>
                </Col>
            </Row>
        </div>

    );
};



export default LandingPage;