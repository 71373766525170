import PublicFooterComponent from "./PublicFooter/PublicFooterComponent";
import PublicHeaderComponent from "./PublicHeader/PublicHeaderComponent";
import LanguageSelectorComponent from './LanguageSelector/LanguageSelectorComponent'
import LanguageSelectorInLineComponent from './LanguageSelector/LanguageSelectorInLineComponent'

import PrivateFooterComponent from "./PrivateFooter/PrivateFooterComponent";
import PrivateHeaderComponent from "./PrivateHeader/PrivateHeaderComponent";
import PrivateMenuComponent from "./PrivateMenu/PrivateMenuComponent";
import InputLabeledComponent from './InputLabeled/InputLabeledComponent'

import DotsActionsComponent from './DotsActions/DotsActionsComponent'

export const PublicFooter = PublicFooterComponent;
export const PublicHeader = PublicHeaderComponent;
export const LanguageSelector = LanguageSelectorComponent;
export const LanguageSelectorInLine = LanguageSelectorInLineComponent;
export const PrivateFooter = PrivateFooterComponent;
export const PrivateHeader = PrivateHeaderComponent;
export const PrivateMenu = PrivateMenuComponent;
export const InputLabeled = InputLabeledComponent;
export const DotsActions = DotsActionsComponent;