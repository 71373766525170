import { createRoomConfig, editRoomConfig, fetchLanguagesAvailables } from "../../../../../../../api/repositoryEP";
import RoomConfigData, { RoomConfigCreateData, TranslationData } from "../../../../../../../models/MappingData";
import { useEffect, useState } from "react";
import { Form, Row, Col, Button } from "antd";
import { Input, Checkbox } from "antd";
import { notification } from 'antd';
import { useTranslation } from "react-i18next";
import './RoomForm.scss'


interface RoomFormProps {
    drawerVisible: boolean;
    setDrawerVisible: (visible: boolean) => void;
    roomData: RoomConfigData | null;
    setRoomData: (roomData: RoomConfigData | null) => void;
}


const RoomForm = (props: RoomFormProps): JSX.Element => {
    const [roomNames, setRoomNames] = useState<Array<TranslationData>>([])
    const { t } = useTranslation();
    const [form] = Form.useForm();


    const showErrorMessage = () => {
        notification.error({
            message: t('actions.error-message'),
            description: t('actions.error-description')
        });
    }
    const showSuccessNotification = () => {
        notification.success({
            message: t('actions.success-message'),
            description: t('actions.success-description')
        });
    }


    const closeDrawer = () => {
        props.setRoomData(null)
        form.resetFields();
        props.setDrawerVisible(false)
    }




    const modifyRoomName = (index: number, value: string) => {
        const newRoomNames = [...roomNames];
        newRoomNames[index].value = value;
        setRoomNames(newRoomNames);
    }



    const handleBtnCancel = () => {
        closeDrawer();
    }





    useEffect(() => {

        if (props.drawerVisible) {

            if (props.roomData) {
                setRoomNames(props.roomData.name);
            } else {
                fetchLanguagesAvailables().then((r) => {
                    if (r?.code === 200) {
                        const languages = r.data.map((lang: string) => {
                            return { language: lang, value: '' }
                        })
                        setRoomNames(languages)
                    } else {
                        showErrorMessage();
                        props.setDrawerVisible(false)
                    }
                })

            }
        }
    }, [props.drawerVisible]);




    const onFinish = (values: any) => {
        if (props.roomData === null) { // CREATE
            const roomData: RoomConfigCreateData = {
                name: roomNames,
                code: values.code,
                internNote: values.internalNote,
                syncReward: values.sync
            }

            createRoomConfig(roomData).then((response) => {
                if (response?.code === 200) {
                    showSuccessNotification()
                    closeDrawer();
                } else {
                    showErrorMessage();
                }
            })

        } else { // EDIT
            const roomData: RoomConfigData = {
                id: props.roomData?.id,
                name: roomNames,
                code: values.code,
                internNote: values.internalNote,
                syncReward: values.sync
            }

            editRoomConfig(roomData).then((response) => {
                if (response?.code === 200) {
                    showSuccessNotification()
                    closeDrawer();
                } else {
                    showErrorMessage();
                }
            })
        }

    }


    return (
        <div   className='room-form-component'>
                <Form form={form}
                    onFinish={onFinish}
                    layout="vertical"
                >
                    <Row gutter={16}>
                        <Col xs={24} md={24}>
                            <Form.Item
                                name="name"
                                label={t("components.tab-mapping.room-config.drawer.name")}
                                required
                                rules={[
                                    {
                                        validator: (_, value) =>
                                            roomNames.length === 0 || roomNames.some(option => option.value === '')
                                                ? Promise.reject((t("components.tab-mapping.room-config.drawer.name-required") ?? "Names are required"))
                                                : Promise.resolve(),
                                    },
                                ]}
                            >
                                {roomNames.map((item, optIndex) => (
                                    <div key={optIndex} style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
                                        <span>{item.language.toUpperCase()}</span>
                                        <Input
                                            value={item.value}
                                            onChange={(e) => modifyRoomName(optIndex, e.target.value)}
                                            style={{ marginLeft: 8 }}
                                        />

                                    </div>
                                ))}
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={24}>
                            <Form.Item
                                name="code"
                                label={t("components.tab-mapping.room-config.drawer.code")}
                                rules={[{ required: true, message: t("components.tab-mapping.room-config.drawer.code-required") ?? "Code is required" }]}
                                initialValue={props.roomData?.code}
                            >
                                <Input className="app-input" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={24}>
                            <Form.Item
                                name="internalNote"
                                label={t("components.tab-mapping.room-config.drawer.internal-note")}
                                rules={[{ required: true, message: t("components.tab-mapping.room-config.drawer.internal-note-required") ?? "Internal note is required" }]}
                                initialValue={props.roomData?.internNote}
                            >
                                <Input className="app-input" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={24} style={{ marginTop: 40 }}>
                            <Form.Item
                                name="sync"
                                valuePropName="checked"
                                initialValue={props.roomData?.syncReward ?? false}
                            >
                                <Checkbox className="app-input" >{t("components.tab-mapping.room-config.drawer.checkbox")}</Checkbox>
                            </Form.Item>
                        </Col>


                        <Col xs={24} md={12}>
                            <Form.Item style={{ textAlign: 'center' }}>
                                <Button className="app-button" style={{ width: "70%" }} onClick={handleBtnCancel}>
                                    {t("components.button.cancel")}
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item style={{ textAlign: 'center' }}>
                                <Button className="app-button" style={{ width: "70%" }} htmlType="submit">
                                    {t("components.button.save")}
                                </Button>
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>

        </div>
    );
};

export default RoomForm