export var agenciesList = [
    {
        "id": "a1b2c3d4-e5f6-g7h8-i9j0-k1l2m3n4o5p6",
        "name": "ExploreWorld Travel Agency",
        "address": "123 Wanderlust Lane, Adventure City, USA",
        "telephone": "555-123-4567",
        "identityCode": "EXP123456",
        "idCurrency": "USD",
        "isAlreadyUsed": true
    },
    {
        "id": "b7c8d9e0-f1g2-h3i4-j5k6l7m8n9o0p1",
        "name": "GlobeTrek Adventures",
        "address": "456 Journey Road, Wanderland, Canada",
        "telephone": "789-555-2345",
        "identityCode": "GTAD987654",
        "idCurrency": "CAD",
        "isAlreadyUsed": false
    },
    {
        "id": "c2d3e4f5-g6h7-i8j9-k0l1m2n3o4p5",
        "name": "WonderVoyage Tours",
        "address": "789 Discovery Street, Wanderlustville, Australia",
        "telephone": "123-987-3456",
        "identityCode": "WVT567890",
        "idCurrency": "AUD",
        "isAlreadyUsed": true
    },
    {
        "id": "d4e5f6g7-h8i9-j0k1-l2m3n4o5-p6q7r8s9t0",
        "name": "DreamDestiny Travel",
        "address": "567 Adventure Avenue, Dreamland, Germany",
        "telephone": "456-789-5678",
        "identityCode": "DDT234567",
        "idCurrency": "EUR",
        "isAlreadyUsed": true
    },
    {
        "id": "e5f6g7h8-i9j0-k1l2-m3n4o5p6q7-r8s9t0u1v2w3",
        "name": "Zenith Explorations",
        "address": "890 Expedition Lane, Zenith City, Japan",
        "telephone": "567-234-6789",
        "identityCode": "ZE345678",
        "idCurrency": "JPY",
        "isAlreadyUsed": false
    },
    {
        "id": "1a2b3c4d-5e6f-7g8h-9i0j-1k2l3m4n5o6p",
        "name": "Wanderlust Adventures",
        "address": "123 Discovery Lane, Exploreville, USA",
        "telephone": "555-123-4567",
        "identityCode": "WA123456",
        "idCurrency": "USD",
        "isAlreadyUsed": false
    },
    {
        "id": "2b3c4d5e-6f7g-8h9i0j1k-2l3m4n5o6p7q",
        "name": "GlobalVista Travels",
        "address": "456 Horizon Street, Horizon City, Canada",
        "telephone": "789-555-2345",
        "identityCode": "GVT987654",
        "idCurrency": "CAD",
        "isAlreadyUsed": false
    },
    {
        "id": "3c4d5e6f-7g8h9i-0j1k2l3m4n5o-6p7q8r9s0t",
        "name": "DreamScape Journeys",
        "address": "789 Dream Road, Dreamland, Australia",
        "telephone": "123-987-3456",
        "identityCode": "DSJ567890",
        "idCurrency": "AUD",
        "isAlreadyUsed": false
    },
    {
        "id": "4d5e6f7g-8h9i0j1k-2l3m4n5o6p7q8-9r0s1t2u3v",
        "name": "Excursion Explorers",
        "address": "567 Adventure Lane, Exciteville, Germany",
        "telephone": "456-789-5678",
        "identityCode": "EE234567",
        "idCurrency": "EUR",
        "isAlreadyUsed": false
    },
    {
        "id": "5e6f7g8h-9i0j1k-2l3m4n5o6p7q-8r9s0t1u2v3w",
        "name": "Zenith Journeys",
        "address": "890 Zenith Street, Zenithburg, Japan",
        "telephone": "567-234-6789",
        "identityCode": "ZJ345678",
        "idCurrency": "JPY",
        "isAlreadyUsed": false
    },
    {
        "id": "6f7g8h9i-0j1k2l3m4n5o-6p7q8r9s0t1u2v3w4x",
        "name": "Adventure Plus",
        "address": "123 Thrill Avenue, Adventureland, USA",
        "telephone": "555-789-0123",
        "identityCode": "AP678901",
        "idCurrency": "USD",
        "isAlreadyUsed": false
    },
    {
        "id": "7g8h9i0j-1k2l3m4n5o-6p7q8r9s0t1u2v3w4x5y",
        "name": "Voyage World",
        "address": "456 Journey Lane, Voyageville, Canada",
        "telephone": "789-234-5678",
        "identityCode": "VW012345",
        "idCurrency": "CAD",
        "isAlreadyUsed": false
    },
    {
        "id": "8h9i0j1k-2l3m4n5o6p-7q8r9s0t1u2v3w4x5y6z",
        "name": "EcoExpeditions",
        "address": "789 Eco Street, Greenburg, Australia",
        "telephone": "123-456-7890",
        "identityCode": "EE456789",
        "idCurrency": "AUD",
        "isAlreadyUsed": false
    },
    {
        "id": "9i0j1k2l-3m4n5o6p7q-8r9s0t1u2v3w4x5y6z0a",
        "name": "Timeless Travels",
        "address": "567 Time Avenue, Eternal City, Germany",
        "telephone": "456-678-9012",
        "identityCode": "TT567890",
        "idCurrency": "EUR",
        "isAlreadyUsed": false
    },
    {
        "id": "1k2l3m4n-5o6p7q8r9s-0t1u2v3w4x5y6z0a1b2c",
        "name": "Wonderscape Tours",
        "address": "890 Wonder Lane, Marvelville, Japan",
        "telephone": "567-890-1234",
        "identityCode": "WT890123",
        "idCurrency": "JPY",
        "isAlreadyUsed": false
    }
];