import React, { useState } from 'react';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/mode-css';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-github';
import { Row, Col, Card, Button } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import './CodeEditor.scss';
import { useTranslation } from 'react-i18next';


interface CodeEditorProps {
    htmlCode: string;
    cssCode: string;
    jsCode: string;
    setHtmlCode: (htmlCode: string) => void;
    setCssCode: (cssCode: string) => void;
    setJsCode: (jsCode: string) => void;
}

const CodeEditor = (props: CodeEditorProps) => {
    const { t } = useTranslation();
    const [renderedContent, setRenderedContent] = useState('');
    const [isHtmlExpanded, setIsHtmlExpanded] = useState(false);
    const [isCssExpanded, setIsCssExpanded] = useState(false);
    const [isJsExpanded, setIsJsExpanded] = useState(false);

    const renderCode = () => {
        // Crear un blob URL para el código JavaScript
        const scriptBlob = new Blob([props.jsCode], { type: 'application/javascript' });
        const scriptUrl = URL.createObjectURL(scriptBlob);
        const documentContent = `
        <html>
            <head>
                <style>${props.cssCode}</style>
            </head>
            <body>
                ${props.htmlCode}
                <script src="${scriptUrl}"></script>
            </body>
        </html>`;
        setRenderedContent(documentContent);
    };

    const toggleHtmlExpand = () => {
        setIsHtmlExpanded(!isHtmlExpanded);
    };

    const toggleCssExpand = () => {
        setIsCssExpanded(!isCssExpanded);
    };

    const toggleJsExpand = () => {
        setIsJsExpanded(!isJsExpanded);
    };

    return (
        <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <div className='code-editor'>
                    <div className='container'>
                        <h2>HTML</h2>
                        {isHtmlExpanded ? <UpOutlined onClick={toggleHtmlExpand} /> :
                            <DownOutlined onClick={toggleHtmlExpand} />}
                    </div>
                    <div className='editor-wrapper'>
                        <AceEditor
                            mode="html"
                            theme="github"
                            value={props.htmlCode}
                            setOptions={{ useWorker: false }}
                            onChange={props.setHtmlCode}
                            name="html_editor"
                            editorProps={{ $blockScrolling: true }}
                            width="100%"
                            height={isHtmlExpanded ? '300px' : '100px'}
                        />
                    </div>
                </div>
                <div className='code-editor'>
                    <div className='container'>
                        <h2>CSS</h2>
                        {isCssExpanded ? <UpOutlined onClick={toggleCssExpand} /> :
                            <DownOutlined onClick={toggleCssExpand} />}
                    </div>
                    <div className='editor-wrapper'>
                        <AceEditor
                            mode="css"
                            theme="github"
                            value={props.cssCode}
                            setOptions={{ useWorker: false }}
                            onChange={props.setCssCode}
                            name="css_editor"
                            editorProps={{ $blockScrolling: true }}
                            width="100%"
                            height={isCssExpanded ? '300px' : '100px'}
                        />
                    </div>
                </div>
                <div className='code-editor'>
                    <div className='container'>
                        <h2>JavaScript</h2>
                        {isJsExpanded ? <UpOutlined onClick={toggleJsExpand} /> :
                            <DownOutlined onClick={toggleJsExpand} />}
                    </div>
                    <div className='editor-wrapper'>
                        <AceEditor
                            mode="javascript"
                            theme="github"
                            value={props.jsCode}
                            setOptions={{ useWorker: false }}
                            onChange={props.setJsCode}
                            name="js_editor"
                            editorProps={{ $blockScrolling: true }}
                            width="100%"
                            height={isJsExpanded ? '300px' : '100px'}
                        />
                    </div>
                </div>
                <Button className="app-button full-width margin-button" onClick={renderCode} style={{ marginTop: '20px' }}>{t("components.button.render")}</Button>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                <Card title="Preview" style={{ height: '100%' }}>
                    <iframe
                        title="Preview"
                        srcDoc={renderedContent}
                        style={{ width: '100%', height: '100%', border: 'none' }}
                    />
                </Card>
            </Col>
        </Row>
    );
};

export default CodeEditor;
