import { createRegimesConfig, editRegimesConfig, fetchLanguagesAvailables } from "../../../../../../../api/repositoryEP";
import { RegimesConfigCreateData, RegimesConfigData, TranslationData } from "../../../../../../../models/MappingData";
import { useEffect, useState } from "react";
import { Form, Row, Col, Button } from "antd";
import { Input, Checkbox } from "antd";
import { notification } from 'antd';
import { useTranslation } from "react-i18next";
import { LoadingOutlined } from "@ant-design/icons";
import './RegimesForm.scss'


interface RegimesFormProps {
    drawerVisible: boolean;
    setDrawerVisible: (visible: boolean) => void;
    regimesData: RegimesConfigData | null;
    setRegimesData: (regimesData: RegimesConfigData | null) => void;
}


const RegimesForm = (props: RegimesFormProps): JSX.Element => {
    const [regimesName, setRegimesName] = useState<Array<TranslationData>>([])
    const { t } = useTranslation();
    const [form] = Form.useForm();


    const showErrorMessage = () => {
        notification.error({
            message: t('actions.error-message'),
            description: t('actions.error-description')
        });
    }
    const showSuccessNotification = () => {
        notification.success({
            message: t('actions.success-message'),
            description: t('actions.success-description')
        });
    }


    const closeDrawer = () => {
        props.setRegimesData(null)
        form.resetFields();
        props.setDrawerVisible(false)
    }



    const modifyRegimesName = (index: number, value: string) => {
        const newRegimesName = [...regimesName];
        newRegimesName[index].value = value;
        setRegimesName(newRegimesName);
    }



    const handleBtnCancel = () => {
        closeDrawer();
    }



    useEffect(() => {


        if (props.drawerVisible) {

            if (props.regimesData) {
                setRegimesName(props.regimesData.name)
            } else {
                fetchLanguagesAvailables().then((r) => {
                    if (r?.code === 200) {
                        const languages = r.data.map((lang: string) => {
                            return { language: lang, value: '' }
                        })
                        setRegimesName(languages)
                    } else {
                        showErrorMessage();
                        props.setDrawerVisible(false)
                    }
                })
            }
        }
    }, [props.drawerVisible]);





    const onFinish = (values: any) => {

        if (props.regimesData === null) { // CREATE
            const regimesData: RegimesConfigCreateData = {
                name: regimesName,
                code: values.code,
                internNote: values.internalNote,
                syncReward: values.sync
            }

            createRegimesConfig(regimesData).then((response) => {
                if (response?.code === 200) {
                    showSuccessNotification()
                    closeDrawer();
                } else {
                    showErrorMessage();
                }
            })

        } else { // EDIT
            const regimesData: RegimesConfigData = {
                id: props.regimesData?.id,
                name: regimesName,
                code: values.code,
                internNote: values.internalNote,
                syncReward: values.sync
            }

            editRegimesConfig(regimesData).then((response) => {
                if (response?.code === 200) {
                    showSuccessNotification()
                    closeDrawer();
                } else {
                    showErrorMessage();
                }
            })
        }

    }


    return (
        <div>
          
                <Form form={form}
                    onFinish={onFinish}
                    className='regimes-form-component'
                    layout="vertical"
                >
                    <Row gutter={16}>
                        <Col xs={24} md={24}>
                            <Form.Item
                                name="name"
                                label={t("components.tab-mapping.regimes-config.drawer.name")}
                                required
                                rules={[
                                    {
                                        validator: (_, value) =>
                                            regimesName.length === 0 || regimesName.some(option => option.value === '')
                                                ? Promise.reject((t("components.tab-mapping.regimes-config.drawer.name-required") ?? "Names are required"))
                                                : Promise.resolve(),
                                    },
                                ]}
                            >
                                {regimesName.map((item, optIndex) => (
                                    <div key={optIndex} style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
                                        <span>{item.language.toUpperCase()}</span>
                                        <Input
                                            value={item.value}
                                            onChange={(e) => modifyRegimesName(optIndex, e.target.value)}
                                            style={{ marginLeft: 8 }}
                                        />

                                    </div>
                                ))}
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={24}>
                            <Form.Item
                                name="code"
                                label={t("components.tab-mapping.regimes-config.drawer.code")}
                                rules={[{ required: true, message: t("components.tab-mapping.regimes-config.drawer.code-required") ?? "Code is required" }]}
                                initialValue={props.regimesData?.code}
                            >
                                <Input className="app-input" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={24}>
                            <Form.Item
                                name="internalNote"
                                label={t("components.tab-mapping.regimes-config.drawer.internal-note")}
                                rules={[{ required: true, message: t("components.tab-mapping.regimes-config.drawer.internal-note-required") ?? "Internal note is required" }]}
                                initialValue={props.regimesData?.internNote}
                            >
                                <Input className="app-input" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={24} style={{ marginTop: 40 }}>
                            <Form.Item
                                name="sync"
                                valuePropName="checked"
                                initialValue={props.regimesData?.syncReward ?? false}
                            >
                                <Checkbox className="app-input" >{t("components.tab-mapping.regimes-config.drawer.checkbox")}</Checkbox>
                            </Form.Item>
                        </Col>


                        <Col xs={24} md={12}>
                            <Form.Item style={{ textAlign: 'center' }}>
                                <Button className="app-button" style={{ width: "70%" }} onClick={handleBtnCancel}>
                                    {t("components.button.cancel")}
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item style={{ textAlign: 'center' }}>
                                <Button className="app-button" style={{ width: "70%" }} htmlType="submit">
                                    {t("components.button.save")}
                                </Button>
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>


        

        </div>
    );
};

export default RegimesForm