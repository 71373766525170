import { Button, Form, Input } from "antd";
import { useForm } from "antd/es/form/Form";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import './ForgetPasswordForm.scss';

interface ForgetPasswordFormProps {
    loading: boolean,
    onSendForm: (usernameOrEmail: string) => void,
    onCancel: () => void
}

const ForgetPasswordFormComponent = (props: ForgetPasswordFormProps) : JSX.Element => {
    const { t } = useTranslation();
    
    const [form] = useForm();

    const { loading, onSendForm, onCancel } = props;

    const onFinish = () => {
        onSendForm(form.getFieldValue("email"));
        form.resetFields();
    }

    const onCancelPush = () : void => {
        form.resetFields();
        onCancel();
    }

    useEffect(()=>{
        form.resetFields();
    },[])

    return(
        <div className="forget-password-form">
            <Form form={form} onFinish={onFinish} layout="horizontal">
                <Form.Item
                    label={t("components.forget-password-form.email")}
                    name="email"
                    rules={[{ required: true, message: t("forms.validations.required-field")! }]}
                >
                    <Input className="app-input" type="email"/>
                </Form.Item>
                <div className="actions">
                    <Button style={{borderRadius: "2px"}} type="default" key="back" onClick={onCancelPush}>
                        {t("components.forget-password-form.cancel")}
                    </Button>
                    <Button disabled={loading} className="app-button" key="submit" htmlType="submit" type="primary" loading={loading}>
                        {t("components.forget-password-form.submit")}
                    </Button>
                </div>
            </Form>
        </div>
    )
}

export default ForgetPasswordFormComponent;