import { Button } from 'antd';
import { GetHomeIcon, GetRainCloudIcon, GetReloadIcon } from '../../../utils/icons';
import { useTranslation } from 'react-i18next';
import { GetDefaultLanguage, GetLanguageInUrl, IsLaguagePresentInUrl } from '../../../utils/urls';

import './403.scss';


interface Page403Props{}

const Page403 = (props : Page403Props) : JSX.Element => {
    const { t } = useTranslation();


    const goHome = () : void => {
        const url : string = IsLaguagePresentInUrl() ? GetLanguageInUrl() : GetDefaultLanguage();
        window.location.href = url;
    }
    const goLogin = () : void => {
        const url : string = IsLaguagePresentInUrl() ? GetLanguageInUrl() : GetDefaultLanguage();
        window.location.href = `${url}/login`;
    }

    return <div className="error-403-page generic-message">
                    <div className="icon">
                        {
                            GetRainCloudIcon()
                        }
                    </div>
                    <div className="number">
                        403
                    </div>
                    <div className="message">
                        <p className="title">
                            {t('pages.403.title')}
                        </p>
                        <p className="text">
                            {t('pages.403.first-message')}
                        </p>
                    </div>
                    <div className="buttons">
                        <Button className="app-button" onClick={goHome}>
                            <div className="content">
                                {GetHomeIcon()}
                                <span>
                                    {t('pages.403.buttons.go-home')}
                                </span>
                            </div>
                        </Button>
                        <Button className="app-button" onClick={goLogin}>
                            <div className="content">
                                {GetReloadIcon()}
                                <span>
                                    {t('pages.403.buttons.go-login')}
                                </span>
                            </div>
                        </Button>
                    </div>
            </div>
}

export default Page403;