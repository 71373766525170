import AppConfig from "../models/AppConfig";

export var configClientApplication : AppConfig = {
    mainColor: "#3DBAB3",
    secondaryColor: "#414745",
    scripts: [
        {
            key: 'google-maps-api-key',
            value: 'AIzaSyDIdhJZxHzb9cNHbj_e10MEeRH6zYynVlY'
        }
    ],
    dateFormat: "DD/MM/YYYY",
    availableLanguages: [
        {"iso": "es-ES", "shortCode": "es", "description": "Español", "enabled": true},
        {"iso": "en-EN", "shortCode": "en", "description": "English", "enabled": true},
        {"iso": "fr-FR", "shortCode": "fr", "description": "Français", "enabled": false},
    ].sort((a, b) => a.description.localeCompare(b.description)),
    siteName: "Hotel Brand PRO",
    legalTexts:{
        legalWarning: 'Texto del aviso legal',
        privacity: 'Texto de la política de privacidad',
        cookies: 'Texto de las cookies',
        termsAndConditions: 'Texto de los términos y condiciones'
    },
    images:{
        brandLogo: "https://cdn.pro.api.beds2b.com//images/clients/7b1a849c-a80a-5a23-a3a8-86f9e53e6c6b/resources/senator-hotels-and-resort-logo.png",
        loginBackGround: "",
        appLogo: "https://senatorpro.com/wp-content/uploads/2023/02/senatorprologo.png"
    },
    availableCurrencies: [
        {description: "Euro", id: "EUR", iso: "EUR", symbol: "€", available: true},
        {description: "Canadian Dollar", id: "CAN", iso: "CAN", symbol: "C$", available: false},
        {description: "American Dollar", id: "USD", iso: "USD", symbol: "$", available: true},
        {description: "Great British Pound", id: "GBP", iso: "GBP", symbol: "£", available: false}
    ],
    contactPhones: [
        {
            isoCountry: "es",
            countryName: "España",
            phone: "123456789"
        },
        {
            isoCountry: "fr",
            countryName: "Francia",
            phone: "369852147"
        },
        {
            isoCountry: "us",
            countryName: "Estados Unidos",
            phone: "951623487"
        },
        {
            isoCountry: "cn",
            countryName: "China",
            phone: "369852147"
        },
        {
            isoCountry: "br",
            countryName: "Brasil",
            phone: "369852147"
        }
    ],
    customRegisterFields: [
        {
            availableLanguages: [],
            order: 0,
            searchable: true,
            labels: [
                {
                    label: 'Campo personalizado no obligatorio',
                    languageIso: 'es-ES'
                },
                {
                    label: 'No mandatory field',
                    languageIso: 'en-US'
                }
            ],
            placeholder: [],
            alone: false,
            name: "field1",
            defaultValue: '',
            options: [],
            required: false,
            type: 'input'
        },
        {
            availableLanguages: [],
            order: 1,
            searchable: true,
            labels: [
                {
                    label: 'Campo personalizado obligatorio',
                    languageIso: 'es-ES'
                },
                {
                    label: 'Mandatory field',
                    languageIso: 'en-US'
                }
            ],
            placeholder: [],
            alone: false,
            name: "field2",
            defaultValue: '',
            options: [],
            required: true,
            type: 'input'
        },
        {
            availableLanguages: [],
            order: 3,
            searchable: true,
            labels: [
                {
                    label: 'Desplegable',
                    languageIso: 'es-ES'
                },
                {
                    label: 'Mandatory field',
                    languageIso: 'en-US'
                }
            ],
            placeholder: [],
            alone: false,
            name: "field3",
            defaultValue: 'select-3',
            options: [
                {key: 'select-1', value: [
                    {
                        label: 'Opción 1',
                        languageIso: 'es-ES'
                    },
                    {
                        label: 'Option 1',
                        languageIso: 'en-US'
                    }
                ]},
                {key: 'select-2', value: [
                    {
                        label: 'Opción 2',
                        languageIso: 'es-ES'
                    },
                    {
                        label: 'Option 2',
                        languageIso: 'en-US'
                    }
                ]},
                {key: 'select-3', value: [
                    {
                        label: 'Opción 3',
                        languageIso: 'es-ES'
                    },
                    {
                        label: 'Option 3',
                        languageIso: 'en-US'
                    }
                ]},
                {key: 'select-4', value: [
                    {
                        label: 'Opción 4',
                        languageIso: 'es-ES'
                    },
                    {
                        label: 'Option 4',
                        languageIso: 'en-US'
                    }
                ]},
            ],
            required: true,
            type: 'select'
        },
        {
            availableLanguages: ['es'],
            order: 4,
            searchable: true,
            labels: [
                {
                    label: 'Cuadro de texto (solo disponible en español)',
                    languageIso: 'es-ES'
                }
            ],
            placeholder: [],
            alone: true,
            name: "field5",
            defaultValue: '',
            options: [],
            required: true,
            type: 'text-area'
        }
    ],
}