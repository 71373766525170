
import LoginFormComponent from "./LoginForm/LoginForm";
import ForgetPasswordFormComponent from "./ForgetPasswordForm/ForgetPasswordForm";   

//Profile components
import ChangePasswordComponent from "./Profile/ChangePassword/ChangePasswordComponent";
import EditProfileComponent from "./Profile/EditProfile/EditProfileComponent";
import PersonalDataComponent from "./Profile/PersonalData/PersonalDataComponent";

//Accounts components
import AccountsFilterComponent from "./accounts/filter/AccountsFilterComponent";
import AccountsListComponent from "./accounts/list/AccountsListComponent";
import AccountManageComponent from './accounts/manage/AccountManageComponent';
import AccountUsersFilterComponent from "./accountUsers/filter/AccountUsersFilterComponent";
import AccountUsersListComponent from "./accountUsers/list/AccountUsersListComponent";

//Bookings components
import BookingsListComponent from './bookings/list/BookingListComponent';
import BookingsFilterComponent from './bookings/filter/BookingsFilterComponent';
import CancellationPoliciesComponent from './cancellationpolicies/CancellationPoliciesComponent';
import OccupationComponent from './occupation/OccupationComponent'

//Commons components
import PaginationComponent from "./Pagination/PaginationComponent";
import UsersListComponent from "./users/list/UsersListComponent";
import UsersFilterComponent from "./users/filter/UsersFilterComponent";



//Exported components
export const LoginForm = LoginFormComponent;
export const ForgetPassword = ForgetPasswordFormComponent;
export const ChangePassword = ChangePasswordComponent;
export const EditProfile = EditProfileComponent;
export const PersonalData = PersonalDataComponent;
export const CustomPagination = PaginationComponent;
export const AccountsFilter = AccountsFilterComponent;
export const AccountsList = AccountsListComponent;
export const AccountManage = AccountManageComponent;
export const AccountUsersFilter = AccountUsersFilterComponent;
export const AccountUsersList = AccountUsersListComponent;
export const BookingsList = BookingsListComponent;
export const BookingsFilter = BookingsFilterComponent;
export const CancellationPolicies = CancellationPoliciesComponent;
export const Occupation = OccupationComponent;
export const UsersFilter = UsersFilterComponent;
export const UsersList = UsersListComponent;