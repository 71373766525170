import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Button, Col, Drawer, Row, Table } from 'antd';
import type { TableProps } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { ServicesConfigData, TranslationData } from '../../../../../../models/MappingData';
import { fetchServicesConfigMapping } from '../../../../../../api/repositoryEP';
import { notification } from 'antd';
import ModalGeneric from '../Modals/ModalGeneric';
import ServicesForm from '../Forms/ServicesForm/ServicesForm';
import Search from 'antd/es/input/Search';

interface ServicesConfigProps {
    language: string;
}

const ServicesConfig = (props: ServicesConfigProps): JSX.Element => {

    const { t } = useTranslation();
    const [data, setData] = useState<ServicesConfigData[]>([]);
    const [serviceData, setServiceData] = useState<ServicesConfigData | null>(null);
    const drawerWidth = window.innerWidth <= 768 ? '100%' : '40%';
    const [newServiceDrawer, setNewServiceDrawer] = useState<boolean>(false);
    const [editServiceDrawer, setEditServiceDrawer] = useState(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);


    useEffect(() => {
        if (!newServiceDrawer && !editServiceDrawer && !deleteModalVisible) {
            fetchServicesConfigMapping('').then((response) => {
                if (response?.code === 200) {
                    setData(response.data);
                } else {
                    showErrorMessage();
                }
            });
        }

    }, [newServiceDrawer, editServiceDrawer, deleteModalVisible]);



    const columns: TableProps<ServicesConfigData>['columns'] = [
        {
            title: t("components.tab-mapping.services-config.table.c1"),
            dataIndex: 'name',
            key: 'name',
            render: (names) => {
                const nameInLangugage = names.find((name: TranslationData) => name.language === props.language);
                return nameInLangugage?.value;
            }
        },
        {
            title: t("components.tab-mapping.services-config.table.c2"),
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: t("components.tab-mapping.services-config.table.c3"),
            dataIndex: 'internNote',
            key: 'interNote',
        },
        {
            title: t("components.tab-mapping.services-config.table.c4"),
            key: 'action',
            render: (obj) => (
                <div className='icon-container' >
                    <EditOutlined className='icon' onClick={() => editService(obj)} />
                    <DeleteOutlined className='icon' onClick={() => deleteService(obj)} />
                </div>
            ),
        }
    ]


    const editService = (obj: ServicesConfigData) => {
        setServiceData(obj);
        setEditServiceDrawer(true);

    }
    const deleteService = (obj: ServicesConfigData) => {
        setServiceData(obj);
        setDeleteModalVisible(true);
    }

    const onSearch = (value: string) => {
        fetchServicesConfigMapping(value).then((response) => { 
            if (response?.code === 200) {
                setData(response.data);
            } else {
                showErrorMessage();
            }
         })
    }



    const showErrorMessage = () => {
        notification.error({
            message: t('actions.error-message'),
            description: t('actions.error-description')
        });
    }

    const handleAddButton = () => {
        setServiceData(null);
        setNewServiceDrawer(true);
    }



    return (
        <div className="services-configuration-component">
            {deleteModalVisible && serviceData?.id &&
                <ModalGeneric
                    modalVisible={deleteModalVisible}
                    setModalVisible={setDeleteModalVisible}
                    action='deleteService'
                    id={serviceData.id}
                    data={serviceData}
                    setData={setServiceData}
                />}

            <Drawer
                open={editServiceDrawer}
                title={t("components.tab-mapping.services-config.drawer.title-edit")}
                onClose={() => {
                    setServiceData(null)
                    setEditServiceDrawer(false);
                }}
                width={drawerWidth}
                closable
            >

                <ServicesForm
                    setDrawerVisible={setEditServiceDrawer}
                    drawerVisible={editServiceDrawer}
                    serviceData={serviceData}
                    setServiceData={setServiceData}
                />
            </Drawer>


            <Drawer
                open={newServiceDrawer}
                title={t("components.tab-mapping.services-config.drawer.title-new")}
                onClose={() => {
                    setServiceData(null)
                    setNewServiceDrawer(false);
                }}
                width={drawerWidth}
                closable
            >

                <ServicesForm
                    setDrawerVisible={setNewServiceDrawer}
                    drawerVisible={newServiceDrawer}
                    serviceData={serviceData}
                    setServiceData={setServiceData}
                />
            </Drawer>

            <Row gutter={[10, 10]} style={{ flexDirection: 'column' }}>
                <Col xs={24} md={24}>
                <Row gutter={[16, 16]}>
                        <Col xs={24} md={24} style={{ fontFamily: 'Montserrat' }}>
                            {t("components.tab-mapping.services-config.msg-info")}
                        </Col>
                        <Col xs={24} md={24} lg={12}>
                            <Search
                                placeholder={t("components.tab-mapping.services-config.search") || ''}
                                allowClear
                                onSearch={onSearch}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={6} className='button-container'>
                            <Button
                                className='button-class'
                                onClick={() => handleAddButton()}>
                                {t("components.tab-mapping.room-config.button-add")}
                            </Button>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={6} className='button-container'>
                            <Button className='button-import'>{t("components.tab-mapping.room-config.button-import")}</Button>
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} md={24}>
                    <Table
                        className='table'
                        columns={columns}
                        dataSource={data}
                        scroll={{ x: 'max-content' }} />
                </Col>
            </Row>

        </div>
    );
};



export default ServicesConfig;