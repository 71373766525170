
import {FormGroupData } from '../models/ConfigurationData';

export const groupFormMock: FormGroupData = {
    email : "email@email.com",
    agentCopy: true,
    minOccupancy: 1,
    maxOccupancy: 2,
    minRooms: 1,
    maxRooms: 5,
    release : 5,
    language: "es",
}
