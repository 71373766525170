
import { Pagination } from 'antd';
import './PaginationComponent.scss'

interface PaginationComponentProps {
    totalElements: number;
}

const PaginationComponent = (props: PaginationComponentProps) : JSX.Element => {

    const { totalElements } = props;

    return(
        <div className="pagination-component">
            <div className="pagination-resume">
                <span>{totalElements}</span> resultados.
            </div>
            <div className="pagination-antd">
                <Pagination defaultCurrent={1} total={50} /> 
            </div>
        </div>
    )
}

export default PaginationComponent;