
import './UsersPage.scss'
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { UsersFilterData } from '../../../models/UsersFilterData';
import { CustomPagination } from '../../../components';
import UserData from '../../../models/UserData';
import { fetchUsers } from '../../../api/repositoryEP';
import { UsersList } from '../../../components';
import { UsersFilter } from '../../../components';



const UsersPage = (): JSX.Element => {
    const { t } = useTranslation();
    const [totalElements, setTotalElements] = useState<number>(0);
    const [searching, setSearching] = useState<boolean>(false);
    const [accountList, setAccountList] = useState<UserData[]>([]);

    const onSearch = (filterData: UsersFilterData = {
        name: '',
        language: '',
        role: '',
        email: '',
        phoneNumber: '',
        page: 0,
        pageSize: 5
    }) => {
        setSearching(true);
        fetchUsers(filterData).then((r)=>{
            if (r?.typeText === "success" && r?.data){
                setAccountList(r?.data.list as UserData[]);
                setTotalElements(r?.data.totalElements as number);
            }else{
                setAccountList([]);
                setTotalElements(0);
            }
        }).finally(()=>{
            setSearching(false);
        })
    }

    useEffect(()=>{
        onSearch();
    },[]);



    return (
        <div className="users-page">
            <h3>
                {t('pages.accounts-users.title')}
            </h3>
            <div className="list-container">
                <UsersFilter onSearch={()=>{}} searching={false}/>
                <UsersList list={accountList} loading={searching} />
            </div>
            <div className="pagination-container">
                <CustomPagination totalElements={totalElements} />
            </div>
        </div>
    )
}

export default UsersPage;