
import './TabConfiguration.scss';
import React, { useEffect, useState } from "react";
import { Tabs, Modal, Button } from "antd";
import { useTranslation } from "react-i18next";
import GroupForm from "./components/GroupForm/GroupForm";
import LandingPage from "./components/LandingPage/LandingPage";
import MappingConfig from "./components/MappingConfig/MappingConfig";
import ProConfiguration from "./components/ProConfiguration/ProConfiguration";
import RegisterForm from "./components/RegisterForm/RegisterForm";
import type { TabsProps } from 'antd';
import { GetDefaultLanguage } from '../../utils/urls';




const TabConfiguration = (): JSX.Element => {
    const { t } = useTranslation();


    const items: TabsProps['items'] = [
        {
            key: '1',
            label: t("components.tab-configuration.pro-configuration.title"),
            children: <ProConfiguration />,
        },
        {
            key: '2',
            label: t("components.tab-configuration.landing-page.title"),
            children: <LandingPage />,
        },
        {
            key: '3',
            label: t("components.tab-configuration.register-form.title"),
            children: <RegisterForm />,
        },
        {
            key: '4',
            label: t("components.tab-configuration.group-form.title"),
            children: <GroupForm defaultLanguage={GetDefaultLanguage()}/>,
        },
        {
            key: '5',
            label: t("components.tab-configuration.mapping-config.title"),
            children: <MappingConfig />,
        },
    ];

    return (
        <div className="tab-configuration-component ant-tabs-nav">
            <Tabs defaultActiveKey="1" items={items} className='tabs' />
        </div> 
    );
}


export default TabConfiguration