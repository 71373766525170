import AccountData from "../models/AccountData";

export const accountDataList: AccountData[] = [
  {
    id: "1234", type: "agency",
    creationDate: new Date("2023-01-01"),
    commercialName: "Nombre de agencia 1",
    manager: "John Doe",
    address: "C/ Puerta del Sol, 32, 28013, Provincia de Madrid, Spain",
    status: "active",
    contact:{
      phone: "(+34) 123 45 96 87",
      email: "email@email.es"
    },
    resume:{
      bookingNumber: 20,
      usersNumber: 100,
    },
    agencyData:{
      id: "id1",
      address: "C/ Puerta del Sol, 32, 28013, Provincia de Madrid, Spain",
      idCurrency: "EUR",
      identityCode: "123456789",
      name: "Nombre de agencia 1",
      telephone: "(+34) 123 45 96 87",
      isAlreadyUsed: true
    }
  },
  {
    id: "1235", type: "business",
    creationDate: new Date("2023-02-15"),
    commercialName: "Nombre empresa 1",
    manager: "Jane Doe",
    address: "C/ Puerta del Sol, 32, 28013, Provincia de Madrid, Spain",
    status: "inactive",
    contact:{
      phone: "(+34) 123 45 96 87",
      email: "email@email.es"
    },
    resume:{
      bookingNumber: 10,
      usersNumber: 50,
    },
    companyData:{
      id: "id1",
      address: "C/ Puerta del Sol, 32, 28013, Provincia de Madrid, Spain",
      idCurrency: "EUR",
      identityCode: "123456789",
      name: "Nombre de empresa 1",
      telephone: "(+34) 123 45 96 87",
      isAlreadyUsed: true
    }
  },
  {
    id: "1237", type: "independent",
    creationDate: new Date("2023-03-10"),
    commercialName: "Nombre independiente 1",
    manager: "John Doe",
    address: "C/ Puerta del Sol, 32, 28013, Provincia de Madrid, Spain",
    status: "active",
    contact:{
      phone: "(+34) 123 45 96 87",
      email: "email@email.es"
    },
    resume:{
      bookingNumber: 5,
      usersNumber: 10,
    }
  },
  {
    id: "1236", type: "events",
    creationDate: new Date("2023-04-20"),
    commercialName: "Nombre eventos 1",
    manager: "Jane Doe",
    address: "C/ Puerta del Sol, 32, 28013, Provincia de Madrid, Spain",
    status: "pending",
    contact:{
      phone: "(+34) 123 45 96 87",
      email: "email@email.es"
    },
    resume:{
      bookingNumber: 0,
      usersNumber: 0,
    }
  },
  {
    id: "1238", type: "agency",
    creationDate: new Date("2023-05-05"),
    commercialName: "Nombre agencia 2",
    manager: "John Doe",
    address: "C/ Puerta del Sol, 32, 28013, Provincia de Madrid, Spain",
    status: "inactive",
    contact:{
      phone: "(+34) 123 45 96 87",
      email: "email@email.es"
    },
    resume:{
      bookingNumber: 20,
      usersNumber: 100,
    },
    agencyData:{
      id: "id1",
      address: "C/ Puerta del Sol, 32, 28013, Provincia de Madrid, Spain",
      idCurrency: "EUR",
      identityCode: "123456789",
      name: "Nombre de agencia 1",
      telephone: "(+34) 123 45 96 87",
      isAlreadyUsed: true
    }    
  },
  {
    id: "1239", type: "business",
    creationDate: new Date("2023-06-30"),
    commercialName: "Nombre empresa 2",
    manager: "Jane Doe",
    address: "C/ Puerta del Sol, 32, 28013, Provincia de Madrid, Spain",
    status: "active",
    contact:{
      phone: "(+34) 123 45 96 87",
      email: "email@email.es"
    },
    resume:{
      bookingNumber: 10,
      usersNumber: 50,
    },
    companyData:{
      id: "id1",
      address: "C/ Puerta del Sol, 32, 28013, Provincia de Madrid, Spain",
      idCurrency: "EUR",
      identityCode: "123456789",
      name: "Nombre de empresa 1",
      telephone: "(+34) 123 45 96 87",
      isAlreadyUsed: true
    }
  },
];
