import { RegisterFormFieldsData } from '../models/RegisterFormData';

export const fieldsRegisterFormDemo: Array<RegisterFormFieldsData> = [
    {
        id: '1',
        fieldType: 'input',
        language: 'es',
        label: ' Nombre de usuario',
        isActive: true,
        order: 1,
        searchable: false,
        required : false,
        alone: false,
        nameField: "username",
        defaultValue: "",
        options: "", // es un json realmente
        placeholder: "Introduce el nombre de usuario",
    },
    {

        id: '2',
        fieldType: 'input',
        language: 'en',
        label: ' Username',
        isActive: false,
        required: false,
        order: 1,
        searchable: false,
        alone: true,
        nameField: "username",
        defaultValue: "",
        options: "", // es un json realmente
        placeholder: "Introduce the username",

    },
    {

        id: '3',
        fieldType: 'select',
        language: 'en',
        label: ' language',
        required: true,
        isActive: true,
        order: 2,
        searchable: false,
        alone: false,
        nameField: "language",
        defaultValue: "",
        options: '["option1"," option2"]', // es un json realmente
        placeholder: "",

    },
    {
        id: '4',
        fieldType: 'radio',
        language: 'en',
        label: ' language',
        required: true,
        isActive: true,
        order: 2,
        searchable: false,
        alone: false,
        nameField: "language",
        defaultValue: "",
        options: '["option1"]', // es un json realmente
        placeholder: "",
    },
]