import './ButtonTabMapping.scss';
import React, { useState } from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";

import HotelsConfig from './HotelsConfig/HotelsConfig';
import RoomConfig from './RoomConfig/RoomConfig';
import RegimesConfig from './RegimesConfig/RegimesConfig';
import ServicesConfig from './ServicesConfig/ServicesConfig';
import { GetDefaultLanguage } from '../../../../../utils/urls';

interface ButtonTabMappingProps {
    language: string;

}

const ButtonTabMapping = (props : ButtonTabMappingProps): JSX.Element => {
    const { t } = useTranslation();
    const [selectedComponent, setSelectedComponent] = useState<React.ReactNode >(<RoomConfig language={props.language} />);
    const [selectedIndex, setSelectedIndex] = useState<number>(0); // Estado para el índice seleccionado


    const items: Array<any> = [
        {
            label: t("components.tab-mapping.room-config.title"),
            component: <RoomConfig language={props.language} />,
        },
        {
            label: t("components.tab-mapping.regimes-config.title"),
            component: <RegimesConfig language={props.language} />,
        },
        {
            label: t("components.tab-mapping.hotels-config.title"),
            component: <HotelsConfig  language={props.language}/>,
        },
        {
            label: t("components.tab-mapping.services-config.title"),
            component: <ServicesConfig language={props.language}/>,
        },
    ];

    const handleButtonClick = (component: React.ReactNode, index: number) => {
        setSelectedComponent(component);
        setSelectedIndex(index);
    };




    return (
        <div className='button-tab-mapping-component'>
            <div className="button-group">
                {items.map((item, index) => (
                    <Button
                        key={index}
                        className={`button ${index === selectedIndex ? 'active' : ''}`} // Añade clase 'active' si el índice coincide
                        onClick={() => handleButtonClick(item.component, index)}
                    >
                        {item.label}
                    </Button>
                ))}
            </div>
            <div className="component-container">
                {selectedComponent}
            </div>
        </div>
    );
}

export default ButtonTabMapping;
