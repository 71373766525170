

import { Card, Drawer, Switch, Tooltip } from 'antd';
import AccountData from '../../../models/AccountData';
import './AccounstListComponent.scss'
import { GetAccountsIcon, GetAgencyIcon, GetAgentEventsIcon, GetAgentIndependentIcon, GetBusinessIcon, GetUserIcon, getAccountTypeIcon } from '../../../utils/icons';
import moment from 'moment';
import { useAppConfig } from '../../../Contexts/AppConfigContext';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { DotsActions } from '../../common';
import { AccountManage } from '../..';
import { SmallCompanyData } from '../../../models/SmallCompanyData';
import { SmallAgencyData } from '../../../models/SmallAgencyData';
import AccountUsersPage from '../../../pages/Private/AccountUsersPage/AccountUsersPage';
import { useNavigate } from 'react-router-dom';
import { GetFormattedUrl } from '../../../utils/urls';

interface AccountsListComponentProps {
    list: AccountData[];
    loading: boolean;
    onSearchAgency: (agencyName: string) => void;
    onSearchCompany: (companyName: string) => void;
    companies: SmallCompanyData[];
    agencies: SmallAgencyData[];
}

const AccountsListComponent = (props: AccountsListComponentProps) : JSX.Element => {
    const appConfig = useAppConfig();

    const { t } = useTranslation();


    const { list, loading, onSearchAgency, onSearchCompany, companies, agencies } = props;

    const navigate = useNavigate();

    const goBookings = (account: AccountData) => {
        navigate(GetFormattedUrl(`private/bookings-admin?account=${account.id}`))
    }

    const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
    const [selected, setSelected] = useState<AccountData>();
    const showEditDrawer = (item: AccountData) => {
        setSelected(item);
        setDrawerVisible(true);        
    }

    useEffect(()=>{
        console.log("Updated", companies, agencies)
    },[agencies, props.agencies, companies, props.companies])


    return(
        <div className="accounts-list">
            {
                loading && <div className="loading-container">
                                <LoadingOutlined className="loading-icon"/>
                                <span>{t("components.accounts.list.loading")}</span>
                            </div>
            }
            
            {
                !loading &&  list.map((item: AccountData, index: number) => {
                    var cardHeader : JSX.Element =  <div className="header-container" key={index}>
                                                        <div className="account-type">
                                                            <span>
                                                                <Tooltip title={t(`components.accounts.list.account-type-${item.type}`)}>
                                                                    {getAccountTypeIcon(item.type)}
                                                                </Tooltip>
                                                            </span>
                                                            <span>
                                                                {item.commercialName}
                                                                <small>{t('components.accounts.list.creation-date')}: {moment(item.creationDate).format(appConfig.config.dateFormat)}</small>
                                                            </span>

                                                        </div>
                                                        <div className="actions-container">
                                                            <Switch className="app-switch" defaultChecked={item.status=='active'} />
                                                        </div>
                                                    </div>

                    return(
                        <Card className="app-card" title={cardHeader} bordered={false}>
                            <Drawer open={drawerVisible} title={<span>{selected?.commercialName}<small style={{display: "block"}}>{selected?.address}</small></span>} onClose={()=>{setDrawerVisible(false)}} width={"50%"} closable>
                                <AccountUsersPage accountData={selected}/>
                            </Drawer>
                            <div className="body-container">
                                {GetAccountsIcon('app-icon width-14px')}
                                <div className="additional-info">
                                    <div className="names">
                                        <span>{item.manager}</span>
                                        <span>{item.address}</span>
                                    </div>
                                    <div className="contact">
                                        <span>
                                            <strong>{t('components.accounts.list.email')}</strong>: <a href={`mailto:${item.contact.email}`}>{item.contact.email}</a>
                                        </span>
                                        <span>
                                            <strong>{t('components.accounts.list.phone')}</strong>: {item.contact.phone}
                                        </span>
                                    </div>
                                    <div className="resume">
                                        <span onClick={()=>{showEditDrawer(item)}}>
                                            <strong>{t('components.accounts.list.number-of-users')}</strong>: <span className="app-link" style={{textDecoration: "underline"}}>{item.resume.usersNumber}</span>
                                        </span>
                                        <span onClick={()=>{goBookings(item)}}>
                                            <strong>{t('components.accounts.list.number-of-bookings')}</strong>: <span className="app-link" style={{textDecoration: "underline"}}>{item.resume.bookingNumber}</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    )
                })
            }
        </div>
    )
}

export default AccountsListComponent;