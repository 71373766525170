
import { useTranslation } from 'react-i18next';
import { PublicFooter, PublicHeader } from '../../../../components/common';
import './PrivacityPage.scss';

const PrivacityPage = () : JSX.Element => {
    const { t } = useTranslation();
    
    return(
        <div className="privacity-page">
                {t('pages.privacity.title')}
        </div>
    )
}

export default PrivacityPage;