import { Form, Input } from "antd";
import { useState } from "react";

import './InputLabeledComponent.scss';
import { useTranslation } from "react-i18next";

interface LabeledInputProps {
    inputName: string;
  placeHolder: string;
  type: string;
}

const InputLabeledComponent = (props: LabeledInputProps): JSX.Element => {
    const { t } = useTranslation();

    const [status, setStatus] = useState<string>('');   

  const onFocus = (e: React.FocusEvent<HTMLInputElement>): void => {
    if (e.target.value.length > 0) {
        setStatus('top');
      } else {
        setStatus('focus');
      }
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
    if (e.target.value.length > 0) {
      setStatus('top');
    } else {
      setStatus('');
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value.length > 0) {
      setStatus('top');
    } else {
      setStatus('focus');
    }
  };

  return (
    <div className="input-labeled-component">
      <label className={status}>
        <span className={status}>{props.placeHolder}</span>
        <Form.Item
            className="form-item"
            name={props.inputName}
            rules={[{ required: true, message: t("forms.validations.required-field")! }]}
        >
            <Input
                type={props.type}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                autoComplete="off"
                className="app-input"
                />
        </Form.Item>
      </label>
    </div>
    
  );
};

export default InputLabeledComponent;
