import ProfileForm from "../../../components/ProfileComponents/ProfileForm";
import { useEffect, useState } from "react";
import { UserEdit } from "../../../models/UsersInfo";
import { ConfigContext } from "antd/es/config-provider";
import { Alert, App } from "antd";
import { AppConfigContext } from "antd/es/app/context";
import { useSessionData } from "../../../Contexts/SessionDataContext";
import { get } from "http";
import { fetchUserDataEdition } from "../../../api/repositoryEP";


const ProfilePage = (): JSX.Element => {

    const userId = useSessionData().userData.id;

    const [userData, setUserData] = useState<UserEdit | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchUserDataEdition(userId);
            setUserData(data?.data);
        };

        fetchData();
    }, [userId]);

    if (!userData) {
        // MOSTRAR ERROR Y REDIRIGIR A PAGINA PRINCIPAL NO ? 
        return <Alert message="ERROR" />; 
    }

    return (
        <ProfileForm userData={userData} setUserData={setUserData}></ProfileForm>
    );
}


export default ProfilePage;