import HotelInfo from "../models/HotelInfo";

export const hotelsDemo : Array<HotelInfo> = [
    {
        id: "1",
        name: "Hotel 1"
    },
    {
        id: "2",
        name: "Hotel 2"
    },
    {
        id: "3",
        name: "Hotel 3"
    },
    {
        id: "4",
        name: "Hotel 4"
    },
    {
        id: "5",
        name: "Hotel 5"
    },
    {
        id: "6",
        name: "Hotel 6"
    },
    {
        id: "7",
        name: "Hotel 7"
    },
    {
        id: "8",
        name: "Hotel 8"
    },
    {
        id: "9",
        name: "Hotel 9"
    },
    {
        id: "10",
        name: "Hotel 10"
    },
    {
        id: "11",
        name: "Hotel 11"
    },
    {
        id: "12",
        name: "Hotel 12"
    },
    {
        id: "13",
        name: "Hotel 13"
    },
    {
        id: "14",
        name: "Hotel 14"
    },
    {
        id: "15",
        name: "Hotel 15"
    },
    {
        id: "16",
        name: "Hotel 16"
    },
    {
        id: "17",
        name: "Hotel 17"
    },
    {
        id: "18",
        name: "Hotel 18"
    },
    {
        id: "19",
        name: "Hotel 19"
    },
    {
        id: "20",
        name: "Hotel 20"
    },
    {
        id: "21",
        name: "Hotel 21"
    },
    {
        id: "22",
        name: "Hotel 22"
    },
    {
        id: "23",
        name: "Hotel 23"
    },
    {
        id: "24",
        name: "Hotel 24"
    },
    {
        id: "25",
        name: "Hotel 25"
    },
    {
        id: "26",
        name: "Hotel 26"
    },
    {
        id: "27",
        name: "Hotel 27"
    },
    {
        id: "28",
        name: "Hotel 28"
    }
];