import { useTranslation } from "react-i18next";
import { useEffect, useState } from 'react';
import { Button, Col, Dropdown, Form, Input, Row, Select, Drawer, Checkbox } from "antd";
import "./EditUserForm.scss";
import { editUser, isValidUsername } from "../../api/repositoryEP";
import { Dispatch, SetStateAction } from 'react';
import { fetchUserDataEdition } from "../../api/repositoryEP";
import { UserEdit } from "../../models/UsersInfo";
import EditUserData from "../../models/EditUserData";
import {notification } from 'antd';


interface EditUserFormProps {
    setDrawerVisible: Dispatch<SetStateAction<boolean>>;
    userId: string

}

const EditUserForm = (props: EditUserFormProps): JSX.Element => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const[ usernameValue,setUsernameValue] = useState<string>("");
    const [isValidUsernameInput, setIsValidUsernameInput] = useState<boolean>(true);
    const [passwordValue, setPasswordValue] = useState<string>("");
    const [isEmptyUsername, setIsEmptyUsername] = useState<boolean>(false);
    const [uData, setUData] = useState<UserEdit | null>(null);

    const fetchData = async () => {

        const response = await fetchUserDataEdition(props.userId);
        if (response?.code == 200) {
            setUData(response.data)
            setUsernameValue(response.data.username)
          
        } else {        
            props.setDrawerVisible(false)
        }
    }

    useEffect(() => {
        fetchData();
    }, [props.userId]);


    const handleInputBlur = async () => {
        setIsValidUsernameInput(true);
        if (usernameValue === "") { setIsEmptyUsername(true) } else {
            setIsEmptyUsername(false)
            const response = await isValidUsername(usernameValue);
            if (response?.code === 200) {
                setIsValidUsernameInput(response.data);
            } else {
                setIsValidUsernameInput(false);
            }
        }
    }


    // CAMBIAR EL ONFINISHI
    const onFinish = async (values: any) => {
        if (isValidUsernameInput) {
            // creamos el objeto con los datos del formulario y llamamos a la api
            const userData: EditUserData = {
                name: values.name,
                surname: values.surname,
                lastSurname: values["second-surname"],
                email: values.email,
                phone: values.phone,
                username: values.username,
                password: values.password,
                rol: values.role,
                language: values.language,
                notify: values.notifications
            }

            const response = await editUser(userData);
            if (response?.code == 200) {
                //////////////// MENSAJE DE NOTIFICACION DE ÉXITO
                notification.success({
                    message: t('actions.success-message'),
                    description: t('actions.success-description')
                });
            } else {
                console.log("Error al editar usuario")
            }

            // cerramos el drawer y relamente faltaría actualizar los usuarios 
            props.setDrawerVisible(false)
        }
    };



    
  const handleChangeUsername = (e : any) => {
    setUsernameValue( e.target.value);
  };

    const handleBtnCancel = () => {
        props.setDrawerVisible(false)
    }

    return (
        <div className="account-users-filter">
            <Form form={form} onFinish={onFinish} layout="vertical">
                <Row gutter={16}>
                    {uData && (<>
                        <Col xs={24} md={24}>
                            <h3>
                                {t("components.edit-users.general")}
                            </h3>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item
                                name="name"
                                initialValue={uData?.name}
                                rules={[{ required: true, message: t("components.edit-users.validations.name-required") ?? "Name is required" }]}
                            >
                                <Input className="app-input"
                                    placeholder={'' + t("components.edit-users.name")} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={24}>
                            <Form.Item
                                name="surname"
                                initialValue={uData?.surname}
                                rules={[{ required: true, message: t("components.edit-users.validations.surname-required") ?? "Surname is required" }]}
                            >
                                <Input className="app-input"
                                    placeholder={t("components.edit-users.surname") + ''} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item
                                name="second-surname"
                                initialValue={uData?.secondSurname}
                            >
                                <Input className="app-input"
                                    placeholder={t("components.edit-users.second-surname") + ''} />
                            </Form.Item>
                        </Col>


                        <Col xs={24} md={24}>
                            <h3>
                                {t("components.edit-users.contact")}
                            </h3>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item
                                name="email"
                                initialValue={uData?.email}
                                rules={[{ required: true, message: t("components.edit-users.validations.email-required") + "" }]}

                            >
                                <Input className="app-input"
                                    placeholder={t("components.edit-users.email") + ""} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item
                                name="phone"
                                initialValue={uData?.phone}
                                rules={[
                                    {
                                        pattern: /^[0-9]*$/,
                                        message: t("components.edit-users.validations.phone-numeric") + "",
                                    },
                                ]}
                            >
                                <Input className="app-input"
                                    placeholder={t("components.edit-users.phone") + ""} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={24}>
                            <h3>
                                {t("components.edit-users.access")}
                            </h3>
                        </Col>


                        <Col xs={24} md={24}>
                            <Form.Item
                                name="password"
                            >
                                <Input.Password className="app-input"
                                    placeholder={t("components.edit-users.password") + ""}
                                    value={passwordValue} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={24}>
                            <Form.Item
                                name="repeat-password"
                                dependencies={['password']}

                                rules={[
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            const passwordFieldValue = getFieldValue('password');
                                            if (!passwordFieldValue && !value) {
                                                // Ambos campos están vacíos, no hay error
                                                return Promise.resolve();
                                            }
                                            if (!value && passwordFieldValue) {
                                                return Promise.reject(t("components.edit-users.validations.password-required"))
                                            }
                                            if (!value || passwordFieldValue === value) {
                                                // El campo de repetir contraseña está vacío o coincide con el campo de contraseña
                                                return Promise.resolve();
                                            }
                                            // Los campos no coinciden
                                            return Promise.reject(t("components.edit-users.validations.password-not-match"));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password
                                    className="app-input"
                                    placeholder={t("components.edit-users.repeat-password") + ""}
                                />
                            </Form.Item>
                        </Col>


                        <Col xs={24} md={24}>

                            <Form.Item
                                name="username"
                                initialValue={uData?.username}
                                rules={[
                                    {
                                        required: true,
                                        message: t("components.edit-users.validations.username-required") + ""
                                    },
                                ]}
                                validateStatus={(!isValidUsernameInput || isEmptyUsername) ? 'error' : 'success'}
                                help={(!isValidUsernameInput
                                    && t("components.edit-users.validations.username-not-valid"))
                                    || (isEmptyUsername && t("components.edit-users.validations.username-required"))
                                }
                            >
                                <Input className="app-input"
                                    placeholder={t("components.edit-users.username") + ""}
                                    value={usernameValue}
                                    onChange= { handleChangeUsername}
                                    onBlur={handleInputBlur}
                                />
                            </Form.Item>
                        </Col>




                        <Col xs={24} md={24}>
                            <h3>
                                {t("components.edit-users.role")}
                            </h3>
                        </Col>


                        <Col xs={24} md={24}>
                            <Form.Item name="role" initialValue={uData.role} rules={[{ required: true, message: t("components.edit-users.validations.rol-required") + "" }]} >
                                <Select className="app-input" placeholder={t("components.edit-users.select")}>
                                    <Select.Option value="admin">{t("roles.admin")}</Select.Option>
                                    <Select.Option value="user">{t("roles.user")}</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={24}>
                            <h3>
                                {t("components.edit-users.language")}
                            </h3>
                        </Col>


                        <Col xs={24} md={24}>
                            <Form.Item name="language" initialValue={uData.language} rules={[{ required: true, message: t("components.edit-users.validations.language-required") + "" }]} >
                                <Select className="app-input" placeholder={t("components.edit-users.select")}>
                                    <Select.Option value="es">{t("languages.es")}</Select.Option>
                                    <Select.Option value="en">{t("languages.en")}</Select.Option>
                                    <Select.Option value="fr">{t("languages.fr")}</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>


                        <Col xs={24} md={12}>
                            <Form.Item style={{ textAlign: 'center' }}>
                                <Button className="app-button" style={{ width: "95%" }} htmlType="submit">
                                    {t("components.edit-users.btn-save")}
                                </Button>
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12}>
                            <Form.Item style={{ textAlign: 'center' }}>
                                <Button className="app-button" style={{ width: "95%" }} onClick={handleBtnCancel}>
                                    {t("components.edit-users.btn-cancel")}
                                </Button>
                            </Form.Item>
                        </Col>          
                    </>

                    )}
                </Row>

            </Form>
        </div>
    );
};

export default EditUserForm;
