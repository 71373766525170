import { AVAILABLE_LANGUAGES, DEFAULT_LANGUAGE } from "../i18n/i18n";

export const IsLaguagePresentInUrl = () : boolean => AVAILABLE_LANGUAGES.some(language => window.location.pathname.split("/").length > 0 && window.location.pathname.split("/")[1] == language)
export const GetLanguageInUrl = () : string => IsLaguagePresentInUrl() ? `/${window.location.pathname.split("/")[1]}` : '';
export const GetDefaultLanguage = () : string => DEFAULT_LANGUAGE;
export const GetFormattedUrl = (path: string) : string => `${(IsLaguagePresentInUrl() ? GetLanguageInUrl() : GetDefaultLanguage())}/${path}`;
export const IsSectionInPath = (section: string) : boolean => window.location.pathname.split("/").some(s => s === section);
export const IsHome = () : boolean => window.location.pathname === `${GetLanguageInUrl()}`;
export const GoHome = () : void => {
    const url : string = IsLaguagePresentInUrl() ? GetLanguageInUrl() : GetDefaultLanguage();
    window.location.href = url;
}

export const ImagePath = () : string => `images`;