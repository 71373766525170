import { useTranslation } from "react-i18next";
import { useState } from 'react';
import { Button, Col, Dropdown, Form, Input, Row, Select, Drawer, Checkbox } from "antd";
import "./NewUserForm.scss";
import { FileExcelOutlined, SearchOutlined } from "@ant-design/icons";
import { createUser, isValidUsername } from "../../api/repositoryEP";
import { Dispatch, SetStateAction } from 'react';
import NewUserData from "../../models/NewUserData";


interface NewUserFormProps {
    setDrawerVisible: Dispatch<SetStateAction<boolean>>;

}

const NewUserForm = (props: NewUserFormProps): JSX.Element => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    let usernameValue = "";
    const [isValidUsernameInput, setIsValidUsernameInput] = useState<boolean>(true);
    const [passwordValue, setPasswordValue] = useState<string>("");
    const [isEmptyUsername, setIsEmptyUsername] = useState<boolean>(false);


    const handleInputBlur = async () => {
        console.log("usernameValue", usernameValue)
        setIsValidUsernameInput(true);
        if (usernameValue === "") { setIsEmptyUsername(true) } else {
            setIsEmptyUsername(false)

            const response = await isValidUsername(usernameValue);
            if (response?.code === 200) {
                setIsValidUsernameInput(response.data);
            } else {
                setIsValidUsernameInput(false);
            }
        }
    }



    const onFinish = async (values: any) => {
        if (isValidUsernameInput) {
            // creamos el objeto con los datos del formulario y llamamos a la api
            const userData: NewUserData = {
                name: values.name,
                surname: values.surname,
                lastSurname: values["second-surname"],
                email: values.email,
                phone: values.phone,
                username: values.username,
                password: values.password,
                rol: values.role,
                language: values.language,
                notify: values.notifications
            }

            const response = await createUser(userData);
            if (response?.code == 200) {
                console.log("Usuario creado")
            } else {
                console.log("Error al crear el usuario")
            }

            // cerramos el drawer y relamente faltaría actualizar los usuarios 
            props.setDrawerVisible(false)
        }
    };


    const items = [
        {
            key: '1',
            label: t("components.account-users.filter.active-users"), // usuarios activos
        },
        {
            key: '2',
            label: t("components.account-users.filter.block-users"), // usuarios bloqueados
        },
        {
            key: '3',
            label: t("components.account-users.filter.admin-users"), // administradores
        }
    ];
    const handleBtnCancel = () => {
        props.setDrawerVisible(false)
    }


    return (

        <div className="account-users-filter">
            <Form form={form} onFinish={onFinish} layout="vertical">
                <Row gutter={16}>

                    <Col xs={24} md={24}>
                        <h3>
                            {t("components.new-users.general")}
                        </h3>
                    </Col>
                    <Col xs={24} md={24}>
                        <Form.Item
                            name="name"
                            rules={[{ required: true, message: t("components.new-users.validations.name-required") ?? "Name is required" }]}
                        >
                            <Input className="app-input"
                                placeholder={'' + t("components.new-users.name")} />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={24}>
                        <Form.Item
                            name="surname"
                            rules={[{ required: true, message: t("components.new-users.validations.surname-required") ?? "Surname is required" }]}
                        >
                            <Input className="app-input"
                                placeholder={t("components.new-users.surname") + ''} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={24}>
                        <Form.Item
                            name="second-surname"
                        >
                            <Input className="app-input"
                                placeholder={t("components.new-users.second-surname") + ''} />
                        </Form.Item>
                    </Col>


                    <Col xs={24} md={24}>
                        <h3>
                            {t("components.new-users.contact")}
                        </h3>
                    </Col>
                    <Col xs={24} md={24}>
                        <Form.Item
                            name="email"
                            rules={[{ required: true, message: t("components.new-users.validations.email-required") + "" }]}

                        >
                            <Input className="app-input"
                                placeholder={t("components.new-users.email") + ""} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={24}>
                        <Form.Item
                            name="phone"
                            rules={[
                                {
                                    pattern: /^[0-9]*$/,
                                    message: t("components.new-users.validations.phone-numeric") + "",
                                },
                            ]}
                        >
                            <Input className="app-input"
                                placeholder={t("components.new-users.phone") + ""} />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={24}>
                        <h3>
                            {t("components.new-users.access")}
                        </h3>
                    </Col>


                    <Col xs={24} md={24}>
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: t("components.new-users.validations.password-required") + "" }]}
                            hasFeedback
                        >
                            <Input.Password className="app-input"
                                placeholder={t("components.new-users.password") + ""}
                                value={passwordValue} />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={24}>
                        <Form.Item
                            name="repeat-password"
                            dependencies={['password']}
                            hasFeedback
                            rules={[{ required: true, message: t("components.new-users.validations.password-required") + "" },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(t("components.new-users.validations.password-not-match") + "");
                                },
                            }),
                            ]}
                        >
                            <Input.Password className="app-input"
                                placeholder={t("components.new-users.repeat-password") + ""}
                                value={passwordValue} />
                        </Form.Item>
                    </Col>


                    <Col xs={24} md={24}>

                        <Form.Item
                            name="username"
                            rules={[
                                {
                                    required: true,
                                    message: t("components.new-users.validations.username-required") + ""
                                },
                            ]}
                            validateStatus={(!isValidUsernameInput || isEmptyUsername) ? 'error' : 'success'}
                            help={(!isValidUsernameInput
                                && t("components.new-users.validations.username-not-valid"))
                                || (isEmptyUsername && t("components.new-users.validations.username-required"))
                            }
                        >
                            <Input className="app-input"
                                placeholder={t("components.new-users.username") + ""}
                                value={usernameValue}
                                onChange={(e) => { usernameValue = e.target.value }}
                                onBlur={handleInputBlur}
                            />
                        </Form.Item>
                    </Col>




                    <Col xs={24} md={24}>
                        <h3>
                            {t("components.new-users.role")}
                        </h3>
                    </Col>


                    <Col xs={24} md={24}>
                        <Form.Item name="role" rules={[{ required: true, message: t("components.new-users.validations.rol-required") + "" }]} >
                            <Select className="app-input" placeholder={t("components.new-users.select")}>
                                <Select.Option value="admin">{t("roles.admin")}</Select.Option>
                                <Select.Option value="user">{t("roles.user")}</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={24}>
                        <h3>
                            {t("components.new-users.language")}
                        </h3>
                    </Col>


                    <Col xs={24} md={24}>
                        <Form.Item name="language" rules={[{ required: true, message: t("components.new-users.validations.language-required") + "" }]} >
                            <Select className="app-input" placeholder={t("components.new-users.select")}>
                                <Select.Option value="es">{t("languages.es")}</Select.Option>
                                <Select.Option value="en">{t("languages.en")}</Select.Option>
                                <Select.Option value="fr">{t("languages.fr")}</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={24}>
                        <Form.Item name="notifications">
                            <Checkbox>
                                {t("components.new-users.check-box")}
                            </Checkbox>
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={12}>
                        <Form.Item style={{ textAlign: 'center' }}>
                            <Button className="app-button" style={{ width: "70%" }} onClick={handleBtnCancel}>
                                {t("components.new-users.btn-cancel")}
                            </Button>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item style={{ textAlign: 'center' }}>
                            <Button className="app-button" style={{ width: "70%" }} htmlType="submit">
                                {t("components.new-users.btn-save")}
                            </Button>
                        </Form.Item>
                    </Col>


                </Row>

            </Form>
        </div>
    );
};

export default NewUserForm;
