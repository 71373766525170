import { useEffect, useState } from 'react';
import AccountData from '../../../models/AccountData';
import './AccountUsersPage.scss'

import { useTranslation } from 'react-i18next';
import { changeAccountUserStatus, fetchAccountUsers } from '../../../api/repositoryEP';
import { AccountUsersFilter, AccountUsersList } from '../../../components';
import AccountUserData from '../../../models/AccountUserData';
import { notification } from 'antd';

interface AccountUsersPageProps {
    accountData?: AccountData; 
}

const AccountUsersPage = (props: AccountUsersPageProps) : JSX.Element => {
    const { t } = useTranslation();

    const { accountData } = props;

    const onChangeUserStatus = (userId: string, status: boolean) => {
        
        changeAccountUserStatus(userId, status).then((r)=>{
            if (r?.typeText === "success"){
                notification.success({
                    message: t('actions.success-message'),
                    description: t('actions.success-description')
                });
            }else{
                notification.error({
                    message: t('actions.error-message'),
                    description: t('actions.error-description')
                });
            }
        })
    }

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [accountUsers, setAccountUsers] = useState<AccountUserData[]>([]);
    const getAccountUsers = () => {
        setIsLoading(true);
        fetchAccountUsers(accountData?.id as string).then((r)=>{
            if (r?.typeText === "success" && r?.data){
                setAccountUsers(r?.data as AccountUserData[]);
            }else{
                setAccountUsers([]);
            }
        }).finally(()=>{
            setIsLoading(false);
        })

    }

    useEffect(()=>{
        if (accountData){
            getAccountUsers();
        }
    },[accountData])

    return(
        <div className="account-users-page">
            <AccountUsersFilter onSearch={()=>{}} searching={false} />
            <AccountUsersList list={accountUsers} onChangeUserStatus={onChangeUserStatus}/>
        </div>
    )
}

export default AccountUsersPage;