import { createHotelsConfig, createRoomConfig, editHotelsConfig, editRoomConfig, fetchHotelsConfigEditionMapping, fetchHotelsRegimens, fetchHotelsRooms, fetchLanguagesAvailables } from "../../../../../../../api/repositoryEP";
import RoomConfigData, { Category, HotelRegimesData, HotelRoomsData, HotelsConfigCreateData, HotelsConfigEditData, RoomConfigCreateData, TranslationData } from "../../../../../../../models/MappingData";
import { useEffect, useState } from "react";
import { Form, Row, Col, Button, Switch, Select, InputNumber } from "antd";
import { Input, Checkbox } from "antd";
import { notification } from 'antd';
import { useTranslation } from "react-i18next";
import { KeyOutlined, LoadingOutlined, StarFilled } from "@ant-design/icons";
import './HotelsForm.scss';
import { GetKeyIcon, GetStarIcon } from "../../../../../../../utils/icons";


interface HotelFormProps {
    drawerVisible: boolean;
    setDrawerVisible: (visible: boolean) => void;
    hotelData: HotelsConfigEditData | null;
    setHotelData: (hotelData: HotelsConfigEditData | null) => void;
    language: string
}


const HotelForm = (props: HotelFormProps): JSX.Element => {
    const [loading, setLoading] = useState<boolean>(true);
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [regimens, setRegimens] = useState<Array<HotelRegimesData>>([]);
    const [rooms, setRooms] = useState<Array<HotelRoomsData>>([]);
    const numberCategory = [1, 2, 3, 4, 5]
    const typeCategory = [
        { key: 'key', icon:  GetKeyIcon("app-icon width-12px") },
        { key: 'star', icon: GetStarIcon("app-icon width-12px") },
    ];



    useEffect(() => {
        const fetchHotelRoomData = async (id: string) => {
            try {
                const roomsResponse = await fetchHotelsRooms(props.language, id);
                if (roomsResponse?.code === 200) {
                    setRooms(roomsResponse.data as Array<HotelRoomsData>);
                } else {
                    showErrorMessage();
                    props.setDrawerVisible(false);
                }
            } catch (error) {
                showErrorMessage();
                props.setDrawerVisible(false);
            }
        };

        const fetchRegimenRoomData = async (id: string) => {
            try {
                const regimensResponse = await fetchHotelsRegimens(props.language, id);
                if (regimensResponse?.code === 200) {
                    setRegimens(regimensResponse.data as Array<HotelRegimesData>);
                } else {
                    showErrorMessage();
                    props.setDrawerVisible(false);
                }
            } catch (error) {
                showErrorMessage();
                props.setDrawerVisible(false);
            }
        };

        if (props.drawerVisible) {
            const id = props.hotelData?.id ? props.hotelData.id : '';
            fetchHotelRoomData(id);
            fetchRegimenRoomData(id);
        }

    }, [props.drawerVisible]);




    const handleRoomsCheckboxChange = (roomId: string, active: any) => {
        const updatedRooms = rooms.map(room => {
            if (room.id === roomId) {
                return { ...room, active: active };
            }
            return room;
        });
        setRooms(updatedRooms);
    };


    const handleRegimensCheckboxChange = (regimenId: string, active: any) => {
        const updatedRegimen = regimens.map(regimen => {
            if (regimen.id === regimenId) {
                return { ...regimen, active: active };
            }
            return regimen;
        });
        setRegimens(updatedRegimen);
    };


    const showErrorMessage = () => {
        notification.error({
            message: t('actions.error-message'),
            description: t('actions.error-description')
        });
    }
    const showSuccessNotification = () => {
        notification.success({
            message: t('actions.success-message'),
            description: t('actions.success-description')
        });
    }


    const closeDrawer = () => {
        setRegimens([]);
        setRooms([]);
        props.setHotelData(null)
        form.resetFields();
        props.setDrawerVisible(false)
    }



    /*
        const modifyRoomName = (index: number, value: string) => {
            const newRoomNames = [...roomNames];
            newRoomNames[index].value = value;
            setRoomNames(newRoomNames);
        }*/



    const handleBtnCancel = () => {
        closeDrawer();
    }



    useEffect(() => {
        setLoading(true);
        if (props.hotelData !== null) {
            fetchHotelsConfigEditionMapping(props.hotelData.id).then((response) => {
                if (response?.code === 200) {
                    props.setHotelData(response.data as HotelsConfigEditData);
                }
                setLoading(false);
            });

        } else {
            setLoading(false);
        }


    }, [props.hotelData]);



    useEffect(() => {
        setLoading(true);
        if (props.drawerVisible) {
            if (props.hotelData !== null) {
                fetchHotelsConfigEditionMapping(props.hotelData.id).then((response) => {
                    if (response?.code === 200) {
                        props.setHotelData(response.data as HotelsConfigEditData);
                    } else {
                        showErrorMessage();
                        props.setDrawerVisible(false) // mostramos el error y cerramos el drawer
                    }
                    setLoading(false);
                });
            } else {
                setLoading(false);
            }


        } else {
            closeDrawer();
        }
    }, [props.drawerVisible]);

    const onFinish = (values: any) => {

        if (props.hotelData === null) { // CREATE
            const cate: Category = { type: values.typeCategory, number: values.numberCategory }
            console.log(typeCategory)
            console.log(numberCategory)
            const h: HotelsConfigCreateData = {

                name: values.name,
                category: cate,
                code: values.code,
                destination: values.destination,
                numberRooms: values.numberRooms,
                active: values.active,
                regimen: regimens,
                rooms: rooms,
                syncReward: values.sync
            }
            createHotelsConfig(h).then((response) => {
                if (response?.code === 200) {
                    showSuccessNotification()
                    closeDrawer();
                } else {
                    showErrorMessage();
                }
            })



        } else { // EDIT
            const categ: Category = { type: values.typeCategory, number: values.numberCategory }

            const h: HotelsConfigEditData = {
                id: props.hotelData.id,
                name: values.name,
                category: categ,
                code: values.code,
                destination: values.destination,
                numberRooms: values.numRooms,
                active: values.active,
                regimen: regimens,
                rooms: rooms,
                syncReward: values.sync
            }

            editHotelsConfig(h).then((response) => {
                if (response?.code === 200) {
                    showSuccessNotification()
                    closeDrawer();
                } else {
                    showErrorMessage();
                }
            })

        }

        setRegimens([]);
        setRooms([]);

    }


    return (
        <div className='hotels-form-component'>
            {loading ? (<LoadingOutlined />) : (
                <Form form={form}
                    onFinish={onFinish}
                    layout="vertical"
                >
                    <Row gutter={16}>
                        <Col xs={24} md={24}>
                            <Form.Item
                                name="name"
                                label={t("components.tab-mapping.hotels-config.drawer.name")}
                                rules={[{ required: true, message: t("components.tab-mapping.hotels-config.drawer.name-required") ?? "Name is required" }]}
                                initialValue={props.hotelData?.name}
                            >
                                <Input className="app-input" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={24}>
                            <Form.Item
                                name="code"
                                label={t("components.tab-mapping.hotels-config.drawer.code")}
                                rules={[{ required: true, message: t("components.tab-mapping.hotels-config.drawer.code-required") ?? "Code is required" }]}
                                initialValue={props.hotelData?.code}
                            >
                                <Input className="app-input" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item
                                name="destination"
                                label={t("components.tab-mapping.hotels-config.drawer.destination")}
                                rules={[{ required: true, message: t("components.tab-mapping.hotels-config.drawer.destination-required") ?? "Destination is required" }]}
                                initialValue={props.hotelData?.destination}
                            >
                                <Input className="app-input" />
                            </Form.Item>
                        </Col>


                        <Col xs={24} md={24}>
                            <Form.Item
                                name="numRooms"
                                label={t("components.tab-mapping.hotels-config.drawer.room-number")}
                                rules={[{ required: true, message: t("components.tab-mapping.hotels-config.drawer.room-number-required") ?? "Room number is required" }]}
                                initialValue={props.hotelData?.numberRooms}
                            >
                                <InputNumber className="app-input" />
                            </Form.Item>
                        </Col>

                        <Col xs={12} md={12}>
                            <Form.Item
                                name="active"
                                label={t("components.tab-mapping.hotels-config.drawer.active")}
                                initialValue={props.hotelData?.active}
                                className="switch-container"
                            >
                                <Switch />
                            </Form.Item>
                        </Col>
                        <Col xs={6} md={4}>
                            <Form.Item
                                name="numberCategory"
                                label={t("components.tab-mapping.hotels-config.drawer.category")}
                                initialValue={props.hotelData?.category.number}
                                className="select-container"
                                rules={[{ required: true, message: "" }]}

                            >
                                <Select>
                                    {numberCategory.map((value) => (
                                        <Select.Option key={value} value={value}>
                                            {value}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={3} md={3}>
                            <Form.Item
                                name="typeCategory"
                                label=" "
                                initialValue={props.hotelData?.category.type || 'star'}
                                className="select-container"
                               

                            >
                                <Select>
                                    {typeCategory.map((category) => (
                                        <Select.Option key={category.key} value={category.key}>
                                            {category.icon}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>


                        <Col xs={12} md={12}>
                            <Form.Item
                                label={t("components.tab-mapping.hotels-config.drawer.rooms")}
                            >
                                <div className="multiple-checks-container">
                                    {rooms.map(room => (
                                        <Checkbox
                                            key={room.id}
                                            checked={room.active}
                                            className="notBold"
                                            onChange={(event) => handleRoomsCheckboxChange(room.id, event.target.checked)}
                                        >
                                            {room.name}
                                        </Checkbox>
                                    ))}
                                </div>
                            </Form.Item>
                        </Col>


                        <Col xs={12} md={12}>
                            <Form.Item
                                label={t("components.tab-mapping.hotels-config.drawer.regimes")}
                            >
                                <div className="multiple-checks-container">
                                    {regimens.map(regimen => (
                                        <Checkbox
                                            key={regimen.id}
                                            checked={regimen.active}
                                            className="notBold"
                                            onChange={(event) => handleRegimensCheckboxChange(regimen.id, event.target.checked)}
                                        >
                                            {regimen.name}
                                        </Checkbox>
                                    ))}
                                </div>
                            </Form.Item>
                        </Col>



                        <Col xs={24} md={24} >
                            <Form.Item
                                name="sync"
                                valuePropName="checked"
                                initialValue={props.hotelData?.syncReward ?? false}
                                className="checkbox-sync-container"
                            >
                                <Checkbox className="app-input" >{t("components.tab-mapping.hotels-config.drawer.checkbox")}</Checkbox>
                            </Form.Item>
                        </Col>


                        <Col xs={24} md={12}>
                            <Form.Item style={{ textAlign: 'center' }}>
                                <Button className="app-button" style={{ width: "70%" }} onClick={handleBtnCancel}>
                                    {t("components.button.cancel")}
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Form.Item style={{ textAlign: 'center' }}>
                                <Button className="app-button" style={{ width: "70%" }} htmlType="submit">
                                    {t("components.button.save")}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            )}

        </div>
    );
};

export default HotelForm