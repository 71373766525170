import { ServicesConfigCreateData, ServicesConfigData } from "../../../../../../../models/MappingData";
import { createServicesConfig, editServicesConfig, fetchLanguagesAvailables } from "../../../../../../../api/repositoryEP";
import  { TranslationData } from "../../../../../../../models/MappingData";
import { useEffect, useState } from "react";
import { Form, Row, Col, Button } from "antd";
import { Input, Checkbox } from "antd";
import { notification } from 'antd';
import { useTranslation } from "react-i18next";
import './ServicesForm.scss'


interface ServicesFormProps {
    setDrawerVisible: (visible: boolean) => void;
    drawerVisible: boolean;
    serviceData: ServicesConfigData | null;
    setServiceData: (servicesData: ServicesConfigData | null) => void;
}


const ServicesForm = (props: ServicesFormProps): JSX.Element => {

    const [servicesNames, setServicesNames] = useState<TranslationData[]>([]);
    const { t } = useTranslation();
    const [form] = Form.useForm();


    const showErrorMessage = () => {
        notification.error({
            message: t('actions.error-message'),
            description: t('actions.error-description')
        });
    }
    const showSuccessNotification = () => {
        notification.success({
            message: t('actions.success-message'),
            description: t('actions.success-description')
        });
    }


    const closeDrawer = () => {
        props.setServiceData(null)
        form.resetFields();
        props.setDrawerVisible(false)
    }


    const modifyServicesName = (index: number, value: string) => {
        const newRoomNames = [...servicesNames];
        newRoomNames[index].value = value;
        setServicesNames(newRoomNames);

    }


    const handleBtnCancel = () => {
        closeDrawer();
    }

    useEffect(() => {

        if (props.drawerVisible) {

            if (props.serviceData) { // estamos editando
                setServicesNames(props.serviceData.name);

            } else { // estamos creando
                fetchLanguagesAvailables().then((r) => {
                    if (r?.code === 200) {
                        const languages = r.data.map((lang: string) => {
                            return { language: lang, value: '' }
                        })
                        setServicesNames(languages)
                    } else {
                        showErrorMessage();
                        props.setDrawerVisible(false)
                    }
                })
            }
        }

    }, [props.drawerVisible]);



    const onFinish = (values: any) => {
        console.log(values.name)
        //EIDTAR
        if (props.serviceData) {
            const sData: ServicesConfigData = {
                id: props.serviceData.id,
                code: values.code,
                internNote: values.internalNote,
                syncReward: values.sync,
                name: servicesNames
            }
            editServicesConfig(sData).then((response) => {
                if (response?.code === 200) {
                    showSuccessNotification();
                    closeDrawer();
                } else {
                    showErrorMessage();
                }
            });

        } else {// CREAR
            const sData: ServicesConfigCreateData = {
                code: values.code,
                internNote: values.internalNote,
                syncReward: values.sync,
                name: servicesNames
            }

            createServicesConfig(sData).then((response) => {
                if (response?.code === 200) {
                    showSuccessNotification();
                    closeDrawer();
                } else {
                    showErrorMessage();
                }
            });
        }
    }




    return (
        <div className='services-form-component'>
            <Form form={form}
                onFinish={onFinish}
                layout="vertical"
            >
                <Row gutter={16}>
                    <Col xs={24} md={24}>
                        <Form.Item
                            name="name"
                            label={t("components.tab-mapping.services-config.drawer.name")}
                            required
                            rules={[
                                {
                                    validator: (_, value) =>
                                        servicesNames.length === 0 || servicesNames.some(option => option.value === '')
                                            ? Promise.reject(t("components.tab-mapping.services-config.drawer.name-required") ?? "Names are required")
                                            : Promise.resolve(),
                                },
                            ]}
                        >
                            {servicesNames.map((item, optIndex) => (
                                <div key={optIndex} style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
                                    <span>{item.language.toUpperCase()}</span>
                                    <Input
                                        value={item.value}
                                        onChange={(e) => modifyServicesName(optIndex, e.target.value)}
                                        style={{ marginLeft: 8 }}
                                    />
                                </div>
                            ))}
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={24}>
                        <Form.Item
                            name="code"
                            label={t("components.tab-mapping.services-config.drawer.code")}
                            rules={[{ required: true, message: t("components.tab-mapping.services-config.drawer.code-required") ?? "Code is required" }]}
                            initialValue={props.serviceData?.code}
                        >
                            <Input className="app-input" />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={24}>
                        <Form.Item
                            name="internalNote"
                            label={t("components.tab-mapping.services-config.drawer.internal-note")}
                            rules={[{ required: true, message: t("components.tab-mapping.services-config.drawer.internal-note-required") ?? "Internal note is required" }]}
                            initialValue={props.serviceData?.internNote}
                        >
                            <Input className="app-input" />
                        </Form.Item>
                    </Col>

                    <Col xs={24} md={24} style={{ marginTop: 40 }}>
                        <Form.Item
                            name="sync"
                            valuePropName="checked"
                            initialValue={props.serviceData?.syncReward ?? false}
                        >
                            <Checkbox className="app-input" >{t("components.tab-mapping.services-config.drawer.checkbox")}</Checkbox>
                        </Form.Item>
                    </Col>


                    <Col xs={24} md={12}>
                        <Form.Item style={{ textAlign: 'center' }}>
                            <Button className="app-button" style={{ width: "70%" }} onClick={handleBtnCancel}>
                                {t("components.button.cancel")}
                            </Button>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item style={{ textAlign: 'center' }}>
                            <Button className="app-button" style={{ width: "70%" }} htmlType="submit">
                                {t("components.button.save")}
                            </Button>
                        </Form.Item>
                    </Col>

                </Row>

            </Form>
        </div>
    )
}

export default ServicesForm;