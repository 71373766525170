import { createContext, useContext, useEffect, useState } from "react"
import { ErrorInfo } from "../models/ErrorInfo";

const defaultErrorInfo : ErrorInfo = {
    message: '',
    method: '',
    parameters: null
};


export type ErrorContextType = {
    errorInfo: ErrorInfo;
    updateErrorInfo: (newErrorInfo: ErrorInfo) => void;
};

export const Error500Context = createContext<ErrorContextType>({
    errorInfo: defaultErrorInfo,
    updateErrorInfo: () => {}
});

export const Error500Provider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [errorInfo, setErrorInfo] = useState<ErrorInfo>(defaultErrorInfo);

    const updateErrorInfo = (newErrorInfo: ErrorInfo) => {
        sessionStorage.setItem("last-error", JSON.stringify(newErrorInfo));
        setErrorInfo(newErrorInfo);
    };

    useEffect(()=>{
        const lastError = sessionStorage.getItem('last-error');
        if (lastError){
            setErrorInfo(JSON.parse(lastError) as ErrorInfo);
        }else{
            setErrorInfo(defaultErrorInfo);
        }
    },[]);

    return(
        <Error500Context.Provider value={{ errorInfo, updateErrorInfo }}>
            {children}
        </Error500Context.Provider>
    )
}

export const useErrorInfo = (): ErrorInfo => {
    return useContext(Error500Context).errorInfo;
}

export const useUpdateErrorInfo = (): ((newErrorInfo: ErrorInfo) => void) => {
    return useContext(Error500Context).updateErrorInfo;
}