import { Button } from 'antd';
import { useNavigate } from "react-router-dom";
import { GetHomeIcon, GetRainCloudIcon, GetReloadIcon } from '../../../utils/icons';
import { useTranslation } from 'react-i18next';
import { GetDefaultLanguage, GetLanguageInUrl, IsLaguagePresentInUrl } from '../../../utils/urls';

import './404.scss';


interface Page404Props{}

const Page404 = (props : Page404Props) : JSX.Element => {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const goHome = () : void => {
        const url : string = IsLaguagePresentInUrl() ? GetLanguageInUrl() : GetDefaultLanguage();
        navigate(url); 
    }
    const goBack = () : void => {
        navigate(-1);
    }

    return <div className="error-404-page generic-message">
                    <div className="icon">
                        {
                            GetRainCloudIcon()
                        }
                    </div>
                    <div className="number">
                        404
                    </div>
                    <div className="message">
                        <p className="title">
                            {t('pages.404.title')}
                        </p>
                        <p className="text">
                            {t('pages.404.first-message')}
                        </p>
                    </div>
                    <div className="buttons">
                        <Button className="app-button" onClick={goHome}>
                            <div className="content">
                                {GetHomeIcon()}
                                <span>
                                    {t('pages.404.buttons.go-home')}
                                </span>
                            </div>
                        </Button>
                        <Button className="app-button" onClick={goBack}>
                            <div className="content">
                                {GetReloadIcon()}
                                <span>
                                    {t('pages.404.buttons.go-back')}
                                </span>
                            </div>
                        </Button>
                    </div>
            </div>
}

export default Page404;